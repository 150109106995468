export enum BucketFolder {
    DnaExams = "dna_exam",
    BloodExams = "blood_exam",
    OtherExams = "other_exam",
    Plan = "plan",
    CrossResults = "cross_results",
    HealthPlan = "health_plan",
    Diet = "diet",
    ShoppingList = "shopping_list",
    Eatinghabits = "eating_habits",
    TipsAndRecepes = "tips_and_recepes",
    FruitsAndVegetables = "fruits_and_vegetables",
    UploadedForYou = "for_you"
}