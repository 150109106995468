interface Props {
    onClick?: () => void;
    showBorder?: boolean;
}

const BackButton: React.FC<Props> = ({
    onClick,
    showBorder,
}) => {
    return (
        <button
            className={`horizontal_flex_group align_items_center justify_content_space_around transparent_background uppercase s12 w700 width_109 br5 pointer height_55 ${showBorder ? "black_border" : "no_border"} `}
            onClick={onClick}
            type="button"
        >
            <img src="/images/back-arrow.svg" />
            <div className="no_decoration_text black_text">
                Indietro
            </div>
        </button>
    )
}

export default BackButton
