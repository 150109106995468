interface Props {
    text: string,
    onClick: () => void,
    pointer?: boolean,
    disabled?: boolean,
    fontSize?: number,
    isLoading?: boolean,
    uppercase?: boolean,
}

const SecondaryButton: React.FC<Props> = ({
    text,
    onClick,
    pointer = true,
    disabled = false,
    fontSize = 14,
    isLoading = false,
    uppercase = true,
}) => {
    return (
        <button
            className={`w-full h-full rounded-[5px] ${uppercase && "uppercase"} font-bold text-black bg-secondary-action hover:bg-secondary-action-hover active:bg-secondary-action-active disabled:bg-secondary-action-disabled ${pointer ? "cursor-pointer" : "cursor-not-allowed"} text-[${fontSize}px]`}
            onClick={onClick}
            disabled={disabled}
        >
            {
                isLoading ? (<div className="border-4 border-solid border-white border-t-blue-500 rounded-full w-5 h-5 animate-spin" />) : text
            }
        </button>
    )
}

export default SecondaryButton
