import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { loginApi } from "../../../utils/apis/auth-service";
import { useAuth } from "../../../utils/context/AuthContext";
import LoadingSpinner from "../../new-platform/components/LoadingSpinner";

const ChangePasswordSuccess = () => {

    const { login } = useAuth();

    const navigate = useNavigate();
    const location = useLocation()
    const email = location.state.email
    const password = location.state.password

    useEffect(() => {
        setTimeout(() => { 
            loginApi(
                email,
                password,
                (result) => {
                    login(result.idToken, result.userId, result.accessToken, result.refreshToken, result.expiryTime);
                    navigate("/fetch-user-data")
                },
                () => {}
            )
        }, 1500)
    }, [])

    // ********************************************************************************

    return (
        <div className={`App`}>
            <div className="w-full h-full flex items-center justify-center">
                <div className="w-[392px] flex flex-col items-center bg-white p-[20px]">
                    <div className="font-bold text-[30px] leading-[40px] text-center my-[20px]">
                        Nuova password impostata correttamente
                    </div>
                    <div className="font-normal text-[18px] leading-[26px] text-center my-[20px]">
                        Stai per essere reindirizzato alla tua Area Personale
                    </div>
                    <LoadingSpinner />
                </div>
            </div>
        </div>
    )
}

export default ChangePasswordSuccess
