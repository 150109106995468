import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import PrimaryButton from "../../../../components/buttons/PrimaryButton"
import { Exam } from "../../../../utils/api-objects/Exam"
import { updateBloodTrackerByUserId } from "../../../../utils/apis/dna-test-tracker"
import { getAllExamsByType } from "../../../../utils/apis/emr-service"
import { HOLIFYA_LOGO } from "../../../../utils/Constants"
import { useAuth } from "../../../../utils/context/AuthContext"
import { useBloodTestTracker } from "../../../../utils/context/BloodTextTrackerContext"
import { useUserData } from "../../../../utils/context/UserDataContext"
import { BucketFolder } from "../../../../utils/enums/BucketFolder"
import { BloodTestTrackingStatus } from "../../../../utils/enums/TrackingStatus"
import BackButton from "../../components/BackButton"
import LoadingSpinner from "../../components/LoadingSpinner"
import NavBar from "../../components/NavBar"
import { NavBarElement } from "../../components/NavBarElement"
import { PdfCard, PdfType } from "../../components/PdfCard"

const BloodTest = () => {
  const { auth } = useAuth()
  const userId = auth.userId
  const idToken = auth.idToken
  const { userData } = useUserData()
  const email = userData.email
  const navigate = useNavigate()
  const [currentItem, setCurrentItem] = useState(NavBarElement.Diagnostica)
  const onMenuClick = () => {
    setCurrentItem((prev) => prev === NavBarElement.Diagnostica ? NavBarElement.Menu : NavBarElement.Diagnostica);
  };
  const [isLoading, setIsLoading] = useState(false)
  const { bloodTestTracker, updateBloodTestTracker } = useBloodTestTracker()
  const bloodStatus = bloodTestTracker.bloodStatus
  const canRetrieveUrl = userId && idToken && bloodStatus === BloodTestTrackingStatus.ReportGenerated
  const [report, setReport] = useState<Exam>()

  const onPurchasedClicked = () => {
    window.open("https://www.holifya.com/products/percorso-health", "_blank")
  }

  const onKitArrivedClicked = () => {
    updateBloodTracker(BloodTestTrackingStatus.Delivered)
  }

  const updateBloodTracker = (status: BloodTestTrackingStatus) => {
    if (!userId || !idToken) {
      return
    }

    updateBloodTrackerByUserId(
      userId,
      idToken,
      status,
      (tracker) => {
        const newStatus = tracker.tracking_status
        updateBloodTestTracker(newStatus)
      },
      (error) => {
        console.log(error)
      },
    )
  }

  useEffect(() => {
    if (canRetrieveUrl) {
      setIsLoading(true)
      getAllExamsByType(
        userId,
        idToken,
        BucketFolder.BloodExams,
        (exams) => {
          setReport(exams[0])
          setIsLoading(false)
        },
        (error) => {
          console.error(error)
          setIsLoading(false)
        }
      );
    }
  }, [canRetrieveUrl]);

  // ********************************************************************************

  return (
    <div className="NewApp">
      <div className={`w-full h-full ${isLoading && "opacity-50 cursor-pointer-none"} md:flex md:flex-row-reverse`}>
        <div className="w-full h-[calc(100%-90px)] flex flex-col items-center justify-end overflow-y-auto scrollbar-hide md:w-[calc(100%-100px)] md:h-full">
          <div className="w-[90%] h-[95%] flex flex-col">
            <div className="hidden md:block h-[35px] my-[10px]">
              <img src={HOLIFYA_LOGO} alt="Holifya Logo" />
            </div>
            <div className="my-[10px]">
              <BackButton
                text="Torna a Test e referti"
                onClick={() => navigate("/diagnostica/tests-and-reports")}
              />
            </div>
            <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
              Analisi degli Aminoacidi
            </div>
            {
              bloodStatus === BloodTestTrackingStatus.Purchased &&
              <div className="flex items-center mb-[20px]">
                <div className="font-bold text-[16px] leading-[23px]">Hai già ricevuto il kit?</div>
                <div
                  className="font-bold text-[16px] leading-[23px] text-holifya-blue ml-[10px] cursor-pointer"
                  onClick={onKitArrivedClicked}
                >
                  Si, già ricevuto
                </div>
              </div>
            }
            {
              (bloodStatus && bloodStatus !== BloodTestTrackingStatus.None && bloodStatus !== BloodTestTrackingStatus.ReportGenerated) ? <TrackingWidget status={bloodStatus} />
                :
                (bloodStatus === BloodTestTrackingStatus.None || bloodStatus === null) &&
                <div>
                  <div className="font-normal text-[16px] leading-[26px]">
                    Acquista un 🌀 <strong>Percorso Health</strong> per accedere agli esami sugli Aminoacidi di Holifya e monitorare il miglioramento dei tuoi parametri di salute nel tempo!
                  </div>
                  <div className="font-semibold text-[12px] leading-[20px] mt-[10px] mb-[20px]">
                    Per riscattare e gestire gli acquisti con questo account assicurati di utilizzare la mail <span className="text-holifya-blue">{email}</span> al momento dell'acquisto.
                  </div>
                  <div className="w-full h-[50px]">
                    <PrimaryButton
                      text="Acquista"
                      pointer={true}
                      disabled={false}
                      fontSize={16}
                      uppercase={false}
                      onClick={onPurchasedClicked}
                    />
                  </div>
                </div>
            }
            {
              bloodStatus === BloodTestTrackingStatus.ReportGenerated && report && !isLoading &&
              <PdfCard exam={report} pdfType={PdfType.BloodTest} />
            }
            <div className="p-[10px]" />
          </div>
        </div>
        <NavBar
          currentItem={currentItem}
          onMenuClick={onMenuClick}
        />
      </div>
      {
        isLoading &&
        <div className="w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center">
          <LoadingSpinner />
        </div>
      }
    </div>
  )
}

export default BloodTest

interface Props {
  status: BloodTestTrackingStatus,
}

const TrackingWidget: React.FC<Props> = ({
  status,
}) => {
  const navigate = useNavigate()
  const handleSecondText = () => {
    switch (status) {
      case BloodTestTrackingStatus.Purchased:
        return "Il tuo test è in arrivo!"
      case BloodTestTrackingStatus.Delivered:
        return "Il tuo test è arrivato!"
      case BloodTestTrackingStatus.TestDone:
        return "Registra il tuo campione"
      case BloodTestTrackingStatus.SampleRegistered:
        return "Richiedi il ritiro del tuo campione"
      case BloodTestTrackingStatus.PickupRequested:
        return "Il ritiro è stato prenotato"
      case BloodTestTrackingStatus.DeliveredToLab:
        return <span className="font-normal text-[16px] leading-[26px]">In attesa dei risultati. <br />Se vuoi aggiornamenti sullo stato del tuo campione scrivi a support@holifya.com.
          Non appena i tuoi risultati saranno pronti riceverai una notifca per email.</span>
    }
  }

  const handleNavigation = () => {
    switch (status) {
      case BloodTestTrackingStatus.Delivered:
        return navigate("tutorial")
      case BloodTestTrackingStatus.TestDone:
        return navigate("sample-register")
      case BloodTestTrackingStatus.SampleRegistered:
        return navigate("packing")
      case BloodTestTrackingStatus.PickupRequested:
        return navigate("request-pickup")
      case BloodTestTrackingStatus.DeliveredToLab:
        navigator.clipboard.writeText("support@holifya.com")
          .then(() => toast.success("Email copiata con successo!"))
          .catch(() => toast.error("Errore nel copiare l'email"));
    }
  }

  const handleButtonText = () => {
    switch (status) {
      case BloodTestTrackingStatus.Delivered:
        return "Effettua il test e prosegui"
      case BloodTestTrackingStatus.TestDone:
        return "Registra Codice Campione"
      case BloodTestTrackingStatus.SampleRegistered:
        return "Richiedi il ritiro del campione"
      case BloodTestTrackingStatus.PickupRequested:
        return "Modifica il ritiro del campione"
      case BloodTestTrackingStatus.DeliveredToLab:
        return "Copia mail support"
      default:
        return ""
    }
  }

  // ********************************************************************************

  return (
    <div className="w-full rounded-[15px] flex shadow-platform">
      <div className="w-full p-[20px]">
        <div className="font-bold text-[12px] leading-[17px] mb-[10px]">
          Il tuo campione ematico
        </div>
        <div className="font-normal text-[16px] leading-[24px]">{handleSecondText()}</div>
        {
          (status === BloodTestTrackingStatus.Delivered || status === BloodTestTrackingStatus.TestDone || status === BloodTestTrackingStatus.SampleRegistered || status === BloodTestTrackingStatus.PickupRequested || status === BloodTestTrackingStatus.DeliveredToLab) &&
          <div className="w-full h-[50px] mt-[20px]">
            <PrimaryButton
              text={handleButtonText()}
              pointer={true}
              disabled={false}
              fontSize={16}
              onClick={handleNavigation}
            />
          </div>
        }
      </div>
    </div>
  )
}