import PrimaryButton from "../../../components/buttons/PrimaryButton";

interface Props {
    text: string,
    onClose: () => void,
}

export const WarningDialog: React.FC<Props> = ({
    text,
    onClose,
}) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-4 rounded-[15px] shadow-lg flex flex-col items-center w-[90%] md:w-[362px]">
                <div>
                    {text}
                </div>
                <div className="w-full h-[50px] mt-[20px]">
                    <PrimaryButton
                        text={"Chiudi"}
                        pointer={true}
                        disabled={false}
                        onClick={onClose}
                    />
                </div>
            </div>
        </div>
    );
};