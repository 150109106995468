interface Props {
  name: string;
  label: string;
  inputValue: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  disabled?: boolean;
  marginBottom?: number;
  marginTop?: number;
  fontSize?: number;
  onFocus?: () => void;
}

const EmailInputElement: React.FC<Props> = ({
  name,
  label,
  inputValue,
  onChange,
  placeholder,
  disabled = false,
  marginBottom = 0,
  marginTop = 0,
  fontSize = 16,
  onFocus,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...e,
      target: { ...e.target, value: e.target.value.toLowerCase() },
    });
  };

  // ********************************************************************************

  return (
    <div
      className="w-full flex flex-col justify-between"
      style={{ marginBottom: `${marginBottom}px`, marginTop: `${marginTop}px` }}
    >
      {
        label && (
          <label
            className="font-normal text-[14px] leading-[20px] mb-[5px]"
            htmlFor={`email-input-${name}`}
          >
            {label}
          </label>
        )}
      <input
        name={name}
        id={`email-input-${name}`}
        className="border border-solid border-[#cecece] rounded-[5px] outline-none h-[42px] font-normal pl-[15px] placeholder:font-normal placeholder:text-[14px] placeholder:leading-[20px]"
        placeholder={placeholder}
        value={inputValue}
        type="email"
        onChange={handleChange}
        disabled={disabled}
        autoComplete="email"
        autoCapitalize="none"
        onFocus={onFocus}
        style={{ fontSize: `${fontSize}px` }}
      />
    </div>
  )
}

export default EmailInputElement
