import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WhiteButton from "../../../../components/buttons/WhiteButton";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { Answers } from "../../../../utils/Answers";
import { Question } from "../../../../utils/api-objects/Question";
import { getAnswersByIdsAndUserId, getQuestionByQuestionCategory } from "../../../../utils/apis/emr-service";
import { HOLIFYA_LOGO, NEW_PLATFORM_HOME_LOGO_BLACK } from "../../../../utils/Constants";
import { useAuth } from "../../../../utils/context/AuthContext";
import { QuestionCategory } from "../../../../utils/enums/Surveys";
import { fromQuestionCategoryToString } from "../../../../utils/Functions";
import BackButton from "../../components/BackButton";
import NavBar from "../../components/NavBar";
import { NavBarElement } from "../../components/NavBarElement";

const HealthAssessmentCategory = () => {
    const { auth } = useAuth()
    const userId = auth.userId
    const idToken = auth.idToken
    const navigate = useNavigate()
    const [currentItem, setCurrentItem] = useState(NavBarElement.Diagnostica)

    const { category } = useParams()
    const typedCategory = category as QuestionCategory

    const onMenuClick = () => {
        setCurrentItem(prev =>
            prev === NavBarElement.Diagnostica ? NavBarElement.Menu : NavBarElement.Diagnostica
        );
    };

    const [isLoading, setIsLoading] = useState(true)
    const [questions, setQuestions] = useState<Question[]>()
    const [answers, setAnswers] = useState<Answers[]>()

    const [showGoToHADialog, setShowGoToHADialog] = useState(false)
    const [showEditAnswerPopup, setShowEditAnswerPopup] = useState(false)

    const [focusQuestion, setFocusQuestion] = useState<Question>()

    useEffect(() => {
        if (!idToken || !userId) {
            return
        }
        getQuestionByQuestionCategory(
            typedCategory,
            (questions) => {
                setQuestions(questions)
                const ids: string[] = questions.map(question => question.id)
                getAnswersByIdsAndUserId(
                    ids,
                    userId,
                    idToken,
                    (answers) => {
                        setAnswers(answers)
                        setIsLoading(false)
                    },
                    (error) => {
                        console.log(error)
                        setIsLoading(false)
                    },
                )
            },
            (error) => {
                console.log(error)
            },
        )
    }, [])

    const onEditAnswerClick = (question: Question, answer: Answers | undefined) => {
        if (answer) {
            // setShowEditAnswerPopup(true)
            // setFocusQuestion(question)
            const answerString = answer.value.join(";;");
            navigate(`/edit-question?question_id=${question.id}&answer=${answerString}`)
        } else {
            setShowGoToHADialog(true)
        }
    }

    const onCloseDialogClick = () => {
        setShowGoToHADialog(false)
    }

    const onCloseEditDialogClick = () => {
        setShowEditAnswerPopup(false)
        setFocusQuestion(undefined)
    }

    const onUpdateQuestionClick = () => {
        console.log(focusQuestion)
        const a = answers?.find(answer => answer.question_id === focusQuestion?.id)
        console.log(a)
        navigate(`/edit-question?question_id=${focusQuestion?.id}&answer=${a?.value}`)
    }

    const onOverwriteQuestionClick = () => {
        console.log(focusQuestion)
    }

    // ********************************************************************************

    return (
        <div className="NewApp">
            <div className="w-full h-full md:flex md:flex-row-reverse">
                <div className={`w-full h-[calc(100%-90px)] flex flex-col items-center justify-end overflow-y-auto scrollbar-hide md:w-[calc(100%-100px)] md:h-full`}>
                    <div className={`w-[90%] h-[95%] flex flex-col ${(isLoading || showGoToHADialog || showEditAnswerPopup) && "opacity-50 pointer-events-none"}`}>
                        <div className="hidden md:block h-[35px] my-[10px]">
                            <img src={HOLIFYA_LOGO} alt="Holifya Logo" />
                        </div>
                        <div className="my-[10px]">
                            <BackButton
                                text="Torna a Health Assessment"
                                onClick={() => navigate("/health-assessment")}
                            />
                        </div>
                        {
                            category &&
                            <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
                                {fromQuestionCategoryToString(typedCategory, false)}
                            </div>
                        }
                        {
                            questions && !isLoading && <div>
                                {
                                    questions.map((question) => {
                                        const answer = answers?.find(answer => answer.question_id === question.id);
                                        return <QuestionCard key={question.id} question={question} answer={answer} onEditClick={onEditAnswerClick} />;
                                    })
                                }
                            </div>
                        }
                    </div>
                    <NavBar
                        currentItem={currentItem}
                        onMenuClick={onMenuClick}
                        disabled={showGoToHADialog || showEditAnswerPopup}
                    />
                </div>
            </div>
            {
                isLoading && <div className="w-full h-full absolute"><LoadingSpinner /></div>
            }
            {
                showEditAnswerPopup && <EditAnswerDialog onCloseClick={onCloseEditDialogClick} onUpdateQuestionClick={onUpdateQuestionClick} onOverwriteQuestionClick={onOverwriteQuestionClick} question={focusQuestion!} />
            }
            {
                showGoToHADialog && <HADialog onCloseClick={onCloseDialogClick} />
            }
        </div>
    )
}

export default HealthAssessmentCategory

interface Props {
    question: Question,
    answer: Answers | undefined,
    onEditClick: (question: Question, answer: Answers | undefined) => void,
}

const QuestionCard: React.FC<Props> = ({
    question,
    answer,
    onEditClick,
}) => {
    return (
        <div className="w-full h-[111px] my-[15px] rounded-[5px] border border-solid border-black flex items-center justify-center p-[20px]">
            <div className="w-full h-full flex items-center justify-between">
                <div className="h-full flex flex-col justify-evenly overflow-y-auto scrollbar-hide">
                    <div className="font-bold text-[12px] leading-[22px]">{question.label}</div>
                    <div>{answer !== undefined ? answer.value.join(" - ") : "--"}</div>
                </div>
                <div
                    className="font-semibold text-[12px] leading-[17px] text-holifya-blue cursor-pointer"
                    onClick={() => onEditClick(question, answer)}
                >
                    Modifica
                </div>
            </div>
        </div>
    )
}

interface DialogProps {
    onCloseClick: () => void,
}

const HADialog: React.FC<DialogProps> = ({
    onCloseClick,
}) => {
    const navigate = useNavigate()

    return (
        <div className=" w-[90%] h-[450px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-[15px] flex items-center justify-center shadow-platform">
            <div className="w-[90%] h-[90%] flex flex-col justify-between">
                <div className="font-bold text-[20px] leading-[25px]">
                    Non hai ancora risposto al
                    questionario 📝 Health Assessment
                </div>
                <div className="font-normal text-[16px] leading-[26px]">
                    La prima volta che inserisci le tue informazioni devi farlo dal questionario di 📝 <strong>Health Assessment</strong> accessibile dal tuo Pannello di controllo in <strong>Home</strong> ( <span><img className="align-middle inline-block m-0 p-0 h-auto w-auto" src={NEW_PLATFORM_HOME_LOGO_BLACK} /></span> ) o da qui.
                    Una volta completato potrai modificare singolarmente tutte le tue risposte o rispondere ai questionari di check-up.
                </div>
                <div className="w-full h-[110px] flex flex-col justify-between self-center">
                    <button
                        className="w-full h-[50px] bg-white rounded-[5px] border border-solid border-holifya-blue cursor-pointer font-bold text-[16px] leading-[22px] uppercase text-holifya-blue"
                        onClick={() => navigate("/health-assessment-home-page")}
                    >
                        Inizia subito questionario
                    </button>
                    <button
                        className="w-full h-[50px] bg-white rounded-[5px] cursor-pointer font-bold text-[16px] leading-[22px] text-black"
                        onClick={onCloseClick}
                    >
                        Chiudi
                    </button>
                </div>
            </div>
        </div>
    )
}

interface EditAnswersProps {
    onCloseClick: () => void,
    onUpdateQuestionClick: () => void,
    onOverwriteQuestionClick: () => void,
    question: Question,
}

const EditAnswerDialog: React.FC<EditAnswersProps> = ({
    onCloseClick,
    onUpdateQuestionClick,
    onOverwriteQuestionClick,
    question,
}) => {
    return (
        <div className="w-[90%] h-[409px] rounded-[10px] bg-white shadow-platform absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center">
            <div className="w-[90%] h-[90%] flex flex-col justify-between">
                <div className="font-bold text-[20px] leading-[20px]">Conferma la tua scelta</div>
                <div className="font-normal text-[16px] leading-[26px]">
                    Le risposte alle tue domande possono essere un aggiornamento temporale o una sovrascrizione dell'informazione.<br />
                    Vuoi aggiornare o sovrascrivere?
                </div>
                <div className="w-full h-[170px] flex flex-col justify-between">
                    <button
                        className={`w-full h-[50px] rounded-[5px] bg-white border border-solid border-holifya-blue ${question.updatable ? "cursor-pointer" : "opacity-50 pointer-events-none"} font-bold text-[16px] leading-[22px] text-holifya-blue`}
                        onClick={onUpdateQuestionClick}
                    >
                        Aggiorna
                    </button>
                    <button
                        className={`w-full h-[50px] rounded-[5px] bg-white border border-solid border-holifya-red cursor-pointer font-bold text-[16px] leading-[22px] text-holifya-red`}
                        onClick={onOverwriteQuestionClick}
                    >
                        Sovrascrivi
                    </button>
                    <div className="w-full h-[50px]">
                        <WhiteButton
                            text="Annulla"
                            onClick={onCloseClick}
                            uppercase={false}
                            fontSize={16}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
