import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { confirmTutorial } from "../../../utils/apis/order-service"
import { HOLIFYA_LOGO } from "../../../utils/Constants"
import { useAuth } from "../../../utils/context/AuthContext"
import { useTutorial } from "../../../utils/context/TutorialContext"
import { TutorialType } from "../../../utils/enums/TutorialType"
import { TutorialCard } from "./components/TutorialCard"

const HowItWorksDna = () => {

    const navigate = useNavigate()
    const { auth } = useAuth()
    const userId = auth.userId
    const idToken = auth.idToken
    const { tutorial, updateTutorial } = useTutorial()
    const [index, setIndex] = useState(1)

    const onSubmitClick = () => {
        setIndex(index + 1)
    }

    const onCloseClick = async () => {
        if (index >= 2) {
            if (!userId || !idToken) return
            await confirmTutorial(
                userId,
                idToken,
                TutorialType.DNA_TEST,
                () => {
                    if (tutorial?.tutorial) {
                        const updatedTutorial = { ...tutorial.tutorial, confirmed: true };
                        updateTutorial(updatedTutorial);
                    }
                    navigate("/home")
                },
                (error) => { console.log(error) },
            )
        }
        navigate("/home")
    }

    // ********************************************************************************

    return (
        <div className="NewApp">
            <div className={`w-full h-full flex flex-col items-center justify-end overflow-y-auto scrollbar-hide md:justify-center`}>
                <div className="w-[90%] h-[95%] flex flex-col items-center md:w-[744px] md:h-fit">
                    <div className="hidden h-[35px] mt-[10px] mb-[20px] md:block">
                        <img src={HOLIFYA_LOGO} />
                    </div>
                    <TutorialCard
                        index={index}
                        onNextClick={onSubmitClick}
                        onCloseClick={onCloseClick}
                        onBackClick={() => setIndex(index - 1)}
                        type={TutorialType.DNA_TEST}
                    />
                    <div className="p-[10px]" />
                </div>
            </div>
        </div>
    )
}

export default HowItWorksDna
