import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "../../../../components/buttons/PrimaryButton"
import LoadingSpinner from "../../../../components/LoadingSpinner"
import { Exam } from "../../../../utils/api-objects/Exam"
import { updateTrackerByOrderId } from "../../../../utils/apis/dna-test-tracker"
import { getAllExamsByType } from "../../../../utils/apis/emr-service"
import { HOLIFYA_LOGO } from "../../../../utils/Constants"
import { useAuth } from "../../../../utils/context/AuthContext"
import { useDNATestTracker } from "../../../../utils/context/DNATestTrackerContext"
import { useUserData } from "../../../../utils/context/UserDataContext"
import { BucketFolder } from "../../../../utils/enums/BucketFolder"
import { TrackingStatus } from "../../../../utils/enums/TrackingStatus"
import BackButton from "../../components/BackButton"
import NavBar from "../../components/NavBar"
import { NavBarElement } from "../../components/NavBarElement"
import { PdfCard, PdfType } from "../../components/PdfCard"
import TrackingWidget from "../../components/TrackingWidget"

const DnaTest = () => {
    const navigate = useNavigate()
    const { dnaTestTracker, updateDNATestTracker } = useDNATestTracker()
    const { auth } = useAuth()
    const { userData } = useUserData()
    const email = userData.email

    const [status, setStatus] = useState(dnaTestTracker.status)
    const [report, setReport] = useState<Exam>()
    const [isLoading, setIsLoading] = useState(false)
    const [currentItem, setCurrentItem] = useState(NavBarElement.Diagnostica)

    const orderId = dnaTestTracker.orderId
    const idToken = auth.idToken
    const userId = auth.userId

    const canUpdate = orderId !== null && idToken !== null
    const canRetrieveUrl = userId && idToken && status === TrackingStatus.ReportGenerated

    const onMenuClick = () => {
        setCurrentItem((prev) => prev === NavBarElement.Diagnostica ? NavBarElement.Menu : NavBarElement.Diagnostica);
    };

    useEffect(() => {
        setStatus(dnaTestTracker.status);
    }, [dnaTestTracker.status]);

    useEffect(() => {
        if (canRetrieveUrl) {
            setIsLoading(true)
            getAllExamsByType(
                userId,
                idToken,
                BucketFolder.DnaExams,
                (exams) => {
                    setReport(exams[0])
                    setIsLoading(false)
                },
                (error) => {
                    console.error(error)
                    setIsLoading(false)
                }
            );
        }
    }, [canRetrieveUrl]);

    const onKitReceivedClick = () => {
        setIsLoading(true)
        canUpdate && updateTrackerByOrderId(
            orderId,
            idToken,
            TrackingStatus.Delivered,
            (tracker) => {
                const newStatus = tracker.tracking_status
                updateDNATestTracker(newStatus, orderId)
                setIsLoading(false)
            },
            (error) => {
                console.log(error)
                setIsLoading(false)
            }
        )
    }

    const onPurchasedClicked = () => {
        window.open("https://www.holifya.com/products/dna-complete", "_blank")
    }

    // ********************************************************************************

    return (
        <div className="NewApp">
            <div className={`w-full h-full ${isLoading && "opacity-50 pointer-events-none"} md:flex md:flex-row-reverse`}>
                <div className={`w-full h-[calc(100%-90px)] flex flex-col items-center justify-end overflow-y-auto scrollbar-hide md:w-[calc(100%-100px)] md:h-full`}>
                    <div className="w-[90%] h-[95%] flex flex-col">
                        <div className="hidden md:block h-[35px] my-[10px]">
                            <img src={HOLIFYA_LOGO} alt="Holifya Logo" />
                        </div>
                        <div className="my-[10px]">
                            <BackButton
                                text="Torna a Test e referti"
                                onClick={() => navigate("/diagnostica/tests-and-reports")}
                            />
                        </div>
                        <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
                            DNA Test
                        </div>
                        {
                            status === TrackingStatus.Purchased &&
                            <div className="flex items-center mb-[20px]">
                                <div className="font-bold text-[16px] leading-[23px]">
                                    Hai già ricevuto il kit?
                                </div>
                                <div
                                    className="font-bold text-[16px] leading-[23px] text-holifya-blue ml-[10px] cursor-pointer"
                                    onClick={onKitReceivedClick}
                                >
                                    Si, già ricevuto
                                </div>
                            </div>
                        }
                        {(status && status !== TrackingStatus.ReportGenerated) && <TrackingWidget status={status} />}
                        {
                            status === TrackingStatus.ReportGenerated && report && !isLoading &&
                            <PdfCard exam={report} pdfType={PdfType.DnaTest} />
                        }
                        {
                            status === null &&
                            <div>
                                <div className="font-normal text-[16px] leading-[26px]">
                                    Acquista un 🧬 <strong>Test del DNA</strong> e gestisci la ricezione del kit, il prelievo, la registrazione, l'invio in laboratorio e ricevi i tuoi risultati in questa pagina!
                                </div>
                                <div className="font-semibold text-[12px] leading-[20px] mt-[10px] mb-[20px]">
                                    Per riscattare e gestire gli acquisti con questo account assicurati di utilizzare la mail <span className="text-holifya-blue">{email}</span> al momento dell'acquisto.
                                </div>
                                <div className="w-full h-[50px]">
                                    <PrimaryButton
                                        text="Acquista"
                                        pointer={true}
                                        disabled={false}
                                        fontSize={16}
                                        uppercase={false}
                                        onClick={onPurchasedClicked}
                                    />
                                </div>
                            </div>
                        }
                        <div className="p-[10px]" />
                    </div>
                </div>
                <NavBar
                    currentItem={currentItem}
                    onMenuClick={onMenuClick}
                />
            </div>
            {isLoading && <div className="w-full h-full absolute"><LoadingSpinner /></div>}
        </div>
    )
}

export default DnaTest
