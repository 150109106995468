import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import PrimaryButton from "../../../../components/buttons/PrimaryButton"
import WhiteButton from "../../../../components/buttons/WhiteButton"
import LoadingSpinner from "../../../../components/LoadingSpinner"
import { Question } from "../../../../utils/api-objects/Question"
import { getQuestionById, overwriteAnswer, updateAnswer } from "../../../../utils/apis/emr-service"
import { ANSWERS_OPENING_INPUT } from "../../../../utils/Constants"
import { useAuth } from "../../../../utils/context/AuthContext"
import { QuestionType } from "../../../../utils/enums/Surveys"
import CloseButton from "../../components/CloseButton"
import { MultiSelectCard, OpenParagraphCard, RadioCard, SingleValueCard } from "../../components/SurveyCards"


const EditQuestionPage = () => {
    const { auth } = useAuth()
    const userId = auth.userId
    const idToken = auth.idToken

    const navigate = useNavigate()

    const [searchParams] = useSearchParams()
    const questionId = searchParams.get("question_id")
    const oldAnswer = searchParams.get("answer")

    const [question, setQuestion] = useState<Question>()
    const [isLoading, setIsLoading] = useState(true)
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)
    const [isOverwriteLoading, setIsOverwriteLoading] = useState(true)
    const [isConfirmButtonEnabled, setIsConfirmButtonEnabled] = useState(false)
    const [shouldShowDialog, setShouldShowDialog] = useState(false)

    const [radioAnswer, setRadioAnswer] = useState("")
    const [selectedAnswers, setSelectedAnswers] = useState<Set<string>>(new Set());
    const [singleValueAnswer, setSingleValueAnswer] = useState("")
    const [openParagraphAnswer, setOpenParagraphAnswer] = useState("")
    const [originalCustomAnswer, setOriginalCustomAnswer] = useState<string>()
    const [multiselectCustomAnswer, setMultiSelectCustomAnswer] = useState("")
    const [radioCustomAnswer, setRadioCustomAnswer] = useState("")

    const [radioBecomesInput, setRadioBecomesInput] = useState(false)
    const [multiselectBecomesInput, setMultiselectBecomesInput] = useState(false)

    const onBackClick = () => {
        if (!question) {
            return
        }
        navigate(`/health-assessment/category/${question.category}`)
    }

    const onRadioCardClick = (answer: string) => {
        setRadioAnswer(answer)
        if (ANSWERS_OPENING_INPUT.includes(answer)) {
            setRadioBecomesInput(true)
        } else {
            setRadioBecomesInput(false)
        }
    }

    const onMultiSelectCardClick = (answer: string) => {
        if (ANSWERS_OPENING_INPUT.includes(answer)) {
            setMultiselectBecomesInput(!multiselectBecomesInput);
        }
        setSelectedAnswers(prevState => {
            const newSelectedAnswers = new Set(prevState);
            if (!ANSWERS_OPENING_INPUT.includes(answer)) {
                if (newSelectedAnswers.has(answer)) {
                    newSelectedAnswers.delete(answer);
                } else {
                    newSelectedAnswers.add(answer);
                }
            }
            return newSelectedAnswers;
        });
    }

    const handleSingleValueAnswerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSingleValueAnswer(e.target.value)
    }

    const handleOpenParagraphAnswerChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setOpenParagraphAnswer(e.target.value)
    }

    const handleMultiSelectInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newCustomAnswer = e.target.value;
        setMultiSelectCustomAnswer(newCustomAnswer)
        setSelectedAnswers(prevState => {
            const newSelectedAnswers = new Set(prevState);

            // If there is an old custom answer, remove it from the set
            if (originalCustomAnswer && newSelectedAnswers.has(originalCustomAnswer)) {
                newSelectedAnswers.delete(originalCustomAnswer);
            }

            setOriginalCustomAnswer(newCustomAnswer)

            // Add the new custom answer to the set
            if (newCustomAnswer) {
                newSelectedAnswers.add(newCustomAnswer);
            }

            return newSelectedAnswers;
        });
        if (newCustomAnswer === originalCustomAnswer) {
            setIsConfirmButtonEnabled(false)
        } else {
            setIsConfirmButtonEnabled(true)
        }
    }

    const onConfirmClick = () => {
        setShouldShowDialog(true)
    }

    const onUpdateQuestionClick = () => {
        if (!question || !userId || !idToken) {
            return
        }
        setIsUpdateLoading(true)
        updateAnswer(
            userId,
            idToken,
            question.id,
            handleAnswersForUpdate(),
            () => {
                setIsUpdateLoading(false)
                onBackClick()
            },
            () => {
                setIsUpdateLoading(false)
            },
        )
    }

    const onOverwriteQuestionClick = () => {
        if (!question || !userId || !idToken) {
            return
        }
        setIsOverwriteLoading(true)
        overwriteAnswer(
            userId,
            idToken,
            question.id,
            handleAnswersForUpdate(),
            () => {
                setIsOverwriteLoading(false)
                onBackClick()
            },
            () => {
                setIsOverwriteLoading(false)
            },
        )
    }

    const handleAnswersForUpdate = () => {
        if (!question) {
            return []
        }

        switch (question.question_type) {
            case QuestionType.Radio:
                if (radioCustomAnswer && radioBecomesInput) {
                    return [radioCustomAnswer]
                } else {
                    return [radioAnswer]
                }
            case QuestionType.MultiSelect:
                const responsesArray: string[] = Array.from(selectedAnswers.entries()).map(([answer]) => (
                    answer
                ));
                console.log(responsesArray)
                return responsesArray
            case QuestionType.SingleValueText:
            case QuestionType.SingleValueNumber:
                return [singleValueAnswer]
            case QuestionType.OpenParagraph:
                return [openParagraphAnswer]
            default:
                return []
        }
    }

    // This useeffect should run just once when questionId is first detected
    useEffect(() => {
        if (!questionId) {
            return
        }
        getQuestionById(
            questionId,
            (question) => {
                setQuestion(question)
                setIsLoading(false)
            },
            (error) => {
                console.log(error)
                setIsLoading(false)
            }
        )
    }, [questionId])

    // This useeffect runs when oldAnswer is detected and when question is set
    useEffect(() => {
        if (!question || !oldAnswer) {
            return
        }
        switch (question.question_type) {
            case QuestionType.Radio:
                setRadioAnswer(oldAnswer)
                if (!question.answers.includes(oldAnswer)) {
                    setRadioBecomesInput(true)
                    setRadioCustomAnswer(oldAnswer)
                }
                break;
            case QuestionType.MultiSelect:
                const initialAnswersSet = new Set(oldAnswer.split(";;").map(answer => answer.trim()));
                setSelectedAnswers(initialAnswersSet)
                const initialCustomAnswer = [...initialAnswersSet].find(answer => !question.answers.includes(answer));
                if (initialCustomAnswer) {
                    setMultiselectBecomesInput(true)
                    setOriginalCustomAnswer(initialCustomAnswer)
                    setMultiSelectCustomAnswer(initialCustomAnswer)
                }
                break;
            case QuestionType.SingleValueNumber:
            case QuestionType.SingleValueText:
                setSingleValueAnswer(oldAnswer)
                break;
            case QuestionType.OpenParagraph:
                setOpenParagraphAnswer(oldAnswer)
                break;
        }
    }, [question, oldAnswer])

    // This useeffect runs when an answer is changed, basically this enables the submit button when the answer is different
    useEffect(() => {
        if (!question || !oldAnswer) {
            return
        }
        switch (question.question_type) {
            case QuestionType.Radio:
                if (radioAnswer !== oldAnswer) {
                    setIsConfirmButtonEnabled(true)
                } else {
                    setIsConfirmButtonEnabled(false)
                }
                if (radioBecomesInput) {
                    if (radioCustomAnswer !== oldAnswer) {
                        setIsConfirmButtonEnabled(true)
                    } else {
                        setIsConfirmButtonEnabled(false)
                    }
                }
                break;
            case QuestionType.SingleValueNumber:
            case QuestionType.SingleValueText:
                if (singleValueAnswer !== oldAnswer) {
                    setIsConfirmButtonEnabled(true)
                } else {
                    setIsConfirmButtonEnabled(false)
                }
                break;
            case QuestionType.MultiSelect:
                const initialAnswersSet = new Set(oldAnswer.split(";;").map(answer => answer.trim()));
                const hasChanges = selectedAnswers.size !== initialAnswersSet.size || [...selectedAnswers].some(answer => !initialAnswersSet.has(answer));
                if (hasChanges) {
                    setIsConfirmButtonEnabled(true)
                } else {
                    setIsConfirmButtonEnabled(false)
                }
                break;
            case QuestionType.OpenParagraph:
                if (openParagraphAnswer !== oldAnswer) {
                    setIsConfirmButtonEnabled(true)
                } else {
                    setIsConfirmButtonEnabled(false)
                }
                break;
        }
    }, [radioAnswer, singleValueAnswer, selectedAnswers, openParagraphAnswer, radioCustomAnswer])

    // ********************************************************************************

    return (
        <div className="NewApp">
            <div className={`w-full h-full ${(isLoading || shouldShowDialog) && "opacity-50 pointer-events-none"}`}>
                <div className={`w-full h-[200px] flex items-center justify-center border-b border-solid border-black`}>
                    <div className="w-[90%] h-[70%] ">
                        <div className="w-full flex items-center justify-end mb-[20px]">
                            <CloseButton
                                onClick={onBackClick}
                            />
                        </div>
                        <div className="font-normal text-[20px] leading-[28px]">
                            {question && question.question_text}
                        </div>
                        <div className="font-bold text-[12px] leading-[17px] mt-[10px]">
                            {question && question.question_subtitle}
                        </div>
                    </div>
                </div>
                <div className="w-full h-[calc(100%-320px)] overflow-y-auto scrollbar-hide">
                    {
                        question &&
                        <div className="w-full h-full px-5 pt-5 pb-0 flex flex-col md:flex-row md:justify-center md:flex-wrap">
                            {
                                question.question_type === QuestionType.Radio &&
                                question.answers.map((answer, index) => (
                                    <RadioCard
                                        key={index}
                                        answer={answer}
                                        onClick={onRadioCardClick}
                                        isChecked={answer === radioAnswer || (ANSWERS_OPENING_INPUT.includes(answer) && radioCustomAnswer !== "" && radioBecomesInput)}
                                        isAnInput={radioBecomesInput}
                                        inputValue={radioCustomAnswer}
                                        onInputChange={(e) => setRadioCustomAnswer(e.target.value)}
                                    />
                                ))}
                            {
                                question.question_type === QuestionType.MultiSelect &&
                                question.answers.map((answer, index) => (
                                    <MultiSelectCard
                                        key={index}
                                        answer={answer}
                                        onClick={onMultiSelectCardClick}
                                        isChecked={
                                            (selectedAnswers.has(answer)) ||
                                            (ANSWERS_OPENING_INPUT.includes(answer) && originalCustomAnswer !== undefined && multiselectBecomesInput)
                                        }
                                        isAnInput={multiselectBecomesInput}
                                        inputValue={multiselectCustomAnswer}
                                        onInputChange={handleMultiSelectInputChange}
                                    />
                                ))
                            }
                            {
                                (question.question_type === QuestionType.SingleValueNumber || question.question_type === QuestionType.SingleValueText) &&
                                <SingleValueCard
                                    question={question}
                                    value={singleValueAnswer}
                                    onChange={handleSingleValueAnswerChange}
                                />
                            }
                            {
                                question.question_type === QuestionType.OpenParagraph &&
                                <OpenParagraphCard
                                    question={question}
                                    value={openParagraphAnswer}
                                    onChange={handleOpenParagraphAnswerChange}
                                />
                            }
                            <div className="p-[10px] md:p-0" />
                        </div>
                    }
                </div>
                <div className="w-full h-[200px] flex items-center justify-center md:h-[120px]">
                    <div className="w-[90%] h-[120px] flex flex-col justify-between md:flex-row md:w-[750px] md:items-center">
                        <div className="w-full h-[50px] md:w-[362px]">
                            <PrimaryButton
                                text={"Conferma"}
                                pointer={isConfirmButtonEnabled}
                                disabled={!isConfirmButtonEnabled}
                                fontSize={16}
                                onClick={onConfirmClick}
                            />
                        </div>
                        <div className="w-full h-[50px] md:w-[362px]">
                            <WhiteButton
                                text="Chiudi"
                                onClick={onBackClick}
                                uppercase={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {
                isLoading && <div className="w-full h-full absolute"><LoadingSpinner /></div>
            }
            {
                shouldShowDialog &&
                <EditAnswerDialog
                    onCloseClick={() => setShouldShowDialog(false)}
                    onUpdateQuestionClick={onUpdateQuestionClick}
                    onOverwriteQuestionClick={onOverwriteQuestionClick}
                    question={question!}
                    isUpdateLoading={isUpdateLoading}
                    isOverwriteLoading={isOverwriteLoading}
                />
            }
        </div>
    )
}

export default EditQuestionPage

interface EditAnswersProps {
    onCloseClick: () => void,
    onUpdateQuestionClick: () => void,
    onOverwriteQuestionClick: () => void,
    question: Question,
    isUpdateLoading: boolean,
    isOverwriteLoading: boolean,
}

const EditAnswerDialog: React.FC<EditAnswersProps> = ({
    onCloseClick,
    onUpdateQuestionClick,
    onOverwriteQuestionClick,
    question,
    isUpdateLoading,
    isOverwriteLoading,
}) => {
    return (
        <div className="w-[90%] h-[409px] rounded-[10px] bg-white shadow-platform absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center md:w-[362px]">
            <div className="w-[90%] h-[90%] flex flex-col justify-between">
                <div className="font-bold text-[20px] leading-[20px]">Conferma la tua scelta</div>
                <div className="font-normal text-[16px] leading-[26px]">
                    Le risposte alle tue domande possono essere un aggiornamento temporale o una sovrascrizione dell'informazione.<br />
                    Vuoi aggiornare o sovrascrivere?
                </div>
                <div className="w-full h-[170px] flex flex-col justify-between">
                    <button
                        className={`w-full h-[50px] rounded-[5px] bg-white border border-solid border-holifya-blue ${question.updatable ? "cursor-pointer" : "opacity-50 pointer-events-none"} font-bold text-[16px] leading-[22px] text-holifya-blue`}
                        onClick={onUpdateQuestionClick}
                    >
                        Aggiorna
                    </button>
                    <button
                        className={`w-full h-[50px] rounded-[5px] bg-white border border-solid border-holifya-red cursor-pointer font-bold text-[16px] leading-[22px] text-holifya-red`}
                        onClick={onOverwriteQuestionClick}
                    >
                        Sovrascrivi
                    </button>
                    <div className="w-full h-[50px]">
                        <WhiteButton
                            text="Annulla"
                            onClick={onCloseClick}
                            uppercase={false}
                            fontSize={16}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
