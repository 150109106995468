import { useNavigate } from "react-router-dom"
import { useBloodTestTracker } from "../../../utils/context/BloodTextTrackerContext"
import { useConsults } from "../../../utils/context/ConsultsContext"
import { useDNATestTracker } from "../../../utils/context/DNATestTrackerContext"
import { useExpert } from "../../../utils/context/ExpertContext"
import { useSurveyData } from "../../../utils/context/SurveyDataContext"
import { ControlPanelElement, FocusType } from "../../../utils/enums/ControlPanel"
import { BloodTestTrackingStatus, TrackingStatus } from "../../../utils/enums/TrackingStatus"
import { Appointment } from "./Appointment"

const ControlPanel = () => {
    const navigate = useNavigate()
    const { dnaTestTracker } = useDNATestTracker()
    const status = dnaTestTracker.status
    const { surveyData } = useSurveyData()
    const healthAssessmentCompleted = surveyData.healthAssessmentCompleted
    const { consultsData } = useConsults()
    const { experts } = useExpert()
    const nutritionist = experts.nutritionist
    const nutriConsultsLeft = consultsData.nutritionistConsultsLeft
    const nutriFutureAppointments = consultsData.nutritionistFutureConsults
    const specialistFutureAppointments = consultsData.specialistFutureConsults
    const { bloodTestTracker } = useBloodTestTracker()
    const bloodStatus = bloodTestTracker.bloodStatus

    const handleDnaTestNavigation = (status: TrackingStatus | null) => {
        switch (status) {
            case null:
            case TrackingStatus.Purchased:
            case TrackingStatus.Departed:
            case TrackingStatus.DeliveredToLaboratory:
            case TrackingStatus.StartingToExtract:
            case TrackingStatus.StartingAnalysis:
            case TrackingStatus.ReportGenerated:
                return navigate("/diagnostica/tests-and-reports/dna-test")
            case TrackingStatus.Delivered:
                return navigate("/diagnostica/tests-and-reports/dna-test/tutorial")
            case TrackingStatus.TestDone:
                return navigate("/diagnostica/tests-and-reports/dna-test/register-swab-code")
            case TrackingStatus.SampleRegistered:
                return navigate("/diagnostica/tests-and-reports/dna-test/packing")
            case TrackingStatus.PickupRequested:
                return navigate("/diagnostica/tests-and-reports/dna-test/request-pickup")
            default:
                return ""
        }
    }

    const handleBloodTestNavigation = (status: BloodTestTrackingStatus | null) => {
        switch (status) {
            case null:
            case BloodTestTrackingStatus.None:
            case BloodTestTrackingStatus.Purchased:
            case BloodTestTrackingStatus.DeliveredToLab:
            case BloodTestTrackingStatus.ReportGenerated:
                return navigate("/diagnostica/tests-and-reports/blood-test")
            case BloodTestTrackingStatus.Delivered:
                return navigate("/diagnostica/tests-and-reports/blood-test/tutorial")
            case BloodTestTrackingStatus.TestDone:
                return navigate("/diagnostica/tests-and-reports/blood-test/sample-register")
            case BloodTestTrackingStatus.SampleRegistered:
                return navigate("/diagnostica/tests-and-reports/blood-test/packing")
            case BloodTestTrackingStatus.PickupRequested:
                return navigate("/diagnostica/tests-and-reports/blood-test/request-pickup")
        }
    }

    const handleHealthAssessmentNavigation = (healthAssessmentCompleted: boolean) => {
        switch (healthAssessmentCompleted) {
            case false:
                return navigate("/health-assessment-home-page")
            case true:
                return navigate("/check-up-assessment-home-page")
        }
    }

    const handleNutritionistsNavigation = () => {
        if (nutriFutureAppointments === null) {
            if (nutriConsultsLeft === 0) {
                if (nutritionist) {
                    navigate("/equipe/your-experts ")
                } else {
                    window.open("https://www.holifya.com/pages/consulto ", "_blank")
                }
            } else {
                navigate("/equipe/your-experts")
            }
        } else {
            navigate("/equipe")
        }
    }

    const handleSpecialistsNavigation = () => {
        if (specialistFutureAppointments === null) {
            navigate("/equipe/your-experts")
        } else {
            navigate("/equipe")
        }
    }

    // ********************************************************************************

    return (
        <div className="w-[calc(100%-4px)] rounded-[15px] mt-5 flex items-center justify-center shadow-platform flex-shrink-0 md:w-[800px]">
            <div className="w-full flex flex-col justify-between px-[20px] pt-[20px] pb-[10px]">
                <div className="font-bold text-[12px] leading-[17px] mb-[10px]">
                    Pannello di controllo
                </div>
                <div className="w-full flex flex-col">
                    <ControlPanelCard
                        focusType={FocusType.None}
                        element={ControlPanelElement.HealthAssessment}
                        onClick={() => handleHealthAssessmentNavigation(healthAssessmentCompleted)}
                    />
                    <ControlPanelCard
                        focusType={status === TrackingStatus.ReportGenerated ? FocusType.Highlight : (status !== null ? FocusType.Classic : FocusType.None)}
                        element={ControlPanelElement.DNATestComplete}
                        onClick={() => handleDnaTestNavigation(status)}
                    />
                    <ControlPanelCard
                        focusType={bloodStatus === BloodTestTrackingStatus.ReportGenerated ? FocusType.Highlight : ((bloodStatus === BloodTestTrackingStatus.None || bloodStatus === null || bloodStatus === undefined) ? FocusType.None : FocusType.Classic)}
                        element={ControlPanelElement.BloodExams}
                        onClick={() => handleBloodTestNavigation(bloodStatus)}
                    />
                    <ControlPanelCard
                        focusType={FocusType.None}
                        element={ControlPanelElement.ExpertConsult}
                        onClick={handleSpecialistsNavigation}
                        secondTextColor={specialistFutureAppointments !== null ? (specialistFutureAppointments[0].remainingHours < 24 ? "holifya-red" : "holifya-blue") : "holifya-blue"}
                    />
                    <ControlPanelCard
                        focusType={FocusType.None}
                        element={ControlPanelElement.NutritionistConsult}
                        onClick={handleNutritionistsNavigation}
                        secondTextColor={nutriFutureAppointments !== null ? (nutriFutureAppointments[0].remainingHours < 24 ? "holifya-red" : "holifya-blue") : "holifya-blue"}
                    />
                </div>
            </div>
        </div>
    );
}

export default ControlPanel

interface CardProps {
    focusType: FocusType,
    element: ControlPanelElement,
    onClick: () => void,
    secondTextColor?: string,
    pointer?: boolean,
}

const ControlPanelCard: React.FC<CardProps> = ({
    focusType,
    element,
    onClick,
    secondTextColor = "holifya-blue",
    pointer = true,
}) => {
    const { dnaTestTracker } = useDNATestTracker()
    const { surveyData } = useSurveyData()
    const healthAssessmentCompleted = surveyData.healthAssessmentCompleted
    const { consultsData } = useConsults()
    const specialistConsultsLeft = consultsData.specialistConsultsLeft
    const nutritionistConsultsLeft = consultsData.nutritionistConsultsLeft
    const specialistFutureConsults = consultsData.specialistFutureConsults
    const nutritionistFutureConsults = consultsData.nutritionistFutureConsults
    const { bloodTestTracker } = useBloodTestTracker()
    const bloodStatus = bloodTestTracker.bloodStatus

    const handleFocus = () => {
        switch (focusType) {
            case FocusType.None:
                return "border border-solid border-black"
            case FocusType.Classic:
                return "border-2 border-solid border-holifya-blue"
            case FocusType.Highlight:
                return "border-2 border-solid border-holifya-green"
        }
    }

    // ********************************************************************************

    return (
        <div
            className={`${handleFocus()} w-full h-[60px] rounded-[5px] flex items-center justify-center ${pointer && "cursor-pointer"} mb-[10px]`}
            onClick={onClick}
        >
            <div className="w-[95%] flex items-center justify-between">
                <div className="font-medium text-[15px] leading-[21px]">{handleControlPanelElementText(element, bloodStatus !== null)}</div>
                <div className={`font-bold text-[10px] leading-[14px] text-${secondTextColor}`}>{handleControlPanelElementTextTwo(element, dnaTestTracker.status!, healthAssessmentCompleted, specialistConsultsLeft!, nutritionistConsultsLeft!, specialistFutureConsults, nutritionistFutureConsults, bloodStatus)}</div>
            </div>
        </div>
    )
}

const handleControlPanelElementText = (element: ControlPanelElement, complete: boolean = true) => {
    switch (element) {
        case ControlPanelElement.HealthAssessment:
            return `${"\u{1F4DD}"} Health Assessment`
        case ControlPanelElement.DNATestComplete:
            return `${"\u{1F9EC}"} DNA Test Complete`
        case ControlPanelElement.BloodExams:
            return `${"\u{1FA78}"} Analisi ${complete ? "degli" : ""} Aminoacidi`
        case ControlPanelElement.ExpertConsult:
            return `👩‍⚕️ Consulto con Medico`
        case ControlPanelElement.NutritionistConsult:
            return `👨‍⚕️ Consulto con Nutrizionista`
    }
}

const handleControlPanelElementTextTwo = (element: ControlPanelElement, trackerStatus: TrackingStatus, healthAssessmentCompleted: boolean, specialistConsultsLeft: number, nutritionistConsultsLeft: number, specialistFutureConsults: Appointment[] | null, nutritionistFutureConsults: Appointment[] | null, bloodTestStatus: BloodTestTrackingStatus | null) => {
    switch (element) {
        case ControlPanelElement.HealthAssessment:
            return handleAssessmentCardTextTwo(healthAssessmentCompleted)
        case ControlPanelElement.DNATestComplete:
            return handleTestCardTextTwo(trackerStatus)
        case ControlPanelElement.BloodExams:
            return handleBloodCardTextTwo(bloodTestStatus)
        case ControlPanelElement.ExpertConsult:
            return handleSpecialistCardTextTwo(specialistConsultsLeft, specialistFutureConsults)
        case ControlPanelElement.NutritionistConsult:
            return handleNutritionistCardTextTwo(nutritionistConsultsLeft, nutritionistFutureConsults)
    }
}

const handleSpecialistCardTextTwo = (specialistConsultsLeft: number, specialistFutureConsults: Appointment[] | null) => {
    if (specialistFutureConsults !== null) {
        const nextSpecialistConsult = specialistFutureConsults[0]
        const remainingDays = nextSpecialistConsult.remainingDays
        const remainingHours = nextSpecialistConsult.remainingHours
        const remainingMinutes = nextSpecialistConsult.remainingMinutes
        if (remainingDays >= 1) {
            return `${remainingDays === 1 ? "Manca" : "Mancano"} ${remainingDays} ${remainingDays === 1 ? "giorno" : "giorni"}`
        } else if (remainingHours > 1) {
            return `Mancano ${remainingHours} ore`
        } else if (remainingHours === 1 && remainingMinutes > 55) {
            return `Manca 1 ora`
        } else if (remainingMinutes > 1) {
            return `Mancano ${remainingMinutes} minuti`
        } else if (remainingMinutes === 1) {
            return `Manca 1 minuto`
        } else {
            return `In corso`
        }
    } else if (specialistConsultsLeft === 0 || specialistConsultsLeft === null) {
        return "Acquista"
    } else {
        return `${specialistConsultsLeft} ${specialistConsultsLeft === 1 ? "disponibile" : "disponibili"}`
    }
}

const handleNutritionistCardTextTwo = (nutritionistConsultsLeft: number, nutritionistFutureConsults: Appointment[] | null) => {
    if (nutritionistFutureConsults !== null) {
        const nextNutriConsult = nutritionistFutureConsults[0]
        const remainingDays = nextNutriConsult.remainingDays
        const remainingHours = nextNutriConsult.remainingHours
        const remainingMinutes = nextNutriConsult.remainingMinutes
        if (remainingDays >= 1) {
            return `${remainingDays === 1 ? "Manca" : "Mancano"} ${remainingDays} ${remainingDays === 1 ? "giorno" : "giorni"}`
        } else if (remainingHours > 1) {
            return `Mancano ${remainingHours} ore`
        } else if (remainingHours === 1 && remainingMinutes > 55) {
            return `Manca 1 ora`
        } else if (remainingMinutes > 1) {
            return `Mancano ${remainingMinutes} minuti`
        } else if (remainingMinutes === 1) {
            return `Manca 1 minuto`
        } else {
            return `In corso`
        }
    } else if (nutritionistConsultsLeft === 0 || nutritionistConsultsLeft === null) {
        return "Acquista"
    } else {
        return `${nutritionistConsultsLeft} ${nutritionistConsultsLeft === 1 ? "disponibile" : "disponibili"}`
    }
}

const handleAssessmentCardTextTwo = (healthAssessmentCompleted: boolean) => {
    switch (healthAssessmentCompleted) {
        case false:
            return "Rispondi al questionario"
        case true:
            return "Rispondi al check-up"
    }
}

const handleTestCardTextTwo = (trackerStatus: TrackingStatus) => {
    switch (trackerStatus) {
        case TrackingStatus.Purchased:
            return "In arrivo da te!"
        case TrackingStatus.Delivered:
            return "Effettua il test"
        case TrackingStatus.TestDone:
            return "Registra campione"
        case TrackingStatus.SampleRegistered:
            return "Richiedi il ritiro"
        case TrackingStatus.PickupRequested:
            return "Modifica il ritiro"
        case TrackingStatus.Departed:
            return "Verso il laboratorio"
        case TrackingStatus.DeliveredToLaboratory:
            return "In laboratorio"
        case TrackingStatus.StartingToExtract:
            return "Inizio estrazione"
        case TrackingStatus.StartingAnalysis:
            return "In analisi"
        case TrackingStatus.ReportGenerated:
            return "Visualizza risultati"
        default:
            return "Acquista DNA Test"
    }
}

const handleBloodCardTextTwo = (trackerStatus: BloodTestTrackingStatus | null) => {
    switch (trackerStatus) {
        case null:
        case BloodTestTrackingStatus.None:
            return "Acquista Percorso Health"
        case BloodTestTrackingStatus.Purchased:
            return "In arrivo da te!"
        case BloodTestTrackingStatus.Delivered:
            return "Effettua il test"
        case BloodTestTrackingStatus.TestDone:
            return "Registra campione"
        case BloodTestTrackingStatus.SampleRegistered:
            return "Richiedi il ritiro"
        case BloodTestTrackingStatus.PickupRequested:
            return "Modifica il ritiro"
        case BloodTestTrackingStatus.DeliveredToLab:
            return "In attesa dei risultati"
        case BloodTestTrackingStatus.ReportGenerated:
            return "Visualizza risultati"
        default:
            return ""
    }
}
