import { Answers } from "../Answers";
import { Answer, AnswerResponse } from "../api-objects/Answer";
import { Exam } from "../api-objects/Exam";
import { Question } from "../api-objects/Question";
import { BASE_URL, requestOptionsGET } from "../Constants";
import { BucketFolder } from "../enums/BucketFolder";
import { QuestionCategory, QuestionScoreCategory, QuestionType, SurveyType } from "../enums/Surveys";
import { ErrorWithMessageCallback, GetAnswerResponseSuccessCallback, GetAnswersSuccessCallback, GetAnswerSuccessCallback, GetExamsSuccessCallback, GetQuestionsSuccessCallback, GetQuestionSuccessCallback, SuccessWithMessageCallback } from "./callback-helpers";

// ****************************************

// ********************************************************************************
// Questions

export async function addQuestion(
    question_text: string,
    question_type: QuestionType,
    label: string,
    updatable: boolean,
    answers: string[],
    category: QuestionCategory,
    score_category: QuestionScoreCategory,
    onSuccess: SuccessWithMessageCallback,
    onError: ErrorWithMessageCallback,
    question_subtitle: string | null = null,
    placeholder: string | null = null,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/questions`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    question_text,
                    question_subtitle,
                    question_type,
                    label,
                    placeholder,
                    updatable,
                    answers,
                    category,
                    score_category,
                }
            ),
        })
        if (response.ok) {
            const message: string = await response.json()
            onSuccess(message)
        } else {
            onError("errore")
        }
    } catch (error) {
        console.error("Create question failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getQuestionById(
    id: string,
    onSuccess: GetQuestionSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/questions/id/${id}`, {
            method: 'GET',
        })
        if (response.ok) {
            const question: Question = await response.json()
            onSuccess(question)
        } else {
            onError("errore")
        }
    } catch (error) {
        console.error("Get question failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getQuestionsByIds(
    ids: string[],
    onSuccess: GetQuestionsSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/questions/multiple-ids`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                ids
            ),
        })
        if (response.ok) {
            const questions: Question[] = await response.json()
            onSuccess(questions)
        } else {
            onError("errore")
        }
    } catch (error) {
        console.error("Get question failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getQuestionByQuestionText(
    question_text: string,
    onSuccess: GetQuestionSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const encodedQuestionText = encodeURIComponent(question_text)

        const response = await fetch(`${BASE_URL}/api/questions/text/${encodedQuestionText}`, {
            method: 'GET',
        })
        if (response.ok) {
            const question: Question = await response.json()
            onSuccess(question)
        } else {
            onError("errore")
        }
    } catch (error) {
        console.error("Get question failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getQuestionByQuestionType(
    question_type: QuestionType,
    onSuccess: GetQuestionsSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/questions/type/${question_type}`, {
            method: 'GET',
        })
        if (response.ok) {
            const questions: Question[] = await response.json()
            onSuccess(questions)
        } else {
            onError("errore")
        }
    } catch (error) {
        console.error("Get question failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getQuestionByLabel(
    label: string,
    onSuccess: GetQuestionSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/questions/label/${label}`, {
            method: 'GET',
        })
        if (response.ok) {
            const question: Question = await response.json()
            onSuccess(question)
        } else {
            onError("errore")
        }
    } catch (error) {
        console.error("Get question failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getQuestionByQuestionCategory(
    category: QuestionCategory,
    onSuccess: GetQuestionsSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/questions/category/${category}`, {
            method: 'GET',
        })
        if (response.ok) {
            const questions: Question[] = await response.json()
            onSuccess(questions)
        } else {
            onError("errore")
        }
    } catch (error) {
        console.error("Get question failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getQuestionByQuestionScoreCategory(
    score_category: QuestionScoreCategory,
    onSuccess: GetQuestionsSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/questions/score-category/${score_category}`, {
            method: 'GET',
        })
        if (response.ok) {
            const questions: Question[] = await response.json()
            onSuccess(questions)
        } else {
            onError("errore")
        }
    } catch (error) {
        console.error("Get question failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getQuestions(
    onSuccess: GetQuestionsSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/questions/all`, {
            method: 'GET',
        })
        if (response.ok) {
            const questions: Question[] = await response.json()
            onSuccess(questions)
        } else {
            onError("errore")
        }
    } catch (error) {
        console.error("Get questions failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

// ********************************************************************************
// Answers

export async function addAnswer(
    user_id: string,
    id_token: string,
    question_id: string,
    value: string[],
    onSuccess: SuccessWithMessageCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/answers/${user_id}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': id_token,
            },
            body: JSON.stringify(
                {
                    question_id,
                    value,
                }
            ),
        })
        if (response.ok) {
            const message: string = await response.json()
            onSuccess(message)
        } else {
            onError("errore")
        }
    } catch (error) {
        console.error("Create question failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function updateAnswer(
    user_id: string,
    id_token: string,
    question_id: string,
    value: string[],
    onSuccess: SuccessWithMessageCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/answers/update/${user_id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': id_token,
            },
            body: JSON.stringify(
                {
                    question_id,
                    value,
                }
            ),
        })
        if (response.ok) {
            const message: string = await response.json()
            onSuccess(message)
        } else {
            onError("errore")
        }
    } catch (error) {
        console.error("Update question failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function overwriteAnswer(
    user_id: string,
    id_token: string,
    question_id: string,
    value: string[],
    onSuccess: SuccessWithMessageCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/answers/overwrite/${user_id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': id_token,
            },
            body: JSON.stringify(
                {
                    question_id,
                    value,
                }
            ),
        })
        if (response.ok) {
            const message: string = await response.json()
            onSuccess(message)
        } else {
            onError("errore")
        }
    } catch (error) {
        console.error("Overwrite question failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function saveMultipleAnswers(
    user_id: string,
    id_token: string,
    answers: Answers[],
    onSuccess: SuccessWithMessageCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/answers/multiple/${user_id}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': id_token,
            },
            body: JSON.stringify(
                answers,
            ),
        })
        if (response.ok) {
            const message: string = await response.json()
            onSuccess(message)
        } else {
            onError("errore")
        }
    } catch (error) {
        console.error("Create question failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function updateMultipleAnswers(
    user_id: string,
    id_token: string,
    answers: Answers[],
    onSuccess: SuccessWithMessageCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/answers/update/multiple/${user_id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': id_token,
            },
            body: JSON.stringify(
                answers,
            ),
        })
        if (response.ok) {
            const message: string = await response.json()
            onSuccess(message)
        } else {
            onError("errore")
        }
    } catch (error) {
        console.error("Create question failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getAnswerByQuestionId(
    idToken: string,
    question_id: string,
    onSuccess: GetAnswerSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/answers/question_id/${question_id}`, requestOptionsGET(idToken))
        if (response.ok) {
            const answer: Answer = await response.json()
            onSuccess(answer)
        } else {
            onError("errore")
        }
    } catch (error) {
        console.error("Get question failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getAnswersByUserId(
    user_id: string,
    id_token: string,
    onSuccess: GetAnswerResponseSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/answers/user_id/${user_id}`, requestOptionsGET(id_token))
        if (response.ok) {
            const answersResponse: AnswerResponse[] = await response.json()
            onSuccess(answersResponse)
        } else {
            onError("errore")
        }
    } catch (error) {
        console.error("Get answers by user_id failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getAnswersByIdsAndUserId(
    ids: string[],
    user_id: string,
    id_token: string,
    onSuccess: GetAnswersSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const idsString = ids.join(',');
        const response = await fetch(`${BASE_URL}/api/answers/user_id/${user_id}/ids/${encodeURIComponent(idsString)}`, requestOptionsGET(id_token))
        if (response.ok) {
            const answersResponse: Answer[] = await response.json()
            onSuccess(answersResponse)
        } else {
            onError("errore")
        }
    } catch (error) {
        console.error("Get answers by user_id failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getAllExamsByType(
    user_id: string,
    id_token: string,
    type: BucketFolder,
    onSuccess: GetExamsSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/emr/${user_id}/exams-urls/${type}`, requestOptionsGET(id_token))
        if (response.ok) {
            const exams: Exam[] = await response.json()
            onSuccess(exams)
        } else {
            onError("Errore")
        }
    } catch (error) {
        console.error("Get exams by type failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getAllHealthPlanExamsByType(
    user_id: string,
    id_token: string,
    type: BucketFolder,
    onSuccess: GetExamsSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/emr/${user_id}/exams-urls/health-plan/${type}`, requestOptionsGET(id_token))
        if (response.ok) {
            const exams: Exam[] = await response.json()
            onSuccess(exams)
        } else {
            onError("Errore")
        }
    } catch (error) {
        console.error("Get exams by type failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

// ********************************************************************************
// SurveysCompleted

export async function createSurveyCompleted(
    user_id: string,
    id_token: string,
    survey_type: SurveyType,
    data: Answers[],
    onSuccess: SuccessWithMessageCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/surveys-completed/${user_id}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': id_token,
            },
            body: JSON.stringify(
                {
                    survey_type,
                    data,
                }
            ),
        })
        if (response.ok) {
            const message: string = await response.json()
            onSuccess(message)
        } else {
            const errorMessage: string = await response.text();
            onError(errorMessage)
        }
    } catch (error) {
        console.error("Create survey completed failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getSurveyCompletedByUserIdAndSurveyType(
    userId: string,
    idToken: string,
    surveyType: SurveyType,
    onSuccess: SuccessWithMessageCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/surveys-completed/user_id/${userId}/survey-type/${surveyType}`, requestOptionsGET(idToken))
        if (response.ok) {
            const responseJson = await response.json()
            onSuccess(responseJson)
        } else {
            onError("Errore")
        }
    } catch (error) {
        console.error("Get surveyCompleted failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}
