import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';

interface UserDataState {
    name: string | null;
    familyName: string | null;
    email: string | null;
    phoneNumber: string | null;
    taxCode: string | null;
}

// Define the shape of the user data context
interface UserDataContextType {
    userData: UserDataState;
    updateUserData: (name: string, familyName: string, email: string, phoneNumber: string, taxCode: string) => void;
    clearUserData: () => void;
}

// Create the user data context with default values
const UserDataContext = createContext<UserDataContextType | undefined>(undefined);

// Create a provider component
export const UserDataProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    // Initialize state with data from localStorage
    const [userData, setUserData] = useState<UserDataState>({
        name: localStorage.getItem('name'),
        familyName: localStorage.getItem('familyName'),
        email: localStorage.getItem('email'),
        phoneNumber: localStorage.getItem('phoneNumber'),
        taxCode: localStorage.getItem('taxCode'),
    });

    // Effect to update localStorage when user data state changes
    useEffect(() => {
        if (userData.name) {
            localStorage.setItem('name', userData.name);
        } else {
            localStorage.removeItem('name');
        }

        if (userData.familyName) {
            localStorage.setItem('familyName', userData.familyName);
        } else {
            localStorage.removeItem('familyName');
        }

        if (userData.email) {
            localStorage.setItem('email', userData.email);
        } else {
            localStorage.removeItem('email');
        }

        if (userData.phoneNumber) {
            localStorage.setItem('phoneNumber', userData.phoneNumber);
        } else {
            localStorage.removeItem('phoneNumber');
        }

        if (userData.taxCode) {
            localStorage.setItem('taxCode', userData.taxCode);
        } else {
            localStorage.removeItem('taxCode');
        }
    }, [userData]);

    // Function to update user data
    const updateUserData = (name: string, familyName: string, email: string, phoneNumber: string, taxCode: string) => {
        setUserData({ name, familyName, email, phoneNumber, taxCode });
    };

    // Function to clear user data
    const clearUserData = () => {
        setUserData({ name: null, familyName: null, email: null, phoneNumber: null, taxCode: null });
        localStorage.removeItem('name');
        localStorage.removeItem('familyName');
        localStorage.removeItem('email');
        localStorage.removeItem('phoneNumber');
        localStorage.removeItem('taxCode');
    };

    // Provide context value to children
    return (
        <UserDataContext.Provider value={{ userData, updateUserData, clearUserData }}>
            {children}
        </UserDataContext.Provider>
    );
};

// Custom hook to use the user data context
export const useUserData = (): UserDataContextType => {
    const context = useContext(UserDataContext);
    if (context === undefined) {
        throw new Error('useUserData must be used within a UserDataProvider');
    }
    return context;
};
