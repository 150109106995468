import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "../../../../components/buttons/PrimaryButton"
import EmailInputElement from "../../../../components/input/v1/EmailInputElement"
import PasswordInputElement from "../../../../components/input/v1/PasswordInputElement"
import TextInputElement from "../../../../components/input/v1/TextInputElement"
import { EMAIL_REGEX, PASSWORD_REGEX } from "../../../../utils/Constants"
import { PasswordInputIcon } from "../../../../utils/enums/PasswordInputIcon"
import { PasswordInputType } from "../../../../utils/enums/PasswordInputType"

interface Props {
    onSubmit: (inputData: { [key: string]: string }, newsletterAccepted: boolean,) => void;
    emailError: boolean,
}

const RegistrationBox: React.FC<Props> = ({
    onSubmit,
    emailError,
}) => {
    const navigate = useNavigate()

    const [invalidEmailError, setInvalidEmailError] = useState("")
    const [invalidPasswordError, setInvalidPasswordError] = useState("")
    const [invalidPasswordConfirmError, setInvalidPasswordConfirmError] = useState("")

    const [emailRegistered, setEmailRegistered] = useState(emailError)
    
    const [inputs, setInputs] = useState<{ [key: string]: string }>({
        name: '',
        familyName: '',
        phoneNumber: '',
        email: '',
        password: '',
        passwordConfirm: '',
    });

    const isEmailAcceptable = EMAIL_REGEX.test(inputs.email)
    const isPasswordAcceptable = PASSWORD_REGEX.test(inputs.password)
    const isSubmitButtonEnabled = inputs.name !== "" && inputs.familyName !== "" && inputs.phoneNumber !== "" && inputs.email !== "" && inputs.password !== "" && inputs.passwordConfirm === inputs.password

    const [newsletterAccepted, setNewsletterAccepted] = useState(false)

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        // Lowercase email value before saving to state
        const processedValue = name === "email" ? value.toLowerCase() : value;

        if (name === "email") {
            if (EMAIL_REGEX.test(value)) {
                setInvalidEmailError("")
            } else {
                setInvalidEmailError("Inserisci un indirizzo mail valido")
            }
        }

        if (name === "password") {
            if (PASSWORD_REGEX.test(value)) {
                setInvalidPasswordError("")
            } else {
                setInvalidPasswordError("La password non rispecchia i criteri di validità")
            }
        }

        if (name === "passwordConfirm") {
            if (value === inputs.password) {
                setInvalidPasswordConfirmError("")
            } else {
                setInvalidPasswordConfirmError("Password diverse")
            }
        }

        setInputs((prev) => ({
            ...prev,
            [name]: processedValue, // Use processedValue here
        }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        onSubmit(inputs, newsletterAccepted);
    }

    useEffect(() => {
        setEmailRegistered(emailError)
    }, [emailError])

    // ********************************************************************************
    return (
        <div className="w-full flex flex-col items-center">
            <div className="font-bold text-[30px] leading-[40px]">
                Crea il tuo account
            </div>
            <div className="w-[328px] font-normal text-[14px] text-center my-[20px]">
                Creando un account, dichiari di aver letto e compreso le
                <a
                    href={"/assets/personal-data-treatment.pdf"}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={
                        {
                            textDecoration: "underline",
                            color: "black",
                            fontWeight: "600",
                            cursor: "pointer",
                            marginLeft: "5px"
                        }}
                >
                    Informazioni relative al trattamento dei dati personali
                </a>.
            </div>
            <form
                className="w-[328px]"
                onSubmit={handleSubmit}
            >
                <TextInputElement
                    name="name"
                    label="Nome"
                    placeholder="Inserisci nome"
                    errorText={""}
                    inputValue={inputs.name}
                    onChange={handleInputChange}
                />
                <TextInputElement
                    name="familyName"
                    label="Cognome"
                    placeholder="Inserisci cognome"
                    errorText={""}
                    inputValue={inputs.familyName}
                    onChange={handleInputChange}
                />
                <TextInputElement
                    name="phoneNumber"
                    label="Numero di telefono"
                    placeholder="Inserisci numero di telefono"
                    errorText={""}
                    inputValue={inputs.phoneNumber}
                    onChange={handleInputChange}
                />
                <EmailInputElement
                    name="email"
                    label="Indirizzo e-mail"
                    placeholder="nome@esempio.com"
                    inputValue={inputs.email}
                    errorText={invalidEmailError}
                    onChange={handleInputChange}
                />
                {
                    emailRegistered &&
                    <div className="font-semibold text-[12px] leading-[17px] mb-[10px] text-holifya-error">L'indirizzo e-mail è già in uso. <span onClick={() => { navigate("/login") }} className="underline_text pointer error_text">
                        Clicca qui per accedere.</span>
                    </div>
                }
                <div className="w-[328px] font-semibold text-[14px] leading-[22px] text-holifya-blue">
                    Una password valida è composta da almeno 8 caratteri di cui: almeno una Maiuscola, una minuscola, un numero e un simbolo speciale (!$%& ...)
                </div>
                <PasswordInputElement
                    name="password"
                    label="Password"
                    placeholder="Password"
                    inputValue={inputs.password}
                    errorText={invalidPasswordError}
                    disabled={!isEmailAcceptable}
                    image={PasswordInputIcon.ShowDisabled}
                    type={PasswordInputType.Password}
                    toggleTypeEnabled={isEmailAcceptable && inputs.password !== ""}
                    onIconClick={() => console.log()}
                    onChange={handleInputChange}
                />
                <PasswordInputElement
                    name="passwordConfirm"
                    label="Conferma Password"
                    placeholder="Conferma password"
                    inputValue={inputs.passwordConfirm}
                    errorText={invalidPasswordConfirmError}
                    disabled={!(isEmailAcceptable && isPasswordAcceptable)}
                    image={PasswordInputIcon.ShowDisabled}
                    type={PasswordInputType.Password}
                    toggleTypeEnabled={isEmailAcceptable && isPasswordAcceptable && inputs.passwordConfirm !== ""}
                    onIconClick={() => console.log()}
                    onChange={handleInputChange}
                />
                <div className="w-full flex justify-between text-[14px] py-[10px]">
                    <div
                        className="w-[26px] h-[26px] rounded-[8px] border border-solid border-black ml-[20px] cursor-pointer flex items-center justify-center"
                        onClick={() => { setNewsletterAccepted(!newsletterAccepted) }}
                    >
                        {newsletterAccepted && <div className="w-[20px] h-[20px] rounded-[6px] bg-black" />}
                    </div>
                    <div className="w-[calc(100%-60px)] h-full">Dichiaro di voler ricevere newsletter o comunicazioni commerciali da parte di Holifya.<br></br>
                        <a
                            href={"/assets/newsletter.pdf"}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={
                                {
                                    textDecoration: "underline",
                                    color: "black",
                                    fontWeight: "600",
                                    cursor: "pointer",
                                }}
                        >
                            Informativa relativa alla newsletter
                        </a>
                        .</div>
                </div>
                <div className="w-full h-[55px] my-[20px]">
                    <PrimaryButton
                        text="Continua"
                        pointer={isSubmitButtonEnabled}
                        disabled={!isSubmitButtonEnabled}
                        fontSize={12}
                    />
                </div>
            </form>
        </div>
    )
}

export default RegistrationBox
