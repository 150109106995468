import { FormEvent, useState } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import WhiteButtonBlackBorder from "../../../components/buttons/WhiteButtonBlackBorder"
import TextInputElement from "../../../components/input/v1/TextInputElement"
import { assignMedicalUserToPatient, getMedicalUserByRole } from "../../../utils/apis/medicaluser-service"
import { createProduct, findProduct } from "../../../utils/apis/order-service"
import { HOLIFYA_LOGO, PHARMACIES_CONFIRMATION_NUMBER } from "../../../utils/Constants"
import { useAuth } from "../../../utils/context/AuthContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { CreateProductChannel } from "../../../utils/enums/CreateProductChannel"
import { ExpertRole } from "../../../utils/enums/Experts"
import { ProductType } from "../../../utils/enums/ProductType"
import { createExpertFromCalendlyExpert, getRandomNutritionist } from "../../../utils/Functions"

const Redeem = () => {

    const navigate = useNavigate()
    const { auth, logout } = useAuth()
    const idToken = auth.idToken
    const userId = auth.userId
    const { userData } = useUserData()
    const email = userData.email

    const [code, setCode] = useState("")
    const [codeError, setCodeError] = useState("")

    const [isLoading, setIsLoading] = useState(false)

    const onBackToShopClick = () => {
        window.open("https://www.holifya.com/", "_blank")
        logout()
    }

    const onSubmitClick = (e: FormEvent) => {
        e.preventDefault()
        if (code === PHARMACIES_CONFIRMATION_NUMBER) {
            setIsLoading(true)
            setTimeout(() => {
                navigate("/pharmacies-redeem-swabcode")
                setIsLoading(false)
            }, 1000)
        } else {
            callFindOrder()
        }
    }

    const callFindOrder = () => {
        if (!idToken || !userId) return

        setIsLoading(true)

        findProduct(
            code,
            idToken,
            (product) => {
                const type = product
                createProduct(
                    userId,
                    idToken,
                    type,
                    (success) => {
                        if (type === ProductType.Nutritionist) {
                            assignNutri()
                        } else {
                            navigate("/check-purchases")
                            setIsLoading(false)
                        }
                    },
                    (error) => {
                        console.log(error)
                        setIsLoading(false)
                    },
                    {
                        shopify_product: "one-off",
                        channel: CreateProductChannel.RedeemCode,
                        code: code,
                        buyer: null,
                        redeemer: null,
                    }
                )
            },
            (error) => {
                setCodeError(error)
                setIsLoading(false)
            }
        )
    }

    const assignNutri = () => {
        callGetMedicalUsersList()
    }

    const callGetMedicalUsersList = () => {
        if (!idToken || !userId) return
        getMedicalUserByRole(
            idToken,
            ExpertRole.Nutritionist,
            (nutritionists) => {
                const nutritionist = getRandomNutritionist(nutritionists)
                const newExpert = createExpertFromCalendlyExpert(nutritionist)
                assignNutritionist(newExpert.id!)
            },
            (error) => {
                console.log(error)
            }
        )
    }

    const assignNutritionist = (nutriId: string) => {
        if (!userId || !idToken) return
        assignMedicalUserToPatient(
            nutriId,
            userId,
            idToken,
            () => { navigate("/check-purchases") },
            (error) => { 
                console.log(error)
                navigate("/check-purchases")
             },
        )
    }

    // ********************************************************************************

    return (
        <div className="NewApp">
            <div className="w-full h-full flex flex-col items-center justify-end md:justify-center">
                <div className="w-[90%] h-[90%] flex flex-col items-center justify-between md:w-[435px] md:h-[70%]">
                    <div className="flex flex-col items-center">
                        <div className="h-[35px] mb-[10px]">
                            <img src={HOLIFYA_LOGO} alt="Holifya Logo" />
                        </div>
                        <div className="font-normal text-[16px] leading-[24px] text-center mt-[10px]">
                            Non esistono ancora acquisti associati.
                        </div>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="font-bold text-[25px] leading-[33px] mb-[10px]">
                            🔓 Riscatta o Acquista
                        </div>
                        <div className="font-normal text-[16px] leading-[24px] text-center mt-[10px]">
                            Se hai ricevuto un Codice Riscatto puoi riscattare il prodotto associato inserendolo qui sotto.
                        </div>
                        <div className="w-[30px] h-[1px] bg-black mt-[10px]" />
                        <div className="font-normal text-[16px] leading-[24px] text-center mt-[10px]">
                            In alternativa, per riscattare e gestire acquisti con questo account assicurati di utilizzare la mail <span className="font-bold text-holifya-blue">{email}</span> al momento dell'acquisto.
                        </div>
                    </div>
                    <form
                        className="w-full mb-[20px] md:px-[30px]"
                        onSubmit={onSubmitClick}
                    >
                        <div className="h-[85px] mb-[20px]">
                            <TextInputElement
                                label="Codice riscatto"
                                placeholder="123456"
                                errorText={codeError}
                                inputValue={code}
                                onChange={(e) => {
                                    setCodeError("")
                                    setCode(e.target.value)
                                }}
                            />
                        </div>
                        <div className="w-full h-[120px] flex flex-col justify-between">
                            <div className="w-full h-[50px]">
                                <PrimaryButton
                                    text="Conferma codice"
                                    pointer={code.length >= 6}
                                    disabled={code.length < 6}
                                    // onClick={onSubmitClick}
                                    isLoading={isLoading}
                                />
                            </div>
                            <div className="w-full h-[50px]">
                                <WhiteButtonBlackBorder
                                    text="Torna allo Shop"
                                    onClick={onBackToShopClick}
                                    uppercase={false}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Redeem
