import { BASE_URL, requestOptionsGET } from "../Constants";
import { ErrorWithMessageCallback, SuccessWithMessageCallback } from "./callback-helpers";

export async function assignBloodcode(
    bloodcode: string,
    user_id: string,
    onSuccess: SuccessWithMessageCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/bloodcodes/assign/${bloodcode}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                user_id,
            }),
        })
        if (response.ok) {
            const resposeJson = await response.json()
            onSuccess(resposeJson)
        } else {
            const errorDetails = await response.json();
            console.error("Error response:", errorDetails);
            if (response.status === 404) {
                onError(`Bloodcode non trovato`);
            } else if (response.status === 400) {
                onError(`Bloodcode già assegnato`);
            } else {
                onError(`Error: ${response.statusText}`);
            }
        }
    } catch (error) {
        console.error("Assign Bloodcode failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getBloodcode(
    userId: string,
    idToken: string,
    onSuccess: SuccessWithMessageCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/bloodcodes/${userId}`, requestOptionsGET(idToken))
        if (response.ok) {
            const success = await response.json()
            onSuccess(success.id)
        } else {
            onError("Errore")
        }
    } catch (error) {
        console.error("Get bloodcode failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}