import { Membership } from "../api-objects/Membership";
import { SortingItem } from "../api-objects/SortingItem";
import { SortingRequest } from "../api-objects/SortingRequest";
import { Tutorial } from "../api-objects/Tutorial";
import { UserProduct } from "../api-objects/UserProduct";
import { BASE_URL, requestOptionsGET } from "../Constants";
import { ProductType } from "../enums/ProductType";
import { TutorialType } from "../enums/TutorialType";
import { ErrorWithMessageCallback, FindProductSuccessCallback, GenericSuccessCallback, GetMembershipCallback, GetTutorialSuccessCallback } from "./callback-helpers";

export async function createShopifyOrder(
    userId: string,
    idToken: string,
    email: string,
    name: string,
    familyName: string,
    price: string,
    address1: string,
    city: string,
    zip: string,
    province: string,
    country: string,
    shippingName: string,
    onSuccess: GenericSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/orders/shopify`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken,
            },
            body: JSON.stringify(
                {
                    email: email,
                    name: name,
                    family_name: familyName,
                    user_id: userId,
                    price: price,
                    shipping_address: {
                        address1: address1,
                        city: city,
                        zip: zip,
                        province: province,
                        country: country,
                        name: shippingName,
                    },
                }
            ),
        })
        if (response.ok) {
            const responseJson = await response.json()
            onSuccess(responseJson)
        } else {
            onError("errore")
        }
    } catch (error) {
        console.error("Create shopify order failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function createProduct(
    user_id: string,
    id_token: string,
    product: ProductType,
    onSuccess: GenericSuccessCallback,
    onError: ErrorWithMessageCallback,
    data?: { [key: string]: any },
) {
    try {
        const response = await fetch(`${BASE_URL}/api/products/${user_id}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': id_token,
            },
            body: JSON.stringify(
                {
                    product,
                    data,
                }
            ),
        })
        if (response.ok) {
            const success = await response.json()
            onSuccess(success)
        } else {
            onError("Errore nel creare l'ordine")
        }
    } catch (error) {
        console.error("Create order failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

// export async function getProducts(
//     userId: string,
//     idToken: string,
//     onSuccess: GetUserProductsSuccessCallback,
//     onError: ErrorWithMessageCallback,
// ) {
//     try {
//         const response = await fetch(`${BASE_URL}/api/products/user/${userId}`, requestOptionsGET(idToken))
//         if (response.ok) {
//             const products: UserProduct[] = await response.json()
//             if (products.length > 0) {
//                 onSuccess(products)
//             } else {
//                 onError(`Non sono presenti prodotti associati all'utente ${userId}`)
//             }
//         } else {
//             if (response.status === 404) {
//                 onError(`Non sono presenti prodotti associati all'utente ${userId}`)
//             } else {
//                 onError("Errore nel recupero dei prodotti")
//             }
//         }
//     } catch (error) {
//         console.error("Get products failed:", error);
//         return { success: false, error: "An unexpected error occurred" };
//     }
// }

export async function getProducts(userId: string, idToken: string): Promise<UserProduct[]> {
    const response = await fetch(`${BASE_URL}/api/products/user/${userId}`, requestOptionsGET(idToken));
    
    // In caso non trovasse alcun prodotto comunuque la response sarebbe ok
    if (response.ok) {
        const products: UserProduct[] = await response.json();
        if (products.length > 0) {
            return products
        } else {
            // Se non si recuperano prodottu viene lanciato un errore
            throw new Error(`Nessun prodotto associato allo user: ${userId}`);
        }
    } else {
        const errorMessage = response.status === 404 
            ? `Nessun prodotto associato allo user: ${userId}`
            : `C'è stato un errore nel recuperare i prodotti per l'utente: ${userId}`;
        throw new Error(errorMessage);
    }
}

export async function findProduct(
    code: string,
    idToken: string,
    onSuccess: FindProductSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/products/find/${code}`, requestOptionsGET(idToken))
        if (response.ok) {
            const product: ProductType = await response.json()
            onSuccess(product)
        } else {
            onError("Codice non valido")
        }
    } catch (error) {
        console.error("Get products failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function createSortingItem(
    data: SortingRequest,
    onSuccess: GenericSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/sorting`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    data,
                }
            ),
        })
        if (response.ok) {
            const success = await response.json()
            onSuccess(success)
        } else {
            onError("Errore nel creare la riga in sorting")
        }
    } catch (error) {
        console.error("Create sorting failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getSortingItems(
    email: string,
    idToken: string,
): Promise<SortingItem[]> {
    try {
        const response = await fetch(`${BASE_URL}/api/sorting/email/${email}`, requestOptionsGET(idToken));

        if (response.ok) {
            const items: SortingItem[] = await response.json();
            return items;
        } else {
            return []
        }
    } catch (error) {
        console.error("Get sorting items failed:", error);
        throw new Error("An unexpected error occurred");
    }
}

export async function getRedeemableSortingItems(
    email: string,
    idToken: string
): Promise<SortingItem[]> {
    try {
        const response = await fetch(`${BASE_URL}/api/sorting/redeemable/email/${email}`, requestOptionsGET(idToken));

        if (response.ok) {
            const items: SortingItem[] = await response.json();
            return items;
        } else {
            return []; // If no items are found, it returns an empty list
        }
    } catch (error) {
        const errorMessage = error instanceof Error ? error.message : "An unexpected error occurred";
        console.error("Get sorting redeemable items failed:", errorMessage);
        
        return [];
    }
}

export async function redeemSortingItems(
    user_id: string,
    sorting_id: string,
    redeem_email: string,
    idToken: string,
    onSuccess: GenericSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/sorting/redeem/${sorting_id}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken,
            },
            body: JSON.stringify(
                {
                    user_id,
                    redeem_email,
                })
        })
        if (response.ok) {
            const success = await response.json()
            onSuccess(success)
        } else {
            onError("Errore nel redeem del sorting item")
        }
    } catch (error) {
        console.error("Redeem sorting items failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function assignSortingItems(
    sorting_id: string,
    redeemer_email: string,
    idToken: string,
    onSuccess: GenericSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/sorting/assign/${sorting_id}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken,
            },
            body: JSON.stringify(
                {
                    redeemer_email,
                })
        })
        if (response.ok) {
            const success = await response.json()
            onSuccess(success)
        } else {
            onError("Errore nel redeem del sorting item")
        }
    } catch (error) {
        console.error("Redeem sorting items failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getMostImportantTutorial(
    userId: string,
    idToken: string,
    onSuccess: GetTutorialSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/tutorials/most-important/${userId}`, requestOptionsGET(idToken))
        if (response.ok) {
            const tutorial: Tutorial = await response.json()
            onSuccess(tutorial)
        } else {
            onError("Errore nel recuperare i tutorial")
        }
    } catch (error) {
        console.error("Get tutorials failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function confirmTutorial(
    user_id: string,
    idToken: string,
    tutorial_type: TutorialType,
    onSuccess: GenericSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/tutorials/confirm/${user_id}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken,
            },
            body: JSON.stringify(
                {
                    tutorial_type,
                })
        })
        if (response.ok) {
            const success = await response.json()
            onSuccess(success)
        } else {
            onError("Errore nel confirm del tutorial")
        }
    } catch (error) {
        console.error("Confirm tutorial failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getMembership(
    userId: string,
    idToken: string,
    onSuccess: GetMembershipCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/memberships/user/${userId}`, requestOptionsGET(idToken))
        if (response.ok) {
            const membership: Membership = await response.json()
            onSuccess(membership)
        } else {
            onError("Errore nel recuperare la membership")
        }
    } catch (error) {
        console.error("Get membership failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}
