import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { registerApi } from "../../../utils/apis/auth-service";
import { useRegistrationData } from "../../../utils/context/RegistrationContext";
import LoadingSpinner from "../../new-platform/components/LoadingSpinner";
import RegistrationBox from "./components/RegistrationBox";

const EmailRegistration = () => {

    const navigate = useNavigate();
    const { saveData } = useRegistrationData();

    const [emailError, setEmailError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const onBackButtonClick = () => {
        navigate("/login")
    }

    const handleInputsSubmit = (inputData: { [key: string]: string }, newsletterAccepted: boolean) => {
        const name = inputData.name
        const familyName = inputData.familyName
        const phoneNumber = inputData.phoneNumber
        const email = inputData.email
        const password = inputData.password
        callRegister(name, familyName, phoneNumber, email, password, newsletterAccepted)
    }

    const callRegister = (name: string, familyName: string, phoneNumber: string, email: string, password: string, newsletterAccepted: boolean) => {
        setIsLoading(true)
        registerApi(
            email,
            password,
            name,
            familyName,
            phoneNumber,
            newsletterAccepted,
            () => {
                saveData(email, password)
                navigate("/email-registration-code")
                setIsLoading(false)
            },
            (error, showAlert) => {
                if (showAlert) {
                    alert("form error")
                } else {
                    setEmailError(true)
                }
                setIsLoading(false)
            }
        )
    }

    // ****************************************************************************************

    return (
        <div className="App relative">
            <div className={`w-[90%] h-[80px] flex justify-between items-center ${isLoading && "opacity-30 pointer-events-none"}`}>
                <BackButton onClick={onBackButtonClick} />
            </div>
            <div className={`w-full h-[calc(100%-120px)] flex justify-center mt-[20px] overflow-y-auto scrollbar-hide ${isLoading && "opacity-30 pointer-events-none"}`}>
                <div className="w-[328px] h-fit bg-white p-[20px]">
                    <RegistrationBox
                        onSubmit={handleInputsSubmit}
                        emailError={emailError}
                    />
                </div>
            </div>
            {
                isLoading && <div className="w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center"><LoadingSpinner /></div>
            }
        </div>
    )
}

export default EmailRegistration
