import { useNavigate } from "react-router-dom"
import WhiteButton from "../../../../components/buttons/WhiteButton"
import { NEW_PLATFORM_HOME_LOGO_BLACK } from "../../../../utils/Constants"
import CloseButton from "../../components/CloseButton"

const HealthAssessmentSuccessPage = () => {
  const navigate = useNavigate()

  // ********************************************************************************
  return (
    <div className="NewApp">
      <div className="w-full h-[100px] flex items-center justify-center border-b border-solid border-black md:h-[200px]">
        <div className="w-[90%] h-[90%] flex items-center justify-between md:w-[80%]">
          <div className="flex items-center">
            <img className="mr-[10px]" src="/images/new-platform/logos/holifya-logo-no-text.svg" alt="holifya-logo" />
            <div className="font-bold text-[12px] leading-[17px]">Questionario | Health Assessment</div>
          </div>
          <CloseButton onClick={() => navigate("/home")} />
        </div>
      </div>
      <div className="w-full h-[calc(100%-220px)] overflow-y-auto scrollbar-hide md:w-[80%] md:mt-[30px] md:h-fit">
        <div className="w-full h-full p-[20px] font-normal text-[16px] leading-[26px]">
          Fantastico! 🎉 <br/>
          Ora che hai risposto a tutte le domande la tua Medical Equipe ha molte più informazioni su di te.
          Ricordati di rispondere al questionario di check-up, disponibile sempre in Home ( <span><img className="align-middle inline-block m-0 p-0 h-auto w-auto" src={NEW_PLATFORM_HOME_LOGO_BLACK}/></span> ),  per condividere aggiornamenti e progressi!
        </div>
      </div>
      <div className="w-full h-[120px] flex items-center justify-center">
        <div className="w-[90%] h-[50px]">
          <WhiteButton
            text="Chiudi"
            onClick={() => navigate("/home")}
            fontSize={16}
            uppercase={false}
          />
        </div>
      </div>
    </div>
  )
}

export default HealthAssessmentSuccessPage
