export enum AppointmentStatus {
    Active = "active",
    Canceled = "canceled",
}

export enum ConsultType {
    Specialist = "specialist",
    Nutritionist = "nutritionist",
}

export enum PurchaseType {
    OneOff = "one_off",
    Subscription = "subscription",
}