import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { Tutorial } from '../api-objects/Tutorial';

interface TutorialState {
    tutorial: Tutorial | null;
}

// Define the shape of the tutorial context
interface TutorialContextType {
    tutorial: TutorialState;
    updateTutorial: (type: Tutorial | null) => void;
    clearTutorial: () => void;
}

// Create the health plan context with default values
const TutorialContext = createContext<TutorialContextType | undefined>(undefined);

// Create a provider component
export const TutorialProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    // Initialize state with data from localStorage
    const [tutorial, setTutorial] = useState<TutorialState>(() => {
        const savedTutorial = localStorage.getItem('tutorial');

        return {
            tutorial: savedTutorial ? (JSON.parse(savedTutorial) as Tutorial) : null,
        };
    });

    // Effect to update localStorage when tutorialType or unconfirmedTutorialType changes
    useEffect(() => {
        if (tutorial.tutorial) {
            localStorage.setItem('tutorial', JSON.stringify(tutorial.tutorial));
        } else {
            localStorage.removeItem('tutorial');
        }
    }, [tutorial.tutorial]);

    // Function to update confirmed tutorial type
    const updateTutorial = (tutorial: Tutorial | null) => {
        setTutorial((prev) => ({ ...prev, tutorial }));
    };

    // Function to clear both confirmed and unconfirmed tutorial types
    const clearTutorial = () => {
        setTutorial({ tutorial: null });
        localStorage.removeItem('tutorial');
    };

    // Provide context value to children
    return (
        <TutorialContext.Provider value={{ tutorial, updateTutorial, clearTutorial }}>
            {children}
        </TutorialContext.Provider>
    );
};

// Custom hook to use the tutorial context
export const useTutorial = (): TutorialContextType => {
    const context = useContext(TutorialContext);
    if (context === undefined) {
        throw new Error('useTutorial must be used within a TutorialProvider');
    }
    return context;
};
