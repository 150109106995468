import { createContext, ReactNode, useContext, useEffect, useState } from "react";

interface RegistrationState {
    registrationEmail: string | null;
    password: string | null;
}

// Define the shape of the registration context
interface RegistrationContextType {
    registrationData: RegistrationState;
    saveData: (registrationEmail: string, password: string) => void;
    clearData: () => void;
}

// Create the registration context with default values
const RegistrationContext = createContext<RegistrationContextType | undefined>(undefined);

// Create a provider component
export const RegistrationProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    // Initialize state with data from localStorage
    const [registrationData, setRegistrationData] = useState<RegistrationState>({
        registrationEmail: localStorage.getItem('registrationEmail'),
        password: localStorage.getItem('password'),
    });

    // Effect to update localStorage when registration state changes
    useEffect(() => {
        if (registrationData.registrationEmail) {
            localStorage.setItem('registrationEmail', registrationData.registrationEmail);
        } else {
            localStorage.removeItem('registrationEmail');
        }
        
        if (registrationData.password) {
            localStorage.setItem('password', registrationData.password);
        } else {
            localStorage.removeItem('password');
        }
    }, [registrationData]);

    // Function to log in
    const saveData = (registrationEmail: string, password: string) => {
        setRegistrationData({ registrationEmail, password });
    };

    // Function to log out
    const clearData = () => {
        setRegistrationData({ registrationEmail: null, password: null });
    };

    // Provide context value to children
    return (
        <RegistrationContext.Provider value={{ registrationData, saveData, clearData }}>
            {children}
        </RegistrationContext.Provider>
    );
};

// Custom hook to use the registration context
export const useRegistrationData = (): RegistrationContextType => {
    const context = useContext(RegistrationContext);
    if (context === undefined) {
        throw new Error('useRegistrationData must be used within a RegistrationProvider');
    }
    return context;
};
