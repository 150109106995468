import { format, parseISO } from 'date-fns';
import Expert from "../components/Expert";
import { CalendlyExpert } from "./api-objects/CalendlyExpert";
import { ExpertGender, ExpertGenderRole, ExpertRole } from "./enums/Experts";
import { ProductType } from './enums/ProductType';
import { QuestionCategory } from './enums/Surveys';

export const handleDate = (date: string): string => {
    const parsedDate = parseISO(date);
    return format(parsedDate, 'dd/MM/yyyy');
}

export const handleExpertRole = (role: string) => {
    switch (role) {
        case ExpertRole.Nutritionist:
            return "Nutrizionista"
        case ExpertRole.Specialist:
            return "Medico Refertante"
        default:
            return ""
    }
}

/**
 * Function giving the days from now to the input date
 * 
 * @param date 
 * @returns number of days rounded
 */
export const handleDaysDifference = (date: string) => {
    const today = new Date()
    const futureDate = new Date(date)
    const differenceInDays = Math.round((futureDate.getTime() - today.getTime()) / (1000 * 3600 * 24))
    return differenceInDays
}

/**
 * Function giving the hours from now to the input date
 * 
 * @param date 
 * @returns number of hours rounded
 */
export const handleHoursDifference = (date: string) => {
    const today = new Date()
    const futureDate = new Date(date)
    const differenceInDays = Math.round((futureDate.getTime() - today.getTime()) / (1000 * 3600))
    return differenceInDays
}

/**
 * Function giving the minutes from now to the input date
 * 
 * @param date 
 * @returns number of minutes rounded
 */
export const handleMinutesDifference = (date: string) => {
    const today = new Date()
    const futureDate = new Date(date)
    const differenceInDays = Math.round((futureDate.getTime() - today.getTime()) / (1000 * 60))
    return differenceInDays
}

/**
 * Function giving the day of the month of the input date
 * 
 * @param date 
 * @returns the number of the day of month
 */
export const handleDayOfMonth = (date: string) => {
    const realDate = new Date(date)
    const day = realDate.getDate()
    return day
}

/**
 * Function giving a 3 letters string of the input date month
 * 
 * @param date 
 * @returns the string representing the month
 */
export const handleMonthString = (date: string) => {
    const realDate = new Date(date)
    const day = realDate.getMonth() + 1
    switch (day) {
        case 1:
            return "gen"
        case 2:
            return "feb"
        case 3:
            return "mar"
        case 4:
            return "apr"
        case 5:
            return "mag"
        case 6:
            return "giu"
        case 7:
            return "lug"
        case 8:
            return "ago"
        case 9:
            return "set"
        case 10:
            return "ott"
        case 11:
            return "nov"
        case 12:
            return "dic"
        default:
            return ""
    }
}

/**
 * Function giving the hour of the input date
 * 
 * @param date 
 * @returns string representing the hout of the input
 */
export const handleHourAndMinutes = (date: string) => {
    const realDate = new Date(date)
    const hour = realDate.getHours()
    const minutes = realDate.getMinutes()
    return `${hour < 10 ? `0${hour.toString()}` : hour}:${minutes < 10 ? `0${minutes.toString()}` : minutes}`
}

export const fromQuestionCategoryToString = (category: QuestionCategory, includeEmoji: boolean = true) => {
    switch (category) {
        case QuestionCategory.PersonalGoals:
            return `${includeEmoji ? "\u{1F642} " : ""}Personali e obiettivi`;
        case QuestionCategory.FamilyHistoryDiseases:
            return `${includeEmoji ? "\u{1F915} " : ""}Familiarità e patologie`;
        case QuestionCategory.LifestyleSports:
            return `${includeEmoji ? "\u{1F3CB} " : ""}Stile di vita e attività sportiva`;
        case QuestionCategory.DietaryHabits:
            return `${includeEmoji ? "\u{1F959} " : ""}Abitudini alimentari`;
        case QuestionCategory.Notes:
            return `${includeEmoji ? "\u{1F3CB} " : ""}Note`;
    }
}

export const handleShopifyProductName = (product: ProductType) => {
    switch (product) {
        case ProductType.DnaTest:
            return "🧬 DNA Test Complete"
        case ProductType.BloodTest:
            return "🩸 Analisi degli Aminoacidi"
        case ProductType.ExpertConsult:
            return "👩‍⚕️ Consulto Medico"
        case ProductType.Nutritionist:
            return "👨‍⚕️ Consulto Nutrizionista"
        case ProductType.SubHealth:
        case ProductType.SubHealthDna:
            return "🌀 Percorso Health"
        case ProductType.SubNutrition:
        case ProductType.SubNutritionDna:
            return "🍎 Percorso Nutrition"
    }
}

/**
 * Function to get a random nutritionist from a list.
 * @param nutritionists - List of available nutritionists.
 * @param userId - The user's ID to filter nutritionists.
 * @returns A randomly selected nutritionist.
 */
export const getRandomNutritionist = (nutritionists: CalendlyExpert[]) => {
    if (nutritionists.length === 0) {
        throw new Error("No eligible nutritionists found.");
    }

    const index = Math.floor(Math.random() * nutritionists.length);
    return nutritionists[index];
};

/**
 * Creates an Expert object from a CalendlyExpert.
 * @param nutritionist - The selected nutritionist.
 * @returns A formatted Expert object.
 */
export const createExpertFromCalendlyExpert = (nutritionist: CalendlyExpert): Expert => {
    return {
        id: nutritionist.id,
        position: nutritionist.data.sex === ExpertGender.Male ? ExpertGenderRole.Dott : ExpertGenderRole.Dottssa,
        name: nutritionist.data.name,
        familyName: nutritionist.data.family_name,
        role: handleExpertRole(nutritionist.role),
        skills: nutritionist.tags,
        summary: nutritionist.data.bio,
        avatar: nutritionist.calendly_data.calendly_user.avatar_url,
    };
};
