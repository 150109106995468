import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { Appointment } from '../../pages/new-platform/components/Appointment';


interface ConsultsState {
    specialistConsultsLeft: number | null,
    nutritionistConsultsLeft: number | null,
    specialistFutureConsults: Appointment[] | null,
    nutritionistFutureConsults: Appointment[] | null,
    mostRecentFutureAppointment: Appointment | null;
    allAppointments: Appointment[] | null;
    allFutureAppointments: Appointment[] | null;
    allPastAppointments: Appointment[] | null,
}

// Define the shape of the consults context
interface ConsultsContextType {
    consultsData: ConsultsState;
    updateSpecialistConsultsLeft: (left: number) => void;
    updateNutritionistConsultsLeft: (left: number) => void;
    updateSpecialistFutureConsults: (appointments: Appointment[] | null) => void;
    updateNutritionistFutureConsults: (appointments: Appointment[] | null) => void;
    updateMostRecentFutureAppointment: (appointment: Appointment | null) => void;
    updateAllAppointments: (appointments: Appointment[]) => void;
    updateAllFutureAppointments: (appointments: Appointment[]) => void;
    updateAllPastAppointments: (appointments: Appointment[]) => void;
    clearConsults: () => void;
}

// Create the consults context with default values
const ConsultsContext = createContext<ConsultsContextType | undefined>(undefined);

// Create a provider component
export const ConsultsProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    // Helper function to safely parse JSON from localStorage
    const safeParseJSON = (key: string) => {
        const item = localStorage.getItem(key);
        if (item === null || item === undefined || item === "undefined") {
            // Return null if the item is not found or undefined
            return null;
        }
        try {
            return JSON.parse(item);
        } catch (error) {
            console.error(`Error parsing ${key} from localStorage:`, error);
            return null;
        }
    };

    // Initialize state with data from localStorage
    const [consultsData, setConsultsData] = useState<ConsultsState>({
        specialistConsultsLeft: localStorage.getItem('specialistConsultsLeft')
            ? Number(localStorage.getItem('specialistConsultsLeft'))
            : null,
        nutritionistConsultsLeft: localStorage.getItem('nutritionistConsultsLeft')
            ? Number(localStorage.getItem('nutritionistConsultsLeft'))
            : null,
        specialistFutureConsults: safeParseJSON('specialistFutureConsults'),
        nutritionistFutureConsults: safeParseJSON('nutritionistFutureConsults'),
        mostRecentFutureAppointment: safeParseJSON('mostRecentFutureAppointment'),
        allAppointments: safeParseJSON('allAppointments'),
        allFutureAppointments: safeParseJSON('allFutureAppointments'),
        allPastAppointments: safeParseJSON('allPastAppointments'),
    });

    // Effect to update localStorage when consults state changes
    useEffect(() => {
        if (consultsData.specialistConsultsLeft !== null) {
            localStorage.setItem('specialistConsultsLeft', consultsData.specialistConsultsLeft.toString());
        } else {
            localStorage.removeItem('specialistConsultsLeft');
        }

        if (consultsData.nutritionistConsultsLeft !== null) {
            localStorage.setItem('nutritionistConsultsLeft', consultsData.nutritionistConsultsLeft.toString());
        } else {
            localStorage.removeItem('nutritionistConsultsLeft');
        }

        if (consultsData.specialistFutureConsults !== null) {
            localStorage.setItem('specialistFutureConsults', JSON.stringify(consultsData.specialistFutureConsults));
        } else {
            localStorage.removeItem('specialistFutureConsults');
        }

        if (consultsData.nutritionistFutureConsults !== null) {
            localStorage.setItem('nutritionistFutureConsults', JSON.stringify(consultsData.nutritionistFutureConsults));
        } else {
            localStorage.removeItem('nutritionistFutureConsults');
        }

        if (consultsData.mostRecentFutureAppointment !== null) {
            localStorage.setItem('mostRecentFutureAppointment', JSON.stringify(consultsData.mostRecentFutureAppointment));
        } else {
            localStorage.removeItem('mostRecentFutureAppointment');
        }

        if (consultsData.allAppointments !== null) {
            localStorage.setItem('allAppointments', JSON.stringify(consultsData.allAppointments));
        } else {
            localStorage.removeItem('allAppointments');
        }

        if (consultsData.allFutureAppointments !== null) {
            localStorage.setItem('allFutureAppointments', JSON.stringify(consultsData.allFutureAppointments));
        } else {
            localStorage.removeItem('allFutureAppointments');
        }

        if (consultsData.allPastAppointments !== null) {
            localStorage.setItem('allPastAppointments', JSON.stringify(consultsData.allPastAppointments));
        } else {
            localStorage.removeItem('allPastAppointments');
        }
    }, [consultsData]);

    // Function to update specialist consults left
    const updateSpecialistConsultsLeft = (specialistConsultsLeft: number) => {
        setConsultsData(prevState => ({ ...prevState, specialistConsultsLeft }));
    };

    // Function to update nutritionist consults left
    const updateNutritionistConsultsLeft = (nutritionistConsultsLeft: number) => {
        setConsultsData(prevState => ({ ...prevState, nutritionistConsultsLeft }));
    };

    // Function to update specialist future consults
    const updateSpecialistFutureConsults = (appointments: Appointment[] | null) => {
        setConsultsData(prevState => ({ ...prevState, specialistFutureConsults: appointments }));
    };

    // Function to update nutritionist future consults
    const updateNutritionistFutureConsults = (appointments: Appointment[] | null) => {
        setConsultsData(prevState => ({ ...prevState, nutritionistFutureConsults: appointments }));
    };

    // Function to update the most recent future appointment
    const updateMostRecentFutureAppointment = (appointment: Appointment | null) => {
        setConsultsData(prevState => ({ ...prevState, mostRecentFutureAppointment: appointment }));
    };

    // Function to update all appointments
    const updateAllAppointments = (appointments: Appointment[]) => {
        setConsultsData(prevState => ({ ...prevState, allAppointments: appointments }));
    };

    // Function to update all future appointments
    const updateAllFutureAppointments = (appointments: Appointment[]) => {
        setConsultsData(prevState => ({ ...prevState, allFutureAppointments: appointments }));
    };

    // Function to update all past appointments
    const updateAllPastAppointments = (appointments: Appointment[]) => {
        setConsultsData(prevState => ({ ...prevState, allPastAppointments: appointments }));
    };

    // Function to clear consults data
    const clearConsults = () => {
        setConsultsData({
            specialistConsultsLeft: null,
            nutritionistConsultsLeft: null,
            specialistFutureConsults: null,
            nutritionistFutureConsults: null,
            mostRecentFutureAppointment: null,
            allAppointments: null,
            allFutureAppointments: null,
            allPastAppointments: null,
        });
        localStorage.removeItem('specialistConsultsLeft');
        localStorage.removeItem('nutritionistConsultsLeft');
        localStorage.removeItem('specialistFutureConsults');
        localStorage.removeItem('nutritionistFutureConsults');
        localStorage.removeItem('mostRecentFutureAppointment');
        localStorage.removeItem('allAppointments');
        localStorage.removeItem('allFutureAppointments');
        localStorage.removeItem('allPastAppointments');
    };

    // Provide context value to children
    return (
        <ConsultsContext.Provider
            value={{
                consultsData,
                updateSpecialistConsultsLeft,
                updateNutritionistConsultsLeft,
                updateSpecialistFutureConsults,
                updateNutritionistFutureConsults,
                updateMostRecentFutureAppointment,
                updateAllAppointments,
                updateAllFutureAppointments,
                updateAllPastAppointments,
                clearConsults,
            }}
        >
            {children}
        </ConsultsContext.Provider>
    );
};

// Custom hook to use the consults context
export const useConsults = (): ConsultsContextType => {
    const context = useContext(ConsultsContext);
    if (context === undefined) {
        throw new Error('useConsults must be used within a ConsultsProvider');
    }
    return context;
};
