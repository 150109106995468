export enum SurveyType {
    HealthAssessment = "health_assessment",
    CheckupSurvey = "checkup_survey",
}

export enum QuestionType {
    MultiSelect = "multi_select",
    Radio = "radio",
    SingleValueText = "single_value_text",
    SingleValueNumber = "single_value_number",
    OpenParagraph = "open_paragraph",
}

export enum QuestionCategory {
    PersonalGoals = "personali_e_obiettivi",
    FamilyHistoryDiseases = "familiarita_patologie",
    LifestyleSports = "stile_di_vita_e_attivita",
    DietaryHabits = "abitudini_alimentari",
    Notes = "note",
}

export enum QuestionScoreCategory {
    ScoreCategoryOne = "score_category_one",
    ScoreCategoryTwo = "score_category_two",
    ScoreCategoryThree = "score_category_three",
    ScoreCategoryFour = "score_category_four"
}