interface Props {
    text?: string,
    onClick: () => void,
}

const BackButton: React.FC<Props> = ({
    text,
    onClick
}) => {
    return (
        <div className="flex items-center cursor-pointer" onClick={onClick}>
            <div className="mr-[10px] leading-none"><img src="/images/new-platform/components/back-arrow-blue.svg" /></div>
            <div className="font-semibold text-[12px] leading-[17px] text-holifya-blue">{text ? text : "Indietro"}</div>
        </div>
    )
}

export default BackButton
