import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import WhiteButton from "../../../../components/buttons/WhiteButton";

interface Props {
    productName: string,
    onConfirmClick: () => void,
    isLoading: boolean,
    onClose: () => void,
}
const RedeemPopup: React.FC<Props> = ({
    productName,
    onConfirmClick,
    isLoading,
    onClose,
}) => {
    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onConfirmClick();
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="w-[90%] px-[10px] py-[20px] bg-white rounded-[15px] shadow-platform md:w-[362px] md:px-[20px]">
                <form
                    onSubmit={handleFormSubmit}
                >
                    <div className="font-bold text-[20px] leading-[26px] flex justify-between items-center mb-4">
                        {productName}
                    </div>
                    <div className="font-normal text-[16px] leading-[26px]">
                        Confermi di voler assegnare al tuo account 1x <strong>{productName}</strong>
                    </div>
                    <div className="w-full h-[100px] flex flex-col justify-between mt-[20px]">
                        <div className="w-full h-[50px]">
                            <PrimaryButton
                                text={"Conferma"}
                                pointer={true}
                                disabled={false}
                                isLoading={isLoading}
                            />
                        </div>
                        <div className="w-full h-[40px]">
                            <WhiteButton
                                text={"Chiudi"}
                                onClick={onClose}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default RedeemPopup
