import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { confirmForgotPasswordApi } from "../../../utils/apis/auth-service";
import NewPasswordBox from "./components/NewPasswordBox";

const NewPassword = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state.email
    const code = location.state.code

    const [isLoading, setIsLoading] = useState(false)

    const onBackButtonClick = () => {
        navigate("/forgot-password-insert-code", { state: { email } })
    }

    const handleInputsSubmit = (inputData: { [key: string]: string }) => {
        setIsLoading(true)
        const password = inputData.password
        confirmForgotPasswordApi(
            email,
            password,
            code,
            () => {
                setIsLoading(false)
                navigate("/change-password-success", { state: { email, password } })
            },
            (error) => {
                setIsLoading(false)
            }
        )
    }

    // ********************************************************************************

    return (
        <div className="App relative">
            <div className={`w-[90%] h-[80px] flex justify-between items-center ${isLoading && "opacity-30 pointer-events-none"}`}>
                <BackButton onClick={onBackButtonClick} />
            </div>
            <div className={`w-full h-[calc(100%-120px)] flex flex-col items-center justify-start sm:justify-center ${isLoading && "opacity-30 pointer-events-none"}`}>
                <div className="w-[328px] flex bg-white p-[20px]">
                    <NewPasswordBox
                        onSubmit={handleInputsSubmit}
                    />
                </div>
            </div>
            {
                isLoading && <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div>
            }
        </div>
    )
}

export default NewPassword
