import { useState } from "react"
import PrimaryButton from "../../../../components/buttons/PrimaryButton"
import WhiteButton from "../../../../components/buttons/WhiteButton"
import EmailInputElement from "../../../../components/input/v3/EmailInputElement"
import { SortingItem } from "../../../../utils/api-objects/SortingItem"
import { handleShopifyProductName } from "../../../../utils/Functions"

interface AssignPopupProps {
    item: SortingItem,
    initialRedeemableBy: string,
    onAssignConfirmClick: (newRedeemer: string) => void,
    onClose: () => void,
    isLoading: boolean,
}

const AssignPopup: React.FC<AssignPopupProps> = ({
    item,
    initialRedeemableBy,
    onAssignConfirmClick,
    onClose,
    isLoading,
}) => {
    const itemName = handleShopifyProductName(item.product_type)
    const [redeemableBy, setRedeemableBy] = useState(initialRedeemableBy);

    const handleRedeemableByChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRedeemableBy(e.target.value);
    };

    const handleConfirmClick = (e: React.FormEvent) => {
        e.preventDefault()
        onAssignConfirmClick(redeemableBy); // Pass the updated value to the parent
    };

    const assignButtonEnabled = redeemableBy !== initialRedeemableBy

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-4 rounded-[15px] shadow-lg flex flex-col w-[90%] md:w-[400px]">
                <form onSubmit={handleConfirmClick}>
                    <div className="font-bold text-[20px] leading-[26px] mb-[20px]">
                        {itemName}
                    </div>
                    <div className="font-normal text-[16px] leading-[26px] mb-[20px]">
                        Cambia il beneficiario di 1x <strong>{itemName}</strong>
                    </div>
                    <div>
                        <EmailInputElement
                            name={`${item.id}`}
                            label={""}
                            inputValue={redeemableBy}
                            onChange={handleRedeemableByChange}
                            placeholder={"Inserisci il beneficiario"}
                        />
                    </div>
                    <div className="w-full h-[100px] flex flex-col justify-between mt-[20px]">
                        <div className="w-full h-[50px]">
                            <PrimaryButton
                                text={"Conferma"}
                                pointer={assignButtonEnabled}
                                disabled={!assignButtonEnabled}
                                isLoading={isLoading}
                            />
                        </div>
                        <div className="w-full h-[40px]">
                            <WhiteButton
                                text={"Chiudi"}
                                onClick={onClose}
                                uppercase={false}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AssignPopup
