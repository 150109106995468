import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { HOLIFYA_LOGO } from "../../../utils/Constants"
import { Folder } from ".././components/Folder"
import FolderBox from ".././components/FolderBox"
import NavBar from ".././components/NavBar"
import { NavBarElement } from ".././components/NavBarElement"

const Diagnostica = () => {
  const navigate = useNavigate()
  const [currentItem, setCurrentItem] = useState(NavBarElement.Diagnostica)
  const onMenuClick = () => {
    if (currentItem === NavBarElement.Diagnostica) {
      setCurrentItem(NavBarElement.Menu)
    } else {
      setCurrentItem(NavBarElement.Diagnostica)
    }
  }
  return (
    <div className="NewApp">
      <div className="w-full h-full md:flex md:flex-row-reverse">
        <div className="w-full h-[calc(100%-90px)] flex flex-col items-center justify-end md:w-[calc(100%-100px)] md:h-full">
          <div className="w-[90%] h-[95%] flex flex-col">
            <div className="hidden md:block h-[35px] my-[10px]">
              <img src={HOLIFYA_LOGO} alt="Holifya Logo" />
            </div>
            <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
              Diagnostica
            </div>
            <div className="flex flex-col md:flex-row md:justify-between">
              <div className="w-full mb-[20px] md:w-[49%] md:mb-[0]">
                <FolderBox
                  folder={Folder.Results}
                  onClick={() => navigate("results-and-progresses")}
                />
              </div>
              <div className="w-full md:w-[49%]">
                <FolderBox
                  folder={Folder.TestsDocs}
                  onClick={() => navigate("tests-and-reports")}
                />
              </div>
            </div>
          </div>
        </div>
        <NavBar
          currentItem={currentItem}
          onMenuClick={onMenuClick}
        />
      </div>
    </div>
  )
}

export default Diagnostica
