import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { HOLIFYA_LOGO } from "../../../utils/Constants"
import BackButton from "./../components/BackButton"
import NavBar from "./../components/NavBar"
import { NavBarElement } from "./../components/NavBarElement"

const MealPlan = () => {
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const [currentItem, setCurrentItem] = useState(NavBarElement.HealthPlan)
    const onMenuClick = () => {
        if (currentItem === NavBarElement.HealthPlan) {
            setCurrentItem(NavBarElement.Menu)
        } else {
            setCurrentItem(NavBarElement.HealthPlan)
        }
    }

    // ********************************************************************************

    return (
        <div className="NewApp">
            <div className={`w-full h-full ${isLoading && "opacity-50 pointer-events-none"} md:flex md:flex-row-reverse`}>
                <div className="w-full h-[calc(100%-90px)] flex flex-col items-center justify-end overflow-y-auto scrollbar-hide md:w-[calc(100%-100px)] md:h-full">
                    <div className="w-[90%] h-[95%] flex flex-col">
                        <div className="hidden md:block h-[35px] my-[10px]">
                            <img src={HOLIFYA_LOGO} alt="Holifya Logo" />
                        </div>
                        <div className="my-[10px]">
                            <BackButton
                                text="Torna a Health Plan"
                                onClick={() => navigate("/health-plan")}
                            />
                        </div>
                        <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
                            Meal Plan
                        </div>
                        <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
                        <MealPlanCard
                            category={MealPlanCategory.Diet}
                            onClick={() => navigate(`${MealPlanCategory.Diet}`)}
                        />
                        <MealPlanCard
                            category={MealPlanCategory.ShoppingList}
                            onClick={() => navigate(`${MealPlanCategory.ShoppingList}`)}
                        />
                        <MealPlanCard
                            category={MealPlanCategory.EatingHabits}
                            onClick={() => navigate(`${MealPlanCategory.EatingHabits}`)}
                        />
                        <MealPlanCard
                            category={MealPlanCategory.TipsAndRecepes}
                            onClick={() => navigate(`${MealPlanCategory.TipsAndRecepes}`)}
                        />
                        <MealPlanCard
                            category={MealPlanCategory.SeasonalFruitsAndVegetables}
                            onClick={() => navigate(`${MealPlanCategory.SeasonalFruitsAndVegetables}`)}
                        />
                    </div>
                    </div>
                </div>
                <NavBar
                    currentItem={currentItem}
                    onMenuClick={onMenuClick}
                />
            </div>
        </div>
    )
}

export default MealPlan

enum MealPlanCategory {
    Diet = "diet",
    ShoppingList = "shopping-list",
    TipsAndRecepes = "tips-and-recepes",
    EatingHabits = "eating-habits",
    SeasonalFruitsAndVegetables = "seasonal-fruits-and-vegetables",
}

interface Props {
    category: MealPlanCategory,
    onClick: () => void,
}

const MealPlanCard: React.FC<Props> = ({
    category,
    onClick,
}) => {
    const handleCategoryName = () => {
        switch (category) {
            case MealPlanCategory.Diet:
                return "Dieta"
            case MealPlanCategory.ShoppingList:
                return "Liste della spesa"
            case MealPlanCategory.TipsAndRecepes:
                return "Tips e ricette"
            case MealPlanCategory.EatingHabits:
                return "Eating habits"
            case MealPlanCategory.SeasonalFruitsAndVegetables:
                return "Frutta e verdura di stagione"
        }
    }

    const handleCategoryEmoji = () => {
        switch (category) {
            case MealPlanCategory.Diet:
                return "🥙"
            case MealPlanCategory.ShoppingList:
                return "🛒"
            case MealPlanCategory.TipsAndRecepes:
                return "🧑‍🍳"
            case MealPlanCategory.EatingHabits:
                return "🍽"
            case MealPlanCategory.SeasonalFruitsAndVegetables:
                return "🍎"
        }
    }

    // ********************************************************************************

    return (
        <div
            className="w-full aspect-square rounded-[15px] shadow-platform flex items-center justify-center cursor-pointer md:h-[180px]"
            onClick={onClick}
        >
            <div className="w-[80%] h-[80%] flex flex-col justify-between">
                <div className="font-bold text-[12px] leading-[20px]">{handleCategoryName()}</div>
                <div className="w-[35px] h-[35px] flex items-center justify-center text-[35px] leading-[35px] self-end md:w-[80px] md:h-[80px] md:text-[80px]">{handleCategoryEmoji()}</div>
            </div>
        </div>
    )
}
