/**
 * Enum for the collecting process
 * @readonly
 * @enum {string}
 */
export enum TrackingStatus {
    /** @member {string} */
    /** The user has completed the purchase */
    Purchased = "purchased",
     /** @member {string} */
    /** The user has received the test at home */
    Delivered = "delivered",
    /** @member {string} */
    /** The user has performed the cheeck swab */
    TestDone = "test_done",
    /** @member {string} */
    /** The user has registered the swab */
    SampleRegistered = "sample_registered",
    /** @member {string} */
    /** The user has packed and requested the pickup */
    PickupRequested = "pickup_requested",
    /** @member {string} */
    /** The pack has been taken from the transport company */
    Departed = "departed",
    /** @member {string} */
    /** The sample is at the laboratory */
    DeliveredToLaboratory = "delivered_to_laboratory",
    StartingToExtract = "starting_to_extract",
    StartingAnalysis = "starting_analysis",
    AnalysisDone = "analysis_done",
    ReportGenerated = "report_generated"
}

export enum BloodTestTrackingStatus {
    Purchased = "purchased",
    Delivered = "delivered",
    TestDone = "test-done",
    SampleRegistered = "sample-registered",
    PickupRequested = "pickup-requested",
    DeliveredToLab = "delivered-to-laboratory",
    ReportGenerated = "report-generated",
    None = "none",
}
