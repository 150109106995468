import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { CalendlyExpert } from '../api-objects/CalendlyExpert';

interface ExpertsState {
    nutritionist: CalendlyExpert | null;
    specialist: CalendlyExpert | null;
}

// Define the shape of the experts context
interface ExpertsContextType {
    experts: ExpertsState;
    updateNutritionist: (nutritionist: CalendlyExpert | null) => void;
    updateSpecialist: (specialist: CalendlyExpert | null) => void;
    clearExperts: () => void;
}

// Create the experts context with default values
const ExpertsContext = createContext<ExpertsContextType | undefined>(undefined);

// Create a provider component
export const ExpertsProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    // Initialize state with data from localStorage
    const [experts, setExperts] = useState<ExpertsState>(() => {
        const savedNutritionist = localStorage.getItem('nutritionist');
        const savedSpecialist = localStorage.getItem('specialist');
        return {
            nutritionist: savedNutritionist ? JSON.parse(savedNutritionist) as CalendlyExpert : null,
            specialist: savedSpecialist ? JSON.parse(savedSpecialist) as CalendlyExpert : null,
        };
    });

    // Effect to update localStorage when experts state changes
    useEffect(() => {
        if (experts.nutritionist) {
            localStorage.setItem('nutritionist', JSON.stringify(experts.nutritionist));
        } else {
            localStorage.removeItem('nutritionist');
        }

        if (experts.specialist) {
            localStorage.setItem('specialist', JSON.stringify(experts.specialist));
        } else {
            localStorage.removeItem('specialist');
        }
    }, [experts.nutritionist, experts.specialist]);

    // Function to update only the nutritionist
    const updateNutritionist = (nutritionist: CalendlyExpert | null) => {
        setExperts((prevState) => ({ ...prevState, nutritionist }));
    };

    // Function to update only the specialist
    const updateSpecialist = (specialist: CalendlyExpert | null) => {
        setExperts((prevState) => ({ ...prevState, specialist }));
    };

    // Function to clear experts
    const clearExperts = () => {
        setExperts({ nutritionist: null, specialist: null });
        localStorage.removeItem('nutritionist');
        localStorage.removeItem('specialist');
    };

    // Provide context value to children
    return (
        <ExpertsContext.Provider value={{ experts, updateNutritionist, updateSpecialist, clearExperts }}>
            {children}
        </ExpertsContext.Provider>
    );
};

// Custom hook to use the experts context
export const useExpert = (): ExpertsContextType => {
    const context = useContext(ExpertsContext);
    if (context === undefined) {
        throw new Error('useExpert must be used within a ExpertProvider');
    }
    return context;
};
