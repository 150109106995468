import { HealthPlan } from "../api-objects/HealthPlan";
import { BASE_URL, requestOptionsGET } from "../Constants";
import { ErrorWithMessageCallback, GetHealthPlanSuccessCallback } from "./callback-helpers";

export async function getHealthPlan(
    userId: string,
    idToken: string,
    onSuccess: GetHealthPlanSuccessCallback,
    onError: ErrorWithMessageCallback,
    handleSessionExpiry?: () => void,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/health-plans/user/${userId}`, requestOptionsGET(idToken))
        if (response.ok) {
            const healthPlan: HealthPlan = await response.json()
            onSuccess(healthPlan)
        } else if (response.status === 401 && handleSessionExpiry) {
            handleSessionExpiry();
        } else {
            onError("Errore nel recuperare l'health plan")
        }
    } catch (error) {
        console.error("Get Health Plan failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}