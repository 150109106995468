import { IMAGE_LOGO } from "../utils/Constants"

interface Props {
  image?: string,
}

const HolifyaFullLogo: React.FC<Props> = ({
  image,
}) => {
  const logo = image === undefined ? IMAGE_LOGO : image
  return (
    <img className="m-[5px]" src={logo} />
  )
}

export default HolifyaFullLogo
