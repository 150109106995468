import { useState } from "react"
import { useNavigate } from "react-router-dom"
import BackButton from "../../../components/BackButton"
import SecondaryButton from "../../../components/buttons/SecondaryButton"
import { loginApi } from "../../../utils/apis/auth-service"
import { useAuth } from "../../../utils/context/AuthContext"
import { useRegistrationData } from "../../../utils/context/RegistrationContext"
import LoadingSpinner from "../../new-platform/components/LoadingSpinner"
import '../../styles.css'
import ChangePasswordDialog from "./components/ChangePasswordDialog"
import LoginBox from "./components/LoginBox"


const LoginPage = () => {

    const navigate = useNavigate();
    const { login } = useAuth();
    const { registrationData } = useRegistrationData();

    const [isLoading, setIsLoading] = useState(false)
    const [passwordErrorText, setPasswordErrorText] = useState("")
    const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false)

    const registrationPending = registrationData.registrationEmail !== null && registrationData.password !== null

    const onBackButtonClick = () => {
        window.open("https://www.holifya.com/", "_self")
    }

    const onCreateAccountClick = () => {
        if (registrationPending) {
            navigate("/email-registration-code")
        } else {
            navigate("/email-registration")
        }
    }

    const handleLoginInputsSubmit = (inputData: { [key: string]: string }) => {
        const email = inputData.email
        const password = inputData.password
        callLogin(email, password)
    }

    const callLogin = (email: string, password: string) => {
        setIsLoading(true)
        setPasswordErrorText("")
        loginApi(
            email,
            password,
            (result) => {
                login(result.idToken, result.userId, result.accessToken, result.refreshToken, result.expiryTime);
                navigate("/fetch-user-data")
                setIsLoading(false)
            },
            (error, showChangePasswordDialog) => {
                setPasswordErrorText(error)
                if (showChangePasswordDialog) {
                    setShowChangePasswordDialog(true);
                }
                setIsLoading(false)
            }
        )
    }

    // ******************************************************************************************************************

    return (
        <div className={`App relative`}>
            <div className={`w-[90%] h-[80px] flex justify-between items-center ${(isLoading || showChangePasswordDialog) ? "opacity-30 pointer-events-none" : ""}`}>
                <BackButton
                    onClick={onBackButtonClick}
                />
                <div className="w-[159px] h-[55px]">
                    <SecondaryButton
                        text="Crea un account"
                        onClick={onCreateAccountClick}
                        fontSize={12}
                    />
                </div>
            </div>
            <div className={`w-full h-[calc(100%-130px)] sm:h-[calc(100%-180px)] flex flex-col items-center justify-start sm:justify-center mt-[30px] sm:mt-[20px] overflow-y-auto scrollbar-hide ${(isLoading || showChangePasswordDialog) ? "opacity-30 pointer-events-none" : ""}`}>
                <div className={`w-[328px] p-[20px] flex bg-white`}>
                    <LoginBox
                        onSubmit={handleLoginInputsSubmit}
                        passwordError={passwordErrorText}
                    />
                </div>
            </div>
            {
                isLoading && <div className="w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center"><LoadingSpinner /></div>
            }
            {
                showChangePasswordDialog && <ChangePasswordDialog onClick={() => setShowChangePasswordDialog(false)} />
            }
        </div>
    )
}

export default LoginPage
