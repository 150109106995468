import { FormEvent, useState } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import WhiteButtonBlackBorder from "../../../components/buttons/WhiteButtonBlackBorder"
import TextInputElement from "../../../components/input/v1/TextInputElement"
import { createProduct } from "../../../utils/apis/order-service"
import { updateUserInfoWithSwabcode } from "../../../utils/apis/user-service"
import { HOLIFYA_LOGO, PHARMACIES_CONFIRMATION_NUMBER } from "../../../utils/Constants"
import { useAuth } from "../../../utils/context/AuthContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { CreateProductChannel } from "../../../utils/enums/CreateProductChannel"
import { ProductType } from "../../../utils/enums/ProductType"

const PharmaciesRedeemSwabcode = () => {
    const navigate = useNavigate()
    const { auth } = useAuth()
    const idToken = auth.idToken
    const userId = auth.userId
    const { userData } = useUserData()
    const username = userData.name

    const [isLoading, setIsLoading] = useState(false)
    const [swabcode, setSwabcode] = useState("")
    const [errorText, setErrorText] = useState("")

    const onSubmitClick = (e: FormEvent) => {
        e.preventDefault()
        if (!userId || !idToken) return
        setIsLoading(true)
        updateUserInfoWithSwabcode(
            userId,
            idToken,
            swabcode,
            () => { 
                createProduct(
                    userId,
                    idToken,
                    ProductType.DnaTest,
                    () => {
                        navigate("/check-purchases")
                        setIsLoading(false)
                    },
                    (error) => {
                        console.log(error)
                    },
                    {
                        shopify_product: "one-off",
                        channel: CreateProductChannel.FromPharmacy,
                        code: PHARMACIES_CONFIRMATION_NUMBER,
                        buyer: null,
                        redeemer: null,
                    }
                )
            },
            (error) => { 
                setErrorText(error)
                setIsLoading(false)
            },
        )
    }

    const onSwabCodeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setErrorText("")
        setSwabcode(e.target.value)
    }

    const onBackClick = () => {
        navigate("/redeem")
    }

    // ********************************************************************************

    return (
        <div className="NewApp">
            <div className="w-full h-full flex flex-col items-center justify-end md:justify-center">
                <div className="w-[90%] h-[90%] flex flex-col items-center justify-between md:w-[435px] md:h-[70%]">
                    <div className="flex flex-col items-center">
                        <div className="h-[35px] mb-[10px]">
                            <img src={HOLIFYA_LOGO} alt="Holifya Logo" />
                        </div>
                        <div className="font-normal text-[16px] leading-[24px] text-center mt-[10px]">
                            Ciao {username}, hai acquistato un test del DNA in farmacia.
                        </div>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="font-bold text-[25px] leading-[33px] mb-[10px]">
                            Inserisci il codice campione
                        </div>
                        <div className="font-normal text-[16px] leading-[24px] text-center mt-[10px]">
                            In farmacia ti hanno dato il codice campione da inserire nella piattaforma. Scrivilo qui sotto e verrai reindirizzato nella tua area personale.
                        </div>
                    </div>
                    <form
                        className="w-full mb-[20px] md:px-[30px]"
                        onSubmit={onSubmitClick}
                    >
                        <div className="h-[85px] mb-[20px]">
                            <TextInputElement
                                label="Codice campione"
                                placeholder="Es: as6TLu53Gvi9"
                                errorText={errorText}
                                inputValue={swabcode}
                                onChange={onSwabCodeInputHandler}
                            />
                        </div>
                        <div className="w-full h-[120px] flex flex-col justify-between">
                            <div className="w-full h-[50px]">
                                <PrimaryButton
                                    text="Conferma codice"
                                    pointer={swabcode.length >= 12}
                                    disabled={swabcode.length < 12}
                                    isLoading={isLoading}
                                />
                            </div>
                            <div className="w-full h-[50px]">
                                <WhiteButtonBlackBorder
                                    text="Indietro"
                                    onClick={onBackClick}
                                    uppercase={false}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default PharmaciesRedeemSwabcode
