
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import { tutorialSlidesData } from "../../../../utils/Constants";
import { useUserData } from "../../../../utils/context/UserDataContext";
import { TutorialType } from "../../../../utils/enums/TutorialType";
import BackButton from "../../components/BackButton";

interface TutorialProps {
    index: number;
    onNextClick: () => void;
    onCloseClick: () => void;
    onBackClick: () => void;
    type: TutorialType
}

export const TutorialCard: React.FC<TutorialProps> = ({
    index,
    onNextClick,
    onCloseClick,
    onBackClick,
    type,
}) => {
    const { userData } = useUserData()
    const email = userData.email
    if (!email) return null

    const slides = tutorialSlidesData(email, type)
    if (index < 1 || index > slides.length) return null;

    const isLastSlide = index === slides.length;
    const isFirstSlide = index === 1;
    let containerHeightClass: string;
    switch (type) {
        case TutorialType.DNA_TEST:
            containerHeightClass = index === 4 ? "min-h-[1035px]" : "min-h-[655px]";
            break;
        case TutorialType.SUB_HEALTH:
            containerHeightClass = index === 7 ? "min-h-[850px]" : "min-h-[687px]";
            break;
        case TutorialType.SUB_NUTRITION:
            containerHeightClass = (index === 7 || index === 4) ? "min-h-[800px]" : "min-h-[655px]";
            break;
        case TutorialType.CONSULT:
            containerHeightClass = "min-h-[655px]"
            break;
    }

    const SlideCounter = () => (
        <div className="w-full flex justify-end font-medium text-[12px] leading-[17px]">
            {index}/{slides.length}
        </div>
    );

    // ********************************************************************************

    return (
        <div className={`w-full ${containerHeightClass} rounded-[15px] shadow-platform p-[20px] flex flex-col justify-between md:min-h-[629px]`}>
            <div>
                <SlideCounter />
                <Slide {...slides[index - 1]} />
            </div>
            <div>
                {
                    !isLastSlide &&
                    <div className="w-full h-[50px] mt-[20px] mb-[10px]">
                        <PrimaryButton
                            text="Avanti"
                            pointer={true}
                            disabled={false}
                            onClick={onNextClick}
                            fontSize={16}
                        />
                    </div>
                }
                <div className={`w-full h-[40px] flex items-center ${isFirstSlide ? "justify-end" : "justify-between"}`}>
                    {!isFirstSlide && <div className="ml-[10px]"><BackButton onClick={onBackClick} /></div>}
                    <div
                        className="font-bold text-[16px] leading-[23px] mr-[20px] cursor-pointer"
                        onClick={onCloseClick}
                    >
                        Chiudi
                    </div>
                </div>
            </div>
        </div>
    )
}

interface SlideProps {
    title: React.ReactNode,
    content: React.ReactNode,
    imageSrc?: string,
}

export const Slide: React.FC<SlideProps> = ({
    title,
    content,
    imageSrc,
}) => {
    return (
        <div>
            <div className="font-bold text-[20px] leading-[28px]">
                {title}
            </div>
            <div className="font-normal text-[16px] leading-[26px] my-[20px]">
                {content}
            </div>
            {imageSrc && <div className="w-full h-[168px] rounded-[15px] border border-solid border-black flex items-center justify-center md:h-[280px]">
                <img
                    src={imageSrc}
                    className="w-full h-full object-cover rounded-[15px]"
                />
            </div>}
        </div>
    )
}
