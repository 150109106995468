interface Props {
    text: string,
    onClick: () => void,
    pointer: boolean,
    fontSize?: number,
    uppercase?: boolean,
}

const WhiteButtonBlueBorder: React.FC<Props> = ({
    text,
    onClick,
    pointer,
    fontSize = 14,
    uppercase = true,
}) => {
  return (
    <button
        className={`w-full h-full border border-solid border-holifya-blue rounded-[5px] font-bold leading-[17px] text-holifya-blue bg-white ${uppercase && "uppercase"} ${pointer ? "cursor-pointer" : "cursor-not-allowed"} text-[${fontSize}px]`}
        onClick={onClick}
    >
      {text}
    </button>
  )
}

export default WhiteButtonBlueBorder
