export enum Folder {
    Results = "results-progress",
    TestsDocs = "tests-and-docs",
    DNATest = "dna-test",
    BloodExams = "blood-exams",
    HealthAssessment = "health-assessment",
    UploadedForYou = "uploaded-for-you",
    UploadedFromYou = "uploaded-from-you",
    PaymentMethod = "payment-method",
    Appointments = "appointments",
    Experts = "experts",
    MealPlan = "meal-plan",
}