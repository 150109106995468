import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import TntButton from "../../../../components/buttons/TntButton";
import WhiteButton from "../../../../components/buttons/WhiteButton";
import { updateBloodTrackerByUserId } from "../../../../utils/apis/dna-test-tracker";
import { useAuth } from "../../../../utils/context/AuthContext";
import { useBloodTestTracker } from "../../../../utils/context/BloodTextTrackerContext";
import { PickupChoice } from "../../../../utils/enums/PickupChoice";
import { BloodTestTrackingStatus } from "../../../../utils/enums/TrackingStatus";
import CloseButton from "../../components/CloseButton";
import NavBar from "../../components/NavBar";
import { NavBarElement } from "../../components/NavBarElement";
import { PickupWarningBox, RadioCard } from "../dna-test/RequestPickup";

const BloodRequestPickup = () => {
    const { auth } = useAuth()
    const userId = auth.userId
    const idToken = auth.idToken
    const navigate = useNavigate()
    const { bloodTestTracker, updateBloodTestTracker } = useBloodTestTracker()
    const [currentItem, setCurrentItem] = useState(NavBarElement.Diagnostica)
    const onMenuClick = () => {
        setCurrentItem((prev) => prev === NavBarElement.Diagnostica ? NavBarElement.Menu : NavBarElement.Diagnostica);
    };

    const [radioChoice, setRadioChoice] = useState<PickupChoice>()
    const onRadioCardClick = (choice: PickupChoice) => {
        setRadioChoice(choice)
        setTimeout(() => {
            warningDiv.current?.scrollIntoView({ behavior: 'smooth' })
        }, 100)
    }

    const isConfirmButtonEnabled = radioChoice !== undefined

    const warningDiv = useRef<null | HTMLDivElement>(null);

    const handleConfirmClick = () => {
        switch (radioChoice) {
            case PickupChoice.AtHome:
                window.open("https://www.tnt.it/booking/Booking.do", "_blank", "noreferrer")
                break;
            case PickupChoice.TNTPoint:
                window.open("https://www.tnt.it/contents/trova-fedex.html", "_blank", "noreferrer")
                break;
        }
        if (bloodTestTracker && bloodTestTracker.bloodStatus === BloodTestTrackingStatus.SampleRegistered) {
            if (!userId || !idToken) {
                return
            }

            updateBloodTrackerByUserId(
                userId,
                idToken,
                BloodTestTrackingStatus.PickupRequested,
                (tracker) => {
                    const newStatus = tracker.tracking_status
                    updateBloodTestTracker(newStatus)
                    navigate("/diagnostica/tests-and-reports/blood-test")
                },
                (error) => {
                    console.log(error)
                },
            )
        } else {
            navigate("/diagnostica/tests-and-reports/blood-test")
        }
    }

    // ********************************************************************************

    return (
        <div className="NewApp">
            <div className={`w-full h-full md:flex md:flex-row-reverse`}>
                <div className="w-full h-[calc(100%-90px)] flex flex-col items-center justify-end overflow-y-auto scrollbar-hide md:w-[calc(100%-100px)] md:h-full">
                    <div className="w-[90%] h-[95%] flex flex-col">
                        <div className="flex justify-end my-[10px]">
                            <CloseButton
                                onClick={() => navigate("/diagnostica/tests-and-reports/blood-test")}
                            />
                        </div>
                        <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
                            Richiedi il ritiro
                        </div>
                        <div className="font-bold text-[16px] leading-[26px]">
                            Ritiro a domicilio
                        </div>
                        <div className="font-normal text-[16px] leading-[26px]">
                            Se qui sotto selezioni “Ritiro a domicilio” verrai reindirizzato sul sito di FedEx TNT per inserire
                            i tuoi dati di ritiro:
                        </div>
                        <ul className="list-disc ml-[20px]">
                            <li>
                                seleziona l'opzione “Mittente convenzionato” e lascia la spunta su “Merce destinata in Italia” e prosegui;
                            </li>
                            <li>
                                inserisci i tuoi dati relativi a dove ritirare il tuo campione e prosegui;
                            </li>
                            <li>
                                inserisci le tue preferenze su quando ritirare il tuo campione e prosegui;
                            </li>
                            <li>
                                inserisci la tua mail e prosegui;
                            </li>
                            <li>
                                inserisci le dimensioni e il peso (c.ca 0,5 kg e 15cm x 15cm x 5cm) e prosegui;
                            </li>
                            <li>
                                controlla e conferma.
                            </li>
                        </ul>
                        <div className="mt-[5px]">
                            Il pacchetto contenente il tuo test del DNA, con la lettera di vettura allegata, verrà ritirato secondo le istruzioni da te fornite e consegnato in pochi giorni al nostro laboratorio.
                        </div>
                        <div className="font-bold text-[16px] leading-[26px] mt-[15px]">
                            Consegna in FedEx TNT Point
                        </div>
                        <div className="font-normal text-[16px] leading-[26px]">
                            Se qui sotto selezioni “Consegna in FedEx TNT Point”, dopo la conferma verrai reindirizzato al sito di FedEx TNT per trovare il FedEx TNT Point più vicino a te.<br />
                            Porta il pacchetto contenente il tuo test del DNA, con la lettera di vettura allegata, al FedEx TNT Point che preferisci. La lettera di vettura allegata permetterà al tuo campione di arrivare in pochi giorni al nostro laboratorio.
                        </div>
                        <div className="font-bold text-[16px] leading-[26px] mt-[15px]">
                            Cos'è una lettera di vettura?
                        </div>
                        <div className="font-normal text-[16px] leading-[26px] italic">
                            La lettera di vettura è un foglio allegato ad ogni Kit Holifya, che troverete all'interno del pacchetto di spedizione, ed è
                            La lettera di vettura è essenziale per rispedire il campione al nostro laboratorio e andrà consegnata assieme alla busta TNT.
                        </div>
                        <div className="w-full h-[180px] flex flex-col justify-between mt-[20px] mb-[20px] flex-shrink-0">
                            <RadioCard
                                choice={PickupChoice.AtHome}
                                isChecked={radioChoice === PickupChoice.AtHome}
                                onClick={onRadioCardClick}
                            />
                            <RadioCard
                                choice={PickupChoice.TNTPoint}
                                isChecked={radioChoice === PickupChoice.TNTPoint}
                                onClick={onRadioCardClick}
                            />
                        </div>
                        {
                            radioChoice && <div ref={warningDiv}><PickupWarningBox choice={radioChoice}></PickupWarningBox></div>
                        }
                        <div className="w-full h-[100px] flex flex-col justify-between mt-[20px] flex-shrink-0">
                            <div className="w-full h-[50px]">
                                <TntButton
                                    text="Conferma"
                                    pointer={isConfirmButtonEnabled}
                                    disabled={!isConfirmButtonEnabled}
                                    fontSize={16}
                                    uppercase={true}
                                    onClick={handleConfirmClick}
                                />
                            </div>
                            <div className="w-full h-[40px]">
                                <WhiteButton
                                    text="Indietro"
                                    onClick={() => navigate("/diagnostica/tests-and-reports/blood-test/packing")}
                                    fontSize={16}
                                    uppercase={false}
                                />
                            </div>
                        </div>
                        <div className="pt-[10px]"/>
                    </div>
                </div>
                <NavBar
                    currentItem={currentItem}
                    onMenuClick={onMenuClick}
                />
            </div>
        </div>
    )
}

export default BloodRequestPickup
