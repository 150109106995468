import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { confirmCodeApi, resendCodeApi } from "../../../utils/apis/auth-service"
import { useRegistrationData } from "../../../utils/context/RegistrationContext"
import LoadingSpinner from "../../new-platform/components/LoadingSpinner"
import RegistrationCodeBox from "./components/RegistrationCodeBox"

const EmailRegistrationInsertCode = () => {

    const navigate = useNavigate();
    const { registrationData } = useRegistrationData()
    const email = registrationData.registrationEmail

    const [codeErrorText, setCodeErrorText] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const handleResendCode = () => {
        email !== null && resendCodeApi(
            email,
            () => {},
            () => {}
        )
    }

    const handleInputSubmit = (inputData: { [key: string]: string }) => {
        setCodeErrorText("")
        const code = inputData.code
        setIsLoading(true)
        email !== null && confirmCodeApi(
            email,
            code,
            () => {
                setIsLoading(false)
                navigate("/registration-success")
            },
            (error) => {
                setIsLoading(false)
                console.log(error)
                setCodeErrorText(error)
            }
        )
    }

    // ********************************************************************************

    return (
        <div className="App">
            <div className={`w-full h-full flex items-center justify-center ${isLoading && "opacity-30 pointer-events-none"}`}>
                <div className="w-[328px] bg-white p-[20px]">
                    <RegistrationCodeBox
                        onSubmit={handleInputSubmit}
                        onResendCodeClick={handleResendCode}
                        errorText={codeErrorText}
                    />
                </div>
            </div>
            {
                isLoading && <div className="w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center"><LoadingSpinner /></div>
            }
        </div>
    )
}

export default EmailRegistrationInsertCode
