import { useEffect, useState } from "react";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import WhiteButton from "../../../../components/buttons/WhiteButton";
import TextInputElement from "../../../../components/input/v1/TextInputElement";

interface Props {
    onSubmit: (inputData: { [key: string]: string }) => void;
    onResendCodeClick: () => void,
    errorText: string,
}

const RegistrationCodeBox: React.FC<Props> = ({
    onSubmit,
    onResendCodeClick,
    errorText,
}) => {
    const [codeError, setCodeError] = useState(errorText)

    const [inputs, setInputs] = useState<{ [key: string]: string }>({
        code: '',
    });

    const isSubmitButtonEnabled = inputs.code.length === 6

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setCodeError("")

        setInputs((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        onSubmit(inputs);
    }

    useEffect(() => {
        setCodeError(errorText)
    }, [errorText])

    // ********************************************************************************

    return (
        <div className="w-full flex flex-col items-center">
            <div className="font-bold text-[30px] leading-[40px] my-[20px]">
                Inserisci codice
            </div>
            <div className="font-normal text-[18px] leading-[26px] text-center my-[20px]">
                Hai ricevuto per e-mail un codice a 6 cifre per verificare il tuo indirizzo e-mail. Inseriscilo qui sotto.
            </div>
            <form
                className="w-full"
                onSubmit={handleSubmit}
            >
                <TextInputElement
                    name="code"
                    label={"Codice di verifica"}
                    placeholder={"000000"}
                    errorText={codeError}
                    inputValue={inputs.code}
                    onChange={handleInputChange}
                />
                <div className="h-[55px] mb-[20px]">
                    <PrimaryButton
                        text="Conferma codice"
                        pointer={isSubmitButtonEnabled}
                        disabled={!isSubmitButtonEnabled}
                    />
                </div>
            </form>
            <div className="w-[328px] h-[55px]">
                <WhiteButton
                    text="Invia nuovo codice"
                    onClick={onResendCodeClick}
                />
            </div>
        </div>
    )
}

export default RegistrationCodeBox
