import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import WhiteButtonBlackBorder from "../../../components/buttons/WhiteButtonBlackBorder";

const ForgotEmail = () => {

    const navigate = useNavigate();

    const onBackButtonClick = () => {
        navigate("/forgot-password")
    }

    const onWriteToUsClick = () => {
        window.open("mailto:support@holifya.com", "_blank");
    }

    const onToHomepageClick = () => {
        navigate("/login")
    }

    // ********************************************************************************

    return (
        <div className="App">
            <div className='w-[90%] h-[80px] flex justify-between items-center'>
                <BackButton onClick={onBackButtonClick} />
            </div>
            <div className="w-[392px] h-[calc(100%-120px)] flex flex-col items-center justify-start sm:justify-center">
                <div className="w-[328px] flex flex-col items-center bg-white p-[20px]">
                    <div className="font-bold text-[30px] leading-[40px] my-[20px]">
                        E-mail dimenticata
                    </div>
                    <div className="w-[328px] font-normal text-[18px] leading-[26px] text-center my-[20px]">
                        Se sei sicuro di avere un account attivo ma non ricordi la tua mail di accesso scrivici a <span className="blue_text">support@holifya.com</span>.
                    </div>
                    <div className="w-[328px] font-normal text-[18px] leading-[26px] text-center my-[20px]">
                        Il nostro team cercherà di aiutarti a recuperarla.
                    </div>
                    <div className="w-full h-[55px] my-[5px]">
                        <PrimaryButton
                            text="Scrivici"
                            pointer={true}
                            disabled={false}
                            onClick={onWriteToUsClick}
                        />
                    </div>
                    <div className="w-full h-[55px] mt-[5px] mb-[10px]">
                        <WhiteButtonBlackBorder
                            text="Vai alla Homepage"
                            onClick={onToHomepageClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotEmail
