import React from "react";
import ReactDOM from "react-dom/client";
import TagManager from 'react-gtm-module';
import { BrowserRouter } from "react-router-dom";
import "typeface-roboto";
import App from "./App";
import "./index.css";
import { AuthProvider } from "./utils/context/AuthContext";
import { BloodTestTrackerProvider } from "./utils/context/BloodTextTrackerContext";
import { ConsultsProvider } from "./utils/context/ConsultsContext";
import { DNATestTrackerProvider } from "./utils/context/DNATestTrackerContext";
import { ExpertsProvider } from "./utils/context/ExpertContext";
import { HealthPlanProvider } from "./utils/context/HealthPlanContext";
import { RegistrationProvider } from "./utils/context/RegistrationContext";
import { SurveyDataProvider } from "./utils/context/SurveyDataContext";
import { TutorialProvider } from "./utils/context/TutorialContext";
import { UserDataProvider } from "./utils/context/UserDataContext";
import { ProductsProvider } from "./utils/context/UserProductsContext";

const tagManagerArgs = {
    gtmId: 'GTM-P9DJPS6B'
}

TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ProductsProvider>
                <TutorialProvider>
                    <HealthPlanProvider>
                        <ConsultsProvider>
                            <SurveyDataProvider>
                                <RegistrationProvider>
                                    <BloodTestTrackerProvider>
                                        <DNATestTrackerProvider>
                                            <ExpertsProvider>
                                                <UserDataProvider>
                                                    <AuthProvider>
                                                        <App />
                                                    </AuthProvider>
                                                </UserDataProvider>
                                            </ExpertsProvider>
                                        </DNATestTrackerProvider>
                                    </BloodTestTrackerProvider>
                                </RegistrationProvider>
                            </SurveyDataProvider>
                        </ConsultsProvider>
                    </HealthPlanProvider>
                </TutorialProvider>
            </ProductsProvider>
        </BrowserRouter>
    </React.StrictMode>
);
