import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import WhiteButton from "../../../components/buttons/WhiteButton"
import { Answers } from "../../../utils/Answers"
import { Question } from "../../../utils/api-objects/Question"
import { createSurveyCompleted, getQuestionsByIds, updateMultipleAnswers } from "../../../utils/apis/emr-service"
import { ANSWERS_OPENING_INPUT } from "../../../utils/Constants"
import { useAuth } from "../../../utils/context/AuthContext"
import { useSurveyData } from "../../../utils/context/SurveyDataContext"
import { QuestionType, SurveyType } from "../../../utils/enums/Surveys"
import CloseButton from "../components/CloseButton"
import LoadingSpinner from "../components/LoadingSpinner"
import { MultiSelectCard, OpenParagraphCard, RadioCard, SingleValueCard } from "../components/SurveyCards"

const CheckupAssessmentSurvey = () => {

  const navigate = useNavigate()
  const { auth } = useAuth()
  const idToken = auth.idToken
  const userId = auth.userId
  const { surveyData, setHealthAssessmentCompleted } = useSurveyData()
  const ids = surveyData.checkupSurveyQuestions
  const questionsReady = ids !== null

  const [isFirstLoading, setIsFirstLoading] = useState(true)
  const [isLastLoading, setIsLastLoading] = useState(false)
  const [questions, setQuestions] = useState<Question[]>()
  const [shouldSaveResponses, setShouldSaveResponses] = useState(false);
  const [singleValueAnswer, setSingleValueAnswer] = useState("")
  const [openParagraphAnswer, setOpenParagraphAnswer] = useState("")
  const [selectedRadioAnswer, setSelectedRadioAnswer] = useState<string | null>(null);
  const [selectedAnswers, setSelectedAnswers] = useState<Set<string>>(new Set());
  const [customInputValue, setCustomInputValue] = useState<string>("");
  const [surveyResponses, setSurveyResponses] = useState<Map<string, Set<string>>>(new Map());
  const [hasAnswered, setHasAnswered] = useState(false);
  const [choiceBecomesInput, setChoiceBecomesInput] = useState(false)
  const [isOldAnswerCustom, setIsOldAnswerCustom] = useState(false)
  const [oldAnswerCustom, setOldAnswerCustom] = useState("")

  const onBackButtonClick = () => {
    navigate("/home")
  }

  const [index, setIndex] = useState(0)
  const currentQuestion = questions && questions[index]
  const maximumIndex = questions?.length! - 1

  const onSingleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSingleValueAnswer(newValue);

    // Update surveyResponses with the new value
    if (currentQuestion) {
      setSurveyResponses(prevResponses => new Map(prevResponses).set(currentQuestion.id, new Set([newValue])));
    }
  }

  const onOpenParagraphChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOpenParagraphAnswer(e.target.value)
  }

  const onMultiSelectCardClick = (answer: string) => {
    if (ANSWERS_OPENING_INPUT.includes(answer)) {
      setChoiceBecomesInput(true)
    } else {
      setChoiceBecomesInput(false)
    }
    setSelectedAnswers(prevState => {
      const newSelectedAnswers = new Set(prevState);
      if (newSelectedAnswers.has(answer)) {
        newSelectedAnswers.delete(answer);
      } else {
        newSelectedAnswers.add(answer);
      }
      const defaultAnswer = ANSWERS_OPENING_INPUT.find(answer => selectedAnswers.has(answer))
      if (defaultAnswer && customInputValue === "" && newSelectedAnswers.size === 2) {
        newSelectedAnswers.delete(defaultAnswer)
      }
      return newSelectedAnswers;
    });
  }

  const onRadioCardClick = (answer: string) => {
    setIsOldAnswerCustom(false)
    setOldAnswerCustom("")
    if (ANSWERS_OPENING_INPUT.includes(answer)) {
      setChoiceBecomesInput(true)
      setHasAnswered(true)
    } else {
      setChoiceBecomesInput(false)
    }

    setSelectedRadioAnswer(answer);

    if (currentQuestion) {
      setSurveyResponses(prevResponses => new Map(prevResponses).set(currentQuestion.id!, new Set([answer])));
    }

    // Check if the current question label is "Sesso biologico" and the selected answer is "Uomo"
    if (currentQuestion?.label === "Sesso biologico" && answer === "Uomo") {
      // Remove the question with the label "Condizione femminile" from the questions array
      setQuestions(prevQuestions =>
        prevQuestions?.filter(question => question.label !== "Condizione femminile")
      );
    }

    if (!hasAnswered && !ANSWERS_OPENING_INPUT.includes(answer)) {
      setTimeout(() => {
        saveAnswer(new Set([answer]))
        if (index < maximumIndex) {
          setIndex(index + 1);
          setSelectedRadioAnswer(null);
        }
      }, 500);
    }
  }

  const handleCustomInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomInputValue(e.target.value);
  };

  const saveAnswer = (answers: Set<string>) => {
    setSurveyResponses(prevResponses => new Map(prevResponses).set(currentQuestion?.id!, answers))
  }

  const onSubmitAnswerClick = () => {
    if (!ids || !currentQuestion) {
      return
    }
    if (currentQuestion.question_type === QuestionType.MultiSelect) {
      if (ANSWERS_OPENING_INPUT.some(answer => selectedAnswers.has(answer))) {
        ANSWERS_OPENING_INPUT.forEach(answer => {
          selectedAnswers.delete(answer)
        })
        selectedAnswers.add(customInputValue)
      }
      saveAnswer(selectedAnswers)
      setSelectedAnswers(new Set());
      setChoiceBecomesInput(false)
      setCustomInputValue("")
      setIsOldAnswerCustom(false)
      setOldAnswerCustom("")
    } else if (currentQuestion.question_type === QuestionType.Radio) {
      if (choiceBecomesInput) {
        saveAnswer(new Set([customInputValue]));
      } else {
        saveAnswer(new Set([selectedRadioAnswer!]));
      }
      setChoiceBecomesInput(false)
      setCustomInputValue("")
      setIsOldAnswerCustom(false)
      setOldAnswerCustom("")
    } else if (currentQuestion.question_type === QuestionType.SingleValueText || currentQuestion.question_type === QuestionType.SingleValueNumber) {
      saveAnswer(new Set([singleValueAnswer]));
      setSingleValueAnswer("");
    } else if (currentQuestion.question_type === QuestionType.OpenParagraph) {
      saveAnswer(new Set([openParagraphAnswer]));
      setOpenParagraphAnswer("");
    }
    if (index < maximumIndex) {
      setIndex(index + 1)
      // scrollRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      setShouldSaveResponses(true)
    }
  }

  const onBackClick = () => {
    if (index === 0) {
      navigate("/check-up-assessment-home-page")
    } else {
      setIndex(index - 1)
      // scrollRef.current?.scrollTo({ top: 0, behavior: "smooth" });
      setChoiceBecomesInput(false)
    }
  }

  const isConfirmButtonDisabled = (currentQuestion?: Question) => {
    if (!currentQuestion) return true;

    switch (currentQuestion.question_type) {
      case QuestionType.SingleValueText:
      case QuestionType.SingleValueNumber:
        return singleValueAnswer === "";
      case QuestionType.Radio:
        return selectedRadioAnswer === null || selectedRadioAnswer === "";
      case QuestionType.MultiSelect:
        if (selectedAnswers.size === 0) return true
        // return (selectedAnswers.size === 1 && ANSWERS_OPENING_INPUT.some(answer => selectedAnswers.has(answer)) && customInputValue === "");
        if (selectedAnswers.size === 1) {
          if (ANSWERS_OPENING_INPUT.some(answer => selectedAnswers.has(answer)) && customInputValue === "") {
            return true
          }
        }
        return false
      case QuestionType.OpenParagraph:
        return false;
      default:
        return true;
    }
  };

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

  useEffect(() => {
    if (currentQuestion) {
      const answers = surveyResponses.get(currentQuestion.id!) || new Set<string>();

      if (currentQuestion.question_type === QuestionType.Radio) {
        const previousAnswer = Array.from(answers)[0]
        setSelectedRadioAnswer(previousAnswer || null);
        if (previousAnswer !== undefined) {
          setHasAnswered(true)
          if (!currentQuestion.answers.includes(previousAnswer)) {
            setIsOldAnswerCustom(true)
            setOldAnswerCustom(previousAnswer)
          } else {
            setIsOldAnswerCustom(false)
            setOldAnswerCustom("")
          }
        } else {
          setHasAnswered(false)
        }
      } else if (currentQuestion.question_type === QuestionType.MultiSelect) {
        setSelectedAnswers(answers);
        // Check if any selected answer opens an input field (custom input)
        const customAnswer = Array.from(answers).find(answer => !currentQuestion.answers.includes(answer));
        if (customAnswer) {
          setIsOldAnswerCustom(true);
          setOldAnswerCustom(customAnswer);
        } else {
          setIsOldAnswerCustom(false);
          setOldAnswerCustom("");
        }
      } else if (currentQuestion.question_type === QuestionType.SingleValueText || currentQuestion.question_type === QuestionType.SingleValueNumber) {
        setSingleValueAnswer(Array.from(answers)[0] || "");
      } else if (currentQuestion.question_type === QuestionType.OpenParagraph) {
        setOpenParagraphAnswer(Array.from(answers)[0] || "");
      }
    }

    scrollRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentQuestion]);

  useEffect(() => {
    setIsSubmitButtonDisabled(isConfirmButtonDisabled(currentQuestion));
  }, [currentQuestion, singleValueAnswer, selectedRadioAnswer, selectedAnswers, openParagraphAnswer, customInputValue]);

  // Calculate the progress bar width percentage
  const progressPercentage = ids ? ((index + 1) / ids.length) * 100 : 0

  useEffect(() => {
    if (!questionsReady) {
      return
    }

    getQuestionsByIds(
      ids,
      (questions) => {
        const sortedQuestions = questions.sort((a, b) => {
          return parseInt(a.id) - parseInt(b.id)
        })
        setIsFirstLoading(false)
        setQuestions(sortedQuestions)
      },
      (error) => {
        console.log(error)
        setIsFirstLoading(false)
      }
    )
  }, [questionsReady])

  const saveSurveyResponses = () => {
    if (!userId || !idToken) {
      return
    }
    // Convert Map to array of Answers objects
    const responsesArray: Answers[] = Array.from(surveyResponses.entries()).map(([questionId, answers]) => ({
      question_id: questionId.toString(), // Convert to string if necessary
      value: Array.from(answers),
    }));

    setIsLastLoading(true)
    updateMultipleAnswers(
      userId,
      idToken,
      responsesArray,
      () => {
        setIsLastLoading(false)
        createSurveyCompleted(
          userId,
          idToken,
          SurveyType.CheckupSurvey,
          responsesArray,
          () => { navigate("/home") },
          (error) => { console.log(error) }
        )
      },
      (error) => {
        console.log(error)
        setIsLastLoading(false)
      }
    )
  }

  useEffect(() => {
    if (shouldSaveResponses) {
      saveSurveyResponses();
      setShouldSaveResponses(false);
    }
  }, [shouldSaveResponses])

  const scrollRef = useRef<HTMLDivElement | null>(null)

  // ********************************************************************************

  return (
    <div className="NewApp">
      {
        currentQuestion &&
        <div className={`w-full h-full flex flex-col ${(isFirstLoading || isLastLoading) && "opacity-50 pointer-events-none"}`}>
          {/* Header Section */}
          <div className={`w-full flex-none border-b border-solid border-black`}>
            <div className="w-[90%] mx-auto flex flex-col py-4 md:py-10 md:w-[80%]">
              <div className="w-full flex items-center justify-between mb-[20px]">
                <div className="flex items-center">
                  <img className="mr-[10px]" src="/images/new-platform/logos/holifya-logo-no-text.svg" alt="holifya-logo" />
                  <div className="font-bold text-[12px] leading-[17px]">Questionario | Check-up Assessment</div>
                </div>
                <CloseButton onClick={onBackButtonClick} />
              </div>

              {/* Progress bar */}
              <div className="w-full h-[4px] bg-holifya-light-blue mb-[20px]">
                <div className="w-full h-[4px] bg-holifya-blue" style={{ width: `${progressPercentage}%` }} />
              </div>

              {/* Question Title and Subtitle */}
              <div className="font-normal text-[20px] leading-[28px]">
                {currentQuestion.question_text}
              </div>
              {currentQuestion.question_subtitle && (
                <div className="font-bold text-[12px] leading-[17px] mt-[10px]">
                  {currentQuestion.question_subtitle}
                </div>
              )}
            </div>
          </div>
          {/* Middle Content - Scrollable */}
          <div className={`flex-grow overflow-y-auto scrollbar-hide`} ref={scrollRef}>
            <div className="w-[90%] mx-auto py-5 flex flex-col md:flex-row md:justify-center md:flex-wrap">
              {/* Single Value */}
              {(currentQuestion.question_type === QuestionType.SingleValueText ||
                currentQuestion.question_type === QuestionType.SingleValueNumber) &&
                <SingleValueCard
                  question={currentQuestion}
                  value={
                    singleValueAnswer || Array.from(surveyResponses.get(currentQuestion?.id!) || []).join('')
                  }
                  onChange={onSingleValueChange}
                />
              }

              {/* Radio */}
              {
                currentQuestion.question_type === QuestionType.Radio &&
                currentQuestion.answers.map((answer, index) => (
                  <RadioCard
                    key={index}
                    answer={(ANSWERS_OPENING_INPUT.includes(answer) && oldAnswerCustom) ? oldAnswerCustom : answer}
                    onClick={onRadioCardClick}
                    isChecked={selectedRadioAnswer === answer ||
                      (currentQuestion?.question_type === QuestionType.Radio && Array.from(surveyResponses.get(currentQuestion?.id!) || []).includes(answer)) ||
                      (ANSWERS_OPENING_INPUT.includes(answer) && isOldAnswerCustom)
                    }
                    isAnInput={choiceBecomesInput}
                    onInputChange={handleCustomInputChange}
                    inputValue={customInputValue}
                  />
                ))
              }

              {/* MultiSelect */}
              {
                currentQuestion.question_type === QuestionType.MultiSelect &&
                currentQuestion.answers.map((answer, index) => (
                  <MultiSelectCard
                    key={index}
                    answer={(ANSWERS_OPENING_INPUT.includes(answer) && oldAnswerCustom && Array.from(selectedAnswers).some(answer => !currentQuestion.answers.includes(answer))) ? oldAnswerCustom : answer}
                    onClick={onMultiSelectCardClick}
                    isChecked={
                      selectedAnswers.has(answer)
                      // || (currentQuestion?.question_type === QuestionType.MultiSelect && Array.from(surveyResponses.get(currentQuestion?.id!) || []).includes(answer))
                      ||
                      (ANSWERS_OPENING_INPUT.includes(answer) && isOldAnswerCustom && Array.from(selectedAnswers).some(answer => !currentQuestion.answers.includes(answer)))
                    }
                    isAnInput={choiceBecomesInput}
                    onInputChange={handleCustomInputChange}
                    inputValue={customInputValue}
                  />
                ))
              }
              {/* OpenParagraph */}
              {
                currentQuestion.question_type === QuestionType.OpenParagraph &&
                <OpenParagraphCard question={currentQuestion} value={openParagraphAnswer} onChange={onOpenParagraphChange} />
              }
              <div className="p-[10px] md:p-0" />
            </div>
          </div>

          {/* Footer Section */}
          <div className={`flex-none w-full h-[120px] flex items-center justify-center`}>
            <div className="w-[90%] h-[100px] flex flex-col justify-between md:flex-row md:h-[50px] md:w-[750px]">
              <div className="w-full h-[50px] md:w-[362px]">
                <PrimaryButton
                  text={index === maximumIndex ? "Concludi" : "Avanti"}
                  pointer={!isConfirmButtonDisabled}
                  disabled={isSubmitButtonDisabled}
                  fontSize={16}
                  onClick={onSubmitAnswerClick}
                />
              </div>
              <div className="w-full h-[40px] md:w-[362px]">
                <WhiteButton
                  text="Indietro"
                  onClick={onBackClick}
                  uppercase={false}
                />
              </div>
            </div>
          </div>
        </div>
      }
      {/* Loading Spinner */}
      {
        (isFirstLoading || isLastLoading) &&
        <div className="w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center">
          <LoadingSpinner />
          {isLastLoading && <div className="w-[90%] mt-[20px] font-bold text-[20px] leading-[30px] text-center">Attendi un momento, stiamo salvando le tue risposte!</div>}
        </div>
      }
    </div>
  )
}

export default CheckupAssessmentSurvey
