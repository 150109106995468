import CloseButton from "../../../../components/CloseButton"

interface Props {
    onClick: () => void,
}

const ChangePasswordDialog: React.FC<Props> = ({
    onClick,
}) => {
    return (
        <div className="absolute w-[90%] sm:w-[500px] rounded-[20px] border border-solid border-holifya-grey bg-white shadow-platform px-[20px] py-[10px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className="w-full">
                <div className="flex justify-end mb-[10px]">
                    <CloseButton onClick={onClick}/>
                </div>
                <div className="font-normal text-[16px] leading-[22px] mb-[10px]">
                    Abbiamo aggiornato la piattaforma ed è necessario un cambio password.<br></br>
                    Clicca su “Non riesci ad accedere?”, inserisci la tua mail associata al tuo account e reimposta la password per accedere.
                </div>
            </div>
        </div>
    )
}

export default ChangePasswordDialog
