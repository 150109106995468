import { useNavigate } from "react-router-dom"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import WhiteButton from "../../../components/buttons/WhiteButton"
import { NEW_PLATFORM_HOME_LOGO_BLACK } from "../../../utils/Constants"
import CloseButton from "../components/CloseButton"

const CheckupAssessmentHomePage = () => {

    const navigate = useNavigate()

    // ********************************************************************************

    return (
        <div className="NewApp">
            <div className="w-full h-[100px] flex items-center justify-center border-b border-solid border-black md:h-[200px]">
                <div className="w-[90%] h-[90%] flex items-center justify-between md:w-[80%]">
                    <div className="flex items-center">
                        <img className="mr-[10px]" src="/images/new-platform/logos/holifya-logo-no-text.svg" alt="holifya-logo" />
                        <div className="font-bold text-[12px] leading-[17px]">Questionario | Check-up Assessment</div>
                    </div>
                    <CloseButton onClick={() => navigate("/home")} />
                </div>
            </div>
            <div className="w-full h-[calc(100%-220px)] overflow-y-auto scrollbar-hide md:w-[80%] md:mt-[30px] md:h-fit">
                <div className="w-full h-full p-[20px] font-normal text-[16px] leading-[26px]">
                    È passato un po' di tempo dall'ultimo questionario a cui hai risposto?
                    <br />
                    <br />
                    Prenditi pochi minuti per aggiornare le tue risposte e allineare il tuo profilo sullo stato di raggiungimento dei tuoi obiettivi e progressi.
                    <br />
                    <br />
                    Potrai rispondere a questo questionario tutte le volte che vorrai accedendo dal tuo Pannello di controllo in <strong>Home</strong> ( <span><img className="align-middle inline-block m-0 p-0 h-auto w-auto" src={NEW_PLATFORM_HOME_LOGO_BLACK} /></span> ).
                </div>
            </div>
            <div className="w-full h-[120px] flex items-center justify-center md:w-[750px] md:mt-[30px]">
                <div className="w-[90%] h-[100px] flex flex-col justify-between md:flex-row md:w-full md:items-center">
                    <div className="w-full h-[50px] md:w-[362px]">
                        <PrimaryButton
                            text={"Inizia"}
                            pointer={true}
                            disabled={false}
                            fontSize={16}
                            onClick={() => navigate("/check-up-assessment-survey")}
                        />
                    </div>
                    <div className="w-full h-[40px] md:w-[362px]">
                        <WhiteButton
                            text="Chiudi"
                            onClick={() => navigate("/home")}
                            fontSize={16}
                            uppercase={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckupAssessmentHomePage
