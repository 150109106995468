import { useEffect, useState } from "react";
import { PasswordInputIcon } from "../../../utils/enums/PasswordInputIcon";
import { PasswordInputType } from "../../../utils/enums/PasswordInputType";

interface Props {
    name?: string,
    label: string,
    errorText: string,
    inputValue: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    placeholder: string,
    type: PasswordInputType,
    image: PasswordInputIcon,
    toggleTypeEnabled: boolean,
    disabled?: boolean,
    marginBottom?: boolean,
    fontSize?: number,
}

const PasswordInputElement: React.FC<Props> = ({
    name,
    label,
    errorText,
    inputValue,
    onChange,
    placeholder,
    type,
    image,
    toggleTypeEnabled,
    disabled = false,
    marginBottom = false,
    fontSize = 16,
}) => {
    const [inputType, setInputType] = useState<PasswordInputType>(type);
    const [icon, setIcon] = useState<PasswordInputIcon>(image)

    const handleTogglePasswordType = () => {
        setInputType((prevType) =>
            prevType === PasswordInputType.Password ? PasswordInputType.Text : PasswordInputType.Password
        );
        setIcon((prevIcon) => {
            switch (prevIcon) {
                case PasswordInputIcon.ShowDisabled:
                    return PasswordInputIcon.HideDisabled;
                case PasswordInputIcon.HideDisabled:
                    return PasswordInputIcon.ShowDisabled;
                case PasswordInputIcon.ShowEnabled:
                    return PasswordInputIcon.HideEnabled;
                case PasswordInputIcon.HideEnabled:
                    return PasswordInputIcon.ShowEnabled;
            }
        })
    };

    useEffect(() => {
        if (toggleTypeEnabled) {
            if (icon === PasswordInputIcon.ShowDisabled) {
                setIcon(PasswordInputIcon.ShowEnabled)
            } else {
                setIcon(PasswordInputIcon.HideEnabled)
            }
        } else {
            if (icon === PasswordInputIcon.ShowEnabled) {
                setIcon(PasswordInputIcon.ShowDisabled)
            } else if (icon === PasswordInputIcon.HideEnabled) {
                setIcon(PasswordInputIcon.HideDisabled)
            }
        }
    }, [toggleTypeEnabled])

    return (
        <div className={`w-full flex flex-col justify-between ${marginBottom && "mb-[5px]"}`}>
            <label
                className="font-normal text-[14px] leading-[20px]"
                htmlFor={`password-input-${label}`}
            >
                {label}
            </label>
            <div className="w-full h-[40px] flex items center rounded-[5px] border border-solid border-holifya-grey-border">
                <input
                    name={name}
                    id={`password-input-${label}`}
                    className={`border-none rounded-[5px] outline-none w-[calc(100%-30px)] h-[38px] leading-[5em] text-[16px] font-normal pl-[15px] tracking-[1px] placeholder:font-normal placeholder:text-[14px] placeholder:leading-[20px] text-[${fontSize}px]`}
                    placeholder={placeholder}
                    value={inputValue}
                    type={inputType}
                    onChange={onChange}
                    disabled={disabled}
                />
                <img
                    className={`w-[25px] mr-[5px] ${toggleTypeEnabled && "pointer"}`}
                    onClick={toggleTypeEnabled ? handleTogglePasswordType : undefined}
                    src={icon}
                    alt="Toggle visibility"
                />
            </div>
            <div className={`font-bold text-[12px] leading-[17px] text-holifya-error ${errorText === "" && "text-transparent"}`}>
                {
                    errorText !== "" ? errorText : "placeholder"
                }
            </div>
        </div>
    )
}

export default PasswordInputElement
