import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HOLIFYA_LOGO } from "../../../../utils/Constants";
import { QuestionCategory } from "../../../../utils/enums/Surveys";
import { fromQuestionCategoryToString } from "../../../../utils/Functions";
import BackButton from "../../components/BackButton";
import NavBar from "../../components/NavBar";
import { NavBarElement } from "../../components/NavBarElement";

const HealthAssessment = () => {
    const navigate = useNavigate()
    const [currentItem, setCurrentItem] = useState(NavBarElement.Diagnostica)
    const onMenuClick = () => {
        setCurrentItem(prev =>
            prev === NavBarElement.Diagnostica ? NavBarElement.Menu : NavBarElement.Diagnostica
        );
    };

    // ********************************************************************************

    return (
        <div className="NewApp">
            <div className="w-full h-full md:flex md:flex-row-reverse">
                <div className={`w-full h-[calc(100%-90px)] flex flex-col items-center justify-end overflow-y-auto scrollbar-hide md:w-[calc(100%-100px)] md:h-full`}>
                    <div className={`w-[90%] h-[95%] flex flex-col`}>
                        <div className="hidden md:block h-[35px] my-[10px]">
                            <img src={HOLIFYA_LOGO} alt="Holifya Logo" />
                        </div>
                        <div className="my-[10px]">
                            <BackButton
                                text="Torna a Test e referti"
                                onClick={() => navigate("/diagnostica/tests-and-reports")}
                            />
                        </div>
                        <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
                            Health Assessment
                        </div>
                        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                            <HAFolder
                                category={QuestionCategory.PersonalGoals}
                                onClick={() => navigate(`category/${QuestionCategory.PersonalGoals}`)}
                            />
                            <HAFolder
                                category={QuestionCategory.FamilyHistoryDiseases}
                                onClick={() => navigate(`category/${QuestionCategory.FamilyHistoryDiseases}`)}
                            />
                            <HAFolder
                                category={QuestionCategory.LifestyleSports}
                                onClick={() => navigate(`category/${QuestionCategory.LifestyleSports}`)}
                            />
                            <HAFolder
                                category={QuestionCategory.DietaryHabits}
                                onClick={() => navigate(`category/${QuestionCategory.DietaryHabits}`)}
                            />
                            <HAFolder
                                category={QuestionCategory.Notes}
                                onClick={() => navigate(`category/${QuestionCategory.Notes}`)}
                            />
                        </div>
                        <div className="p-[10px]" />
                    </div>
                    <NavBar
                        currentItem={currentItem}
                        onMenuClick={onMenuClick}
                    />
                </div>
            </div>
        </div>
    )
}

export default HealthAssessment

interface HAFolderProps {
    category: QuestionCategory,
    onClick: () => void,
}

const HAFolder: React.FC<HAFolderProps> = ({
    category,
    onClick,
}) => {
    return (
        <div className="w-[calc(100%-4px)] h-[111px] rounded-[5px] flex items-center justify-center self-center flex-shrink-0 shadow-platform cursor-pointer" onClick={onClick}>
            <div className="w-[90%]">
                <div className="font-bold text-[20px] leading-[33px]">{fromQuestionCategoryToString(category)}</div>
            </div>
        </div>
    )
}
