import { BASE_URL } from "../Constants";
import { ErrorCallback, ErrorWithMessageCallback, GenericSuccessCallback, LoginErrorCallback, LoginSuccessCallback, RegisterErrorCallback, SuccessCallback, SuccessWithMessageCallback } from "./callback-helpers";

/**
 * Logs in a user with the provided email and password.
 * 
 * @param email - The user's email address.
 * @param password - The user's password.
 * @param onSuccess - Callback function to be called if the login is successful.
 * @param onError - Callback function to be called if an error occurs during login.
 * 
 * @returns A promise that resolves to an object containing the success status and an error message if an error occurs.
 * 
 * @throws An error if the fetch operation fails.
 */
export async function loginApi(
    email: string,
    password: string,
    onSuccess: LoginSuccessCallback,
    onError: LoginErrorCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/auth/login`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    email: email,
                    password: password,
                }
            ),
        })
        if (!response.ok) {
            const responseJson = await response.json()
            if (responseJson.detail === "Password reset required") {
                onError("Cambio password necessario", true)
            } else {
                onError("Indirizzo e-mail o password errata")
            }
        } else {
            const responseJson = await response.json()
            onSuccess({
                idToken: responseJson.id_token,
                accessToken: responseJson.access_token,
                userId: responseJson.id,
                refreshToken: responseJson.refresh_token,
                expiryTime: responseJson.expiry_in,
            });
        }
    } catch (error) {
        console.error("Login failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

/**
 * Registers a new user with the provided details.
 * 
 * @param email - The user's email address.
 * @param password - The user's password.
 * @param username - The user's chosen username.
 * @param familyName - The user's family name.
 * @param phoneNumber - The user's phone number, which will be prefixed with country code if not already present.
 * @param newsletterAccepted - Indicates whether the user has accepted the newsletter policy.
 * @param onSuccess - Callback function to be called if the registration is successful.
 * @param onError - Callback function to be called if an error occurs during registration.
 * 
 * @returns A promise that resolves to an object containing the success status and an error message if an error occurs.
 * 
 * @throws An error if the fetch operation fails.
 */
export async function registerApi(
    email: string,
    password: string,
    username: string,
    familyName: string,
    phoneNumber: string,
    newsletterAccepted: boolean,
    onSuccess: SuccessCallback,
    onError: RegisterErrorCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/auth/register`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    email: email,
                    password: password,
                    name: username,
                    family_name: familyName,
                    mobile_phone: phoneNumber[0] === "+" ? phoneNumber : `+39${phoneNumber}`,
                    newsletter_policy: newsletterAccepted,
                }
            ),
        })
        if (!response.ok) {
            const responseJson = await response.json()
            if (response.status === 422) {
                onError("Errore di compilazione", true)
            } else if (responseJson.detail === "An account with the given email already exists.") {
                onError("L’indirizzo email risulta già associato a un account");
            } else {
                onError("Errore, riprova");
            }
        } else {
            onSuccess()
        }
    } catch (error) {
        console.error("Registration failed:", error)
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function confirmCodeApi(
    email: string,
    confirmationCode: string,
    onSuccess: SuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/auth/confirm`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    email: email,
                    code: confirmationCode,
                }
            ),
        })
        if (!response.ok) {
            onError("Codice non valido")
        } else {
            onSuccess()
        }
    } catch (error) {
        console.error("Code confirmation failed:", error)
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function forgotPasswordApi(
    email: string,
    onSuccess: SuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/auth/forgot-password`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    email: email,
                }
            ),
        })
        if (!response.ok) {
            onError("Indirizzo e-mail non trovato")
        } else {
            onSuccess()
        }
    } catch (error) {
        console.error("Forgot password error:", error)
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function confirmForgotPasswordApi(
    email: string,
    password: string,
    code: string,
    onSuccess: SuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/auth/confirm-forgot-password`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    email: email,
                    code: code,
                    password: password,
                }
            ),
        })
        if (!response.ok) {
            onError("Qualcosa è andato storto, torna alla pagina precedente e riprova")
        } else {
            onSuccess()
        }
    } catch (error) {
        console.error("Confirm forgot password error:", error)
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function resendCodeApi(
    email: string,
    onSuccess: SuccessCallback,
    onError: ErrorCallback,
) {
    try {
        const respose = await fetch(`${BASE_URL}/auth/resend-code`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    email: email,
                }
            ),
        })
        if (!respose.ok) {
            onError()
        } else {
            onSuccess()
        }
    } catch (error) {
        console.error("Resend code error:", error)
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function logoutAPI(
    email: string,
    onSuccess: SuccessWithMessageCallback,
    onError: ErrorWithMessageCallback,
) {
    const response = await fetch(`${BASE_URL}/auth/logout`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                email: email,
            }
        ),
    })
    if (response.status === 302) {
        const result = await response.json();
        onSuccess(result)
    } else {
        const result = await response.json();
        onError(result)
    }
}

export async function changePassword(
    accessToken: string,
    currentPassword: string,
    newPassword: string,
    onSuccess: GenericSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/auth/change-password`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }, body: JSON.stringify(
                {
                    access_token: accessToken,
                    old_password: currentPassword,
                    new_password: newPassword
                }
            )
        })
        if (response.ok) {
            const success= await response.json()
            onSuccess(success)
        } else {
            onError("errore")
        }
    } catch (error) {
        console.error("Change password error:", error)
        return { success: false, error: "An unexpected error occurred" };
    }
}
