export enum FocusType {
    None = "none",
    Classic = "classic",
    Highlight = "highlight",
}

export enum ControlPanelElement {
    HealthAssessment = "health-assessment",
    DNATestComplete = "dna-test-complete",
    BloodExams = "blood-exams",
    ExpertConsult = "expert-consult",
    NutritionistConsult = "nutritionist-consult",
}