import { useState } from "react"
import { useNavigate } from "react-router-dom"
import BackButton from "../../../components/BackButton"
import { forgotPasswordApi } from "../../../utils/apis/auth-service"
import LoadingSpinner from "../../new-platform/components/LoadingSpinner"
import ForgotPasswordBox from "./components/ForgotPasswordBox"

const ForgotPassword = () => {

    const navigate = useNavigate();
    
    const [emailErrorText, setEmailErrorText] = useState("")
    const [isLoading, setIsLoading] = useState(false)    

    const onBackButtonClick = () => {
        navigate("/login")
    }

    const handleInputSubmit = (inputData: { [key: string]: string }) => {
        const email = inputData.email
        callForgotPassword(email)
    }

    const callForgotPassword = (email: string) => {
        setEmailErrorText("")
        setIsLoading(true)
        forgotPasswordApi(
            email,
            () => {
                setIsLoading(false)
                navigate("/forgot-password-insert-code", { state: { email } })
            },
            (error) => {
                setIsLoading(false)
                setEmailErrorText(error)
            }
        )
    }

    // ********************************************************************************

    return (
        <div className="App relative">
            <div className={`w-[90%] h-[80px] flex justify-between items-center ${isLoading && "opacity-30 pointer-events-none"}`}>
                <BackButton onClick={onBackButtonClick} />
            </div>
            <div className={`w-[392px] h-[calc(100%-120px)] flex flex-col items-center justify-start sm:justify-center ${isLoading && "opacity-30 pointer-events-none"}`}>
                <div className="w-[328px] flex bg-white p-[20px]">
                    <ForgotPasswordBox
                        onSubmit={handleInputSubmit}
                        emailErrorText={emailErrorText}
                    />
                </div>
            </div>
            {
                isLoading && <div className="w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center"><LoadingSpinner /></div>
            }
        </div>
    )
}

export default ForgotPassword
