import { useState } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "../../../../components/buttons/PrimaryButton"
import WhiteButton from "../../../../components/buttons/WhiteButton"
import TextInputElement from "../../../../components/input/v2/TextInputElement"
import { updateBloodTrackerByUserId } from "../../../../utils/apis/dna-test-tracker"
import { assignBloodcode } from "../../../../utils/apis/swabcode-service"
import { useAuth } from "../../../../utils/context/AuthContext"
import { useBloodTestTracker } from "../../../../utils/context/BloodTextTrackerContext"
import { BloodTestTrackingStatus } from "../../../../utils/enums/TrackingStatus"
import CloseButton from "../../components/CloseButton"
import NavBar from "../../components/NavBar"
import { NavBarElement } from "../../components/NavBarElement"

const RegisterSample = () => {
    const { auth } = useAuth()
    const userId = auth.userId
    const idToken = auth.idToken
    const navigate = useNavigate()
    const { updateBloodTestTracker } = useBloodTestTracker()
    const [currentItem, setCurrentItem] = useState(NavBarElement.Diagnostica)
    const onMenuClick = () => {
        setCurrentItem((prev) => prev === NavBarElement.Diagnostica ? NavBarElement.Menu : NavBarElement.Diagnostica);
    };

    const [bloodCode, setBloodCode] = useState("")
    const [errorText, setErrorText] = useState("")

    const [isLoading, setIsLoading] = useState(false)

    const isSubmitButtonDisabled = bloodCode.length < 9

    const onSubmitButtonClick = () => {
        if (!userId || !idToken) {
            return
        }
        
        setIsLoading(true)
        assignBloodcode(
            bloodCode,
            userId,
            () => {
                updateTracker()
            },
            (error) => {
                console.log(error)
                setErrorText("Codice non valido")
                setIsLoading(false)
            },
        )
    }

    const updateTracker = () => {
        if (!userId || !idToken) {
            return
        }

        updateBloodTrackerByUserId(
            userId,
            idToken,
            BloodTestTrackingStatus.SampleRegistered,
            (tracker) => {
                setIsLoading(false)
                const newStatus = tracker.tracking_status
                updateBloodTestTracker(newStatus)
                navigate("/diagnostica/tests-and-reports/blood-test")
            },
            (error) => {
                console.log(error)
                setIsLoading(false)
            },
        )
    }

    const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBloodCode(e.target.value)
        setErrorText("")
    }

    const [showCodeExample, setShowCodeExample] = useState(false)

    // ********************************************************************************

    return (
        <div className="NewApp">
            <div className={`w-full h-full ${showCodeExample && "opacity-50 pointer-events-none"} md:flex md:flex-row-reverse`}>
                <div className="w-full h-[calc(100%-90px)] flex flex-col items-center justify-end overflow-y-auto scrollbar-hide md:w-[calc(100%-100px)] md:h-full">
                    <div className="w-[90%] h-[95%] flex flex-col">
                        <div className="flex justify-end my-[10px]">
                            <CloseButton
                                onClick={() => navigate("/diagnostica/tests-and-reports/blood-test")}
                            />
                        </div>
                        <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
                            Registra campione
                        </div>
                        <div className="font-normal text-[16px] leading-[26px]">
                            <span>Sulla Card per la raccolta del sangue è presente un Codice Analisi.</span>
                            <span className="">
                                <img
                                    className="ml-[5px] cursor-pointer inline-block mb-[3px]"
                                    src="/images/new-platform/components/info-logo.svg"
                                    alt="Info"
                                    onClick={() => setShowCodeExample(true)}
                                />
                            </span>
                        </div>
                        <div>
                            È importante perché definisce la tua analisi e permette di anonimizzare il tuo campione ematico
                        </div>
                        <div className="font-normal text-[16px] leading-[26px] mt-[30px]">
                            <strong>Inserisci il Codice Analisi</strong> qui sotto per registrare correttamente il tuo campione ematico.
                        </div>
                        <div className="mt-[30px]">
                            <TextInputElement
                                label="Codice Analisi (sulla carta di raccolta)"
                                errorText={errorText}
                                inputValue={bloodCode}
                                onChange={inputChangeHandler}
                                placeholder={"Codice campione"}
                            />
                        </div>
                        <div className="w-full h-[120px] flex flex-col justify-between mt-[20px]">
                            <div className="w-full h-[50px]">
                                <PrimaryButton
                                    text="Registra"
                                    pointer={!isSubmitButtonDisabled}
                                    disabled={isSubmitButtonDisabled}
                                    onClick={onSubmitButtonClick}
                                    isLoading={isLoading}
                                />
                            </div>
                            <div className="w-full h-[50px]">
                                <WhiteButton
                                    text="Indietro"
                                    onClick={() => navigate("/diagnostica/tests-and-reports/blood-test/tutorial")}
                                    uppercase={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <NavBar
                    currentItem={currentItem}
                    onMenuClick={onMenuClick}
                />
            </div>
            {showCodeExample && <AllComponentsDialog onCloseClick={() => setShowCodeExample(false)} />}
        </div>
    )
}

export default RegisterSample

interface AllComponentsProps {
    onCloseClick: () => void,
}

const AllComponentsDialog: React.FC<AllComponentsProps> = ({
    onCloseClick,
}) => {
    return (
        <div className="w-[90%] rounded-[15px] bg-white shadow-platform absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className="w-full p-[20px]">
                <div className="flex items-center justify-between mb-[20px]">
                    <div className="font-bold text-[20px] leading-[25px]">
                        Codice Analisi
                    </div>
                    <CloseButton
                        onClick={onCloseClick}
                    />
                </div>
                <img src="/images/new-platform/components/blood-test-codice-analisi.jpg" />
            </div>
        </div>
    )
}
