import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { getProducts, getRedeemableSortingItems } from "../../../utils/apis/order-service"
import { HOLIFYA_LOGO } from "../../../utils/Constants"
import { useAuth } from "../../../utils/context/AuthContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { useProducts } from "../../../utils/context/UserProductsContext"
import { ProductType } from "../../../utils/enums/ProductType"
import LoadingSpinner from "../../new-platform/components/LoadingSpinner"

const CheckPurchases = () => {
    const navigate = useNavigate()
    const { auth } = useAuth()
    const idToken = auth.idToken
    const userId = auth.userId
    const { userData } = useUserData()
    const email = userData.email
    const { updateDnaTest } = useProducts()

    const callGetProducts = async () => {
        if (!idToken || !userId) return;

        try {
            const products = await getProducts(userId, idToken);
            products.forEach((product) => {
                if (product.product_type === ProductType.DnaTest) {
                    updateDnaTest(product.data.channel)
                }
            })
            navigate("/fetch-data");
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : "Nessun prodotto salvato";
            console.log(errorMessage);

            if (email) {
                try {
                    const items = await getRedeemableSortingItems(email, idToken);
                    if (items.length > 0) {
                        navigate("/sorting");
                    } else {
                        navigate("/redeem");
                    }
                } catch (error) {
                    const errorMessage = error instanceof Error ? error.message : "An unexpected error occurred";
                    console.error("Get sorting redeemable items failed:", errorMessage);
                    navigate("/redeem");
                }
            }
        }
    };

    useEffect(() => {
        callGetProducts();
    }, []);

    // ********************************************************************************

    return (
        <div className="NewApp">
            <div className="w-full h-full flex items-center justify-center">
                <div className="flex flex-col items-center px-[20px]">
                    <div className="h-[35px] mb-[40px]">
                        <img src={HOLIFYA_LOGO} alt="Holifya Logo" />
                    </div>
                    <div className="flex flex-col items-center px-[20px]">
                        <div className="font-bold text-[25px] leading-[33px] mb-[20px]">
                            ⏳ Attendi...
                        </div>
                        <div className="font-normal text-[16px] leading-[24px] text-center mb-[50px]">
                            Stiamo cercando acquisti associati a questo indirizzo e-mail.
                        </div>
                        <div><LoadingSpinner /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckPurchases
