interface Props {
    text?: string,
    onClick: () => void,
}

const DownloadButton: React.FC<Props> = ({
    text,
    onClick,
}) => {
  return (
    <div className="flex items-center cursor-pointer" onClick={onClick}>
      <div className="font-semibold text-[12px] leading-[17px] text-holifya-blue mr-[10px]">{text ? text : "Scarica"}</div>
      <div className="leading-none"><img src="/images/new-platform/components/download-arrow-blue.svg"/></div>
    </div>
  )
}

export default DownloadButton
