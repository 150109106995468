import { useEffect, useRef } from "react";
import { Question } from "../../../utils/api-objects/Question";
import { ANSWERS_OPENING_INPUT } from "../../../utils/Constants";
import { QuestionType } from "../../../utils/enums/Surveys";

interface SingleValueProps {
    question: Question,
    value: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

export const SingleValueCard: React.FC<SingleValueProps> = ({
    question,
    value,
    onChange,
}) => {

    // Helper function to format the input with slashes (only for date input)
    const formatDateInput = (input: string) => {
        let cleanedInput = input.replace(/\D/g, ""); // Remove non-digit characters

        if (cleanedInput.length <= 2) {
            return cleanedInput; // Day (DD)
        } else if (cleanedInput.length <= 4) {
            return `${cleanedInput.slice(0, 2)}/${cleanedInput.slice(2)}`; // Day/Month (DD/MM)
        } else {
            return `${cleanedInput.slice(0, 2)}/${cleanedInput.slice(2, 4)}/${cleanedInput.slice(4, 8)}`; // Day/Month/Year (DD/MM/YYYY)
        }
    };

    // Handle input change based on the question label
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        let formattedValue = inputValue;

        // Apply date formatting only if the question label is "Data di nascita"
        if (question.label === "Data di nascita") {
            formattedValue = formatDateInput(inputValue);
        }

        // Pass the formatted or raw value back to the parent component
        onChange({
            ...e,
            target: {
                ...e.target,
                value: formattedValue,
            },
        });
    };

    // Prevent the user from deleting slashes in date input
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const { key, target } = e;
        const { selectionStart } = target as HTMLInputElement;

        // Only apply this logic if it's a date input (when the label is "Data di nascita")
        if (question.label === "Data di nascita") {
            // Prevent deletion of slashes at specific positions
            if ((key === "Backspace" || key === "Delete") && (selectionStart === 3 || selectionStart === 6)) {
                e.preventDefault();
            }
        }
    };
    const inputRef = useRef<HTMLInputElement | null>(null);

    // Focus input on mount
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [question]);
    
    return (
        <div className="w-full h-[80px] rounded-[5px] border border-solid border-black flex items-center justify-center md:w-[860px]">
            <input
                ref={inputRef}
                type={question.question_type === QuestionType.SingleValueText ? "text" : "number"}
                className={`w-[90%] h-[60%] outline-none border-none font-normal text-[16px] leading-[20px] hide-spin`}
                value={value}
                onKeyDown={handleKeyDown}
                onChange={handleInputChange}
                placeholder={question.placeholder !== null ? question.placeholder : undefined}
                maxLength={question.label === "Data di nascita" ? 10 : undefined}
            />
        </div>
    )
}

interface OpenParagraphProps {
    question: Question,
    value: string,
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
}

export const OpenParagraphCard: React.FC<OpenParagraphProps> = ({
    question,
    value,
    onChange,
}) => {
    return (
        <div className="w-full h-[198px] rounded-[5px] border border-solid border-black flex items-center justify-center md:w-[860px]">
            <textarea
                className="w-full h-full p-[20px] border-none font-normal text-[16px] leading-[20px]"
                value={value}
                onChange={(e) => onChange(e)}
                placeholder={question.placeholder !== null ? question.placeholder : undefined}
                autoFocus
            />
        </div>
    )
}

interface RadioProps {
    answer: string,
    onClick: (answer: string) => void,
    isChecked: boolean,
    isAnInput: boolean,
    inputValue?: string,
    onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RadioCard: React.FC<RadioProps> = ({
    answer,
    onClick,
    isChecked,
    isAnInput,
    inputValue,
    onInputChange,
}) => {
    return (
        <div
            className={`cursor-pointer w-full h-[80px] rounded-[5px] flex items-center justify-center mb-[20px] flex-shrink-0 border border-solid ${isChecked ? "border-black shadow-none" : "shadow-platform border-transparent"} md:w-[362px] md:mx-[15px]`}
            onClick={() => onClick(answer)}
        >
            <div className="w-[90%] flex items-center">
                <div className="w-[20px] h-[20px] rounded-[10px] border border-solid border-black flex items-center justify-center mr-[10px] flex-shrink-0">
                    {isChecked && <div className="w-[14px] h-[14px] rounded-[7px] bg-black flex-shrink-0" />}
                </div>
                {
                    isAnInput && isChecked ?
                        <input
                            className="w-[calc(100%-35px)] outline-none"
                            value={inputValue}
                            onChange={onInputChange}
                            placeholder="Specifica"
                            autoFocus
                        /> :
                        <div className="font-normal text-[16px] leading-[20px]">{answer}</div>
                }
            </div>
        </div>
    )
}

interface MultiSelectProps {
    answer: string,
    onClick: (answer: string) => void,
    isChecked: boolean,
    isAnInput: boolean,
    inputValue?: string,
    onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const MultiSelectCard: React.FC<MultiSelectProps> = ({
    answer,
    onClick,
    isChecked,
    isAnInput,
    inputValue,
    onInputChange,
}) => {
    return (
        <div
            className={`cursor-pointer w-full h-[80px] rounded-[5px] flex items-center justify-center mb-[20px] border border-solid shadow-platform flex-shrink-0 ${isChecked ? "shadow-none border-black" : "border-transparent"} md:w-[362px] md:mx-[15px]`}
            onClick={() => onClick(answer)}
        >
            <div className="w-[90%] flex items-center">
                <div className="w-[20px] h-[20px] rounded-[5px] border border-solid border-black flex items-center justify-center mr-[10px] flex-shrink-0">
                    {isChecked && <div className="w-[14px] h-[14px] rounded-[2px] bg-black flex-shrink-0" />}
                </div>
                {
                    isChecked && ANSWERS_OPENING_INPUT.includes(answer) && (isAnInput || inputValue !== "") ?
                        <input
                            className="w-[calc(100%-35px)] outline-none"
                            value={inputValue}
                            onChange={onInputChange}
                            placeholder="Specifica"
                            autoFocus
                        /> :
                        <div className="font-normal text-[16px] leading-[20px]">{answer}</div>
                }
            </div>
        </div>
    )
}