import { useNavigate } from "react-router-dom"
import BackButton from "../BackButton"

const DataProtectionScreen = () => {

  const navigate = useNavigate();
  return (
    <div className="App">
      <span>TO IMPLEMENT! Data protection screen</span>
      <BackButton onClick={() => (navigate("/create-account"))} />
    </div>
  )
}

export default DataProtectionScreen
