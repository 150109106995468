interface Props {
    text: string,
    onClick: () => void,
    pointer?: boolean,
    uppercase?: boolean,
    fontSize?: number,
}

const WhiteButtonBlackBorder: React.FC<Props> = ({
    text,
    onClick,
    pointer = true,
    uppercase = true,
    fontSize = 14,
}) => {
  return (
    <button
        className={`w-full h-full rounded-[5px] font-bold text-[${fontSize}px] leading-[17px] text-black bg-white border border-solid border-black ${uppercase && "uppercase"} ${pointer && "pointer"}`}
        onClick={onClick}
    >
      {text}
    </button>
  )
}

export default WhiteButtonBlackBorder
