import { FormEvent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "../../../../components/buttons/PrimaryButton"
import WhiteButton from "../../../../components/buttons/WhiteButton"
import TextInputElement from "../../../../components/input/v2/TextInputElement"
import { updateTrackerByOrderId } from "../../../../utils/apis/dna-test-tracker"
import { updateUserInfoWithSwabcode } from "../../../../utils/apis/user-service"
import { HOLIFYA_LOGO, SWAB_CODE_MINIMUM_DIGITS } from "../../../../utils/Constants"
import { useAuth } from "../../../../utils/context/AuthContext"
import { useDNATestTracker } from "../../../../utils/context/DNATestTrackerContext"
import { TrackingStatus } from "../../../../utils/enums/TrackingStatus"
import CloseButton from "../../components/CloseButton"
import NavBar from "../../components/NavBar"
import { NavBarElement } from "../../components/NavBarElement"

const RegisterSwabCode = () => {
    const { auth } = useAuth()
    const userId = auth.userId
    const idToken = auth.idToken
    const { dnaTestTracker, updateDNATestTracker } = useDNATestTracker()
    const orderId = dnaTestTracker.orderId
    const status = dnaTestTracker.status
    const canProceed = userId && idToken && orderId && status
    const navigate = useNavigate()
    const [errorText, setErrorText] = useState("")
    const [swabCode, setSwabCode] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [swabCodeImageLoaded, setSwabCodeImageLoaded] = useState(false)
    const [shouldShowSwabCodeImage, setShouldShowSwabCodeImage] = useState(false)
    const [shouldShowHints, setShouldShowHints] = useState(false)
    const [showHintsArrow, setShowHintsArrow] = useState("/images/new-platform/components/down-arrow-blue.svg")

    const [currentItem, setCurrentItem] = useState(NavBarElement.Diagnostica)
    const onMenuClick = () => {
        if (currentItem === NavBarElement.Diagnostica) {
            setCurrentItem(NavBarElement.Menu)
        } else {
            setCurrentItem(NavBarElement.Diagnostica)
        }
    }

    const isSubmitButtonDisabled = swabCode.length < SWAB_CODE_MINIMUM_DIGITS

    useEffect(() => {
        // Preload all necessary images
        const imagesToPreload = [
            "/images/new-platform/components/swab-code.png",
            "/images/new-platform/components/up-arrow-blue.svg",
            "/images/new-platform/components/down-arrow-blue.svg",
            "/images/new-platform/components/number-0.svg",
            "/images/new-platform/components/letter-o.svg",
            "/images/new-platform/components/number-1.svg",
            "/images/new-platform/components/letter-l.svg",
            "/images/new-platform/components/letter-I.svg",
        ];

        imagesToPreload.forEach((src) => {
            const img = new Image();
            img.src = src;
        });

        // Set swab code image as loaded after it is preloaded
        const swabCodeImg = new Image();
        swabCodeImg.src = "/images/new-platform/components/swab-code.png";
        swabCodeImg.onload = () => setSwabCodeImageLoaded(true);
    }, []);

    const onSubmitButtonClick = (e: FormEvent) => {
        e.preventDefault()
        if (!canProceed) {
            return
        }
        setIsLoading(true)
        updateUserInfoWithSwabcode(
            userId,
            idToken,
            swabCode,
            () => {
                updateTrackerAndNavigate()
            },
            (error) => {
                console.log(error)
                updateUserInfoWithSwabcode(
                    userId,
                    idToken,
                    swabCode,
                    () => {
                        updateTrackerAndNavigate()
                    },
                    (error) => {
                        console.log(error)
                        setIsLoading(false)
                        setErrorText("Codice non valido")
                    },
                )
            },
        )
    }

    const updateTrackerAndNavigate = () => {
        if (!canProceed) {
            return
        }
        updateTrackerByOrderId(
            orderId,
            idToken,
            TrackingStatus.SampleRegistered,
            (tracker) => {
                const newStatus = tracker.tracking_status
                updateDNATestTracker(newStatus, orderId)
                setIsLoading(false)
                navigate("/diagnostica/tests-and-reports/dna-test/packing")
            },
            (error) => {
                console.log(error)
                setIsLoading(false)
            }
        )
    }

    const onBackButtonClick = () => {
        navigate("/diagnostica/tests-and-reports/dna-test/tutorial")
    }

    const onSwabCodeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        errorText !== "" && setErrorText("")
        setSwabCode(e.target.value)
    }

    const onInfoButtonClick = () => {
        if (swabCodeImageLoaded) {
            setShouldShowSwabCodeImage(true)
        }
    }

    const onShowHintsClick = () => {
        if (shouldShowHints) {
            setShouldShowHints(false)
            setShowHintsArrow("/images/new-platform/components/down-arrow-blue.svg")
        } else {
            setShouldShowHints(true)
            setShowHintsArrow("/images/new-platform/components/up-arrow-blue.svg")
        }
    }

    return (
        <div className="NewApp">
            <div className={`w-full h-full md:flex md:flex-row-reverse`}>
                <div className={`w-full h-[calc(100%-90px)] flex flex-col items-center justify-end realtive ${shouldShowSwabCodeImage && "visible_30_percent inactive"} md:w-[calc(100%-100px)] md:h-full`}>
                    <div className="w-[90%] h-[95%] flex flex-col overflow-y-auto scrollbar-hide">
                        <div className="hidden md:block h-[35px] my-[10px]">
                            <img src={HOLIFYA_LOGO} alt="Holifya Logo" />
                        </div>
                        <div className="w-full my-[10px] flex justify-end flex-shrink-0">
                            <CloseButton
                                onClick={() => navigate("/diagnostica/tests-and-reports/dna-test")}
                            />
                        </div>
                        <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px] flex-shrink-0">
                            Registra campione
                        </div>
                        <div className="">
                            <div className="mt-[10px] mb-[5px] flex items-center flex-shrink-0">
                                <div className="font-normal text-[14px] leading-[20px] mr-[10px]">Inserisci il tuo codice campione.</div>
                                <div
                                    className="icon-inline cursor-pointer"
                                    onClick={onInfoButtonClick}
                                >
                                    <img
                                        src="/images/new-platform/components/info-logo.svg"
                                        alt="Info"
                                    />
                                </div>
                            </div>
                            <div className="font-normal text-[16px] leading-[26px] italic mb-[20px] flex-shrink-0">
                                Il tuo codice è unico e permette l'anonimizzazione del tuo campione durante la fase di logistica e analisi.
                            </div>
                            <div className="font-normal text-[14px] leading-[20px] mb-[40px] flex-shrink-0">
                                Presta attenzione ai <span className="flex items-center" onClick={onShowHintsClick}><span className="font-bold text-holifya-blue underline mr-[10px]">caratteri più facilmente fraintendibili</span><span className="cursor-pointer"><img src={showHintsArrow} alt="Show hints" /></span></span>
                            </div>
                            <div className="flex flex-col overflow-y-auto">
                                {
                                    shouldShowHints && <HintsWidget />
                                }
                                <form onSubmit={onSubmitButtonClick} className="flex flex-col flex-grow justify-end">
                                    <div className="w-full md:w-[362px]">
                                        <TextInputElement
                                            label="Codice campione"
                                            errorText={errorText}
                                            inputValue={swabCode}
                                            onChange={onSwabCodeInputHandler}
                                            placeholder="Es: as6TLu53Gvi9"
                                        />
                                    </div>
                                    <div className="my-[30px] w-full h-[120px] flex flex-col justify-between flex-shrink-0 md:flex-row md:w-[750px] md:h-[50px] md:my-[0] md:mb-[30px] md:fixed md:bottom-0">
                                        <div className="w-full h-[50px] md:w-[362px]">
                                            <PrimaryButton
                                                text="Registra"
                                                pointer={true}
                                                disabled={isSubmitButtonDisabled}
                                                fontSize={16}
                                                isLoading={isLoading}
                                            />
                                        </div>
                                        <div className="w-full h-[50px] md:w-[362px]">
                                            <WhiteButton
                                                text="Indietro"
                                                onClick={onBackButtonClick}
                                                fontSize={16}
                                                uppercase={false}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {shouldShowSwabCodeImage && <SwabCodeImageDialog onCloseClick={() => setShouldShowSwabCodeImage(false)} />}
                <NavBar
                    currentItem={currentItem}
                    onMenuClick={onMenuClick}
                    disabled={shouldShowSwabCodeImage}
                />
            </div>
        </div>
    )
}

export default RegisterSwabCode

interface Props {
    onCloseClick: () => void,
}
const SwabCodeImageDialog: React.FC<Props> = ({
    onCloseClick,
}) => {
    return (
        <div className="w-[90%] h-[208px] bg-white absolute rounded-[15px] flex items-center justify-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-platform md:w-[361px]">
            <div className="w-[90%] h-[90%] flex flex-col justify-between">
                <div className="w-full h-[40px] flex items-center justify-between">
                    <div className="font-bold text-[20px] leading-[40px]">
                        Il codice campione
                    </div>
                    <CloseButton
                        onClick={onCloseClick}
                    />
                </div>
                <div className="w-full h-[114px] mb-[15px]">
                    <img src="/images/new-platform/components/swab-code.png" />
                </div>
            </div>
        </div>
    )
}

const HintsWidget = () => {
    return (
        <div className="w-full h-[279px] rounded-[15px] bg-holifya-grey mb-[40px] flex items-center justify-center flex-shrink-0 md:w-[591px]">
            <div className="w-[90%] h-[90%] flex flex-col justify-evenly">
                <div className="h-[90px] flex items-center justify-between">
                    <HintSingleElement image="/images/new-platform/components/number-0.svg" text="0 (numero zero)" />
                    <HintSingleElement image="/images/new-platform/components/letter-o.svg" text="O (o maiuscola)" />
                    <HintSingleElement image="/images/new-platform/components/number-1.svg" text="1 (uno numero)" />
                </div>
                <div className="h-[90px] flex items-center justify-between">
                    <HintSingleElement image="/images/new-platform/components/letter-l.svg" text="l (elle minuscola)" />
                    <HintSingleElement image="/images/new-platform/components/letter-i.svg" text="I (i maiuscola)" />
                    <HintSingleElement image="" text="1 (uno numero)" invisible={true} />
                </div>
            </div>
        </div>
    )
}

interface HintProps {
    image: string,
    text: string,
    invisible?: boolean,
}

const HintSingleElement: React.FC<HintProps> = ({
    image,
    text,
    invisible = false,
}) => {
    return (
        <div className={`h-full flex flex-col items-center justify-between ${invisible && "invisible"}`}>
            <div className="w-[48px] h-[48px] bg-white flex items-center justify-center"><img className="flex flex col justify-between" src={image} /></div>
            <div className="font-normal text-[14px] leading-[26px]">{text}</div>
        </div>
    )
}
