import "./css/TextInputElement.css"

/**
 * Props interface for TextInputElement.
 * 
 * @property {string} name - The name for the input field.
 * @property {string} label - The label text for the input field.
 * @property {string} placeholder - The placeholder text for the input field.
 * @property {string} errorText - The error message to display.
 * @property {string} inputValue - The current value of the input field.
 * @property {(e: React.ChangeEvent<HTMLInputElement>) => void} onChange - Callback function to handle input changes.
 */
interface Props {
    name?: string,
    label: string,
    placeholder: string,
    errorText: string,
    inputValue: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

/**
 * TextInputElement Component
 * 
 * A reusable text input component with label, placeholder, error message, and change handler.
 * 
 * @param {Props} props - The props for the component.
 * @returns {JSX.Element} The rendered text input element.
 */
const TextInputElement: React.FC<Props> = ({
    name,
    label,
    placeholder,
    errorText,
    inputValue,
    onChange,
}) => {
    return (
        <div className="text-input-element-container">
            <label
                className="text-input-element-label"
                htmlFor={`text-input-${label}`}
            >
                {label}
            </label>
            <input
                name={name}
                id={`text-input-${label}`}
                className="text-input-element-input-field"
                placeholder={placeholder}
                value={inputValue}
                type="text"
                onChange={onChange}
            />
            {errorText && <div className="text-input-element-error-text" aria-live="polite">
                {errorText}
            </div>}
        </div>
    )
}

export default TextInputElement
