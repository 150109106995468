import { CalendlyExpert } from "../api-objects/CalendlyExpert";
import { BASE_URL, requestOptionsGET } from "../Constants";
import { ExpertRole } from "../enums/Experts";
import { ErrorWithMessageCallback, GetMedicalUsersSuccessCallback, GetMedicalUserSuccessCallback } from "./callback-helpers";

export async function getMedicalUserByRole(
    idToken: string,
    role: ExpertRole,
    onSuccess: GetMedicalUsersSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/medical-user/role/${role}`, requestOptionsGET(idToken))
        if (response.ok) {
            const medicalUsers: CalendlyExpert[] = await response.json()
            onSuccess(medicalUsers)
        } else {
            onError("Errore")
        }
    } catch (error) {
        console.error("Get medical user by role failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getNutritionistByPatientId(
    idToken: string,
    userId: string,
    onSuccess: GetMedicalUserSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/medical-user/patient/${userId}`, requestOptionsGET(idToken))
        if (response.ok) {
            const nutritionist: CalendlyExpert = await response.json()
            onSuccess(nutritionist)
        } else {
            onError("Nutritionista non trovato")
        }
    } catch (error) {
        console.error("Get nutritionist by patient id failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

// ASSIGN specialist to a user
export async function assignMedicalUserToPatient(
    nutriId: string,
    userId: string,
    idToken: string,
    onSuccess: () => void,
    onError: (error: string) => void,
) {
    const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/assign/`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': idToken,
        },
        body: JSON.stringify(
            {
                patient_id: userId,
                medicaluser_id: nutriId,
            })
    })
    if (responseUserinfo.ok) {
        onSuccess()
    } else {
        onError("errire")
    }
}