interface Props {
  onClick: () => void;
  showBorder?: boolean,
  noFlexEnd?: boolean,
}

const CloseButton: React.FC<Props> = ({
  onClick,
  showBorder,
  noFlexEnd,
}) => {
  return (
    <button
      className={`${noFlexEnd ? "" : "close-button"} horizontal_flex_group align_items_center justify_content_space_around transparent_background uppercase s14 lh17 w700 width_109 br5 pointer height_55 no_border ${showBorder ? "black_border" : ""}`}
      onClick={onClick}
      type="button"
    >
      <img src="/images/x.svg" />
      <div className="no_decoration_text">
        Chiudi
      </div>
    </button>
  )
}

export default CloseButton
