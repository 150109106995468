import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import BackButton from "../../../components/BackButton"
import LoadingSpinner from "../../../components/LoadingSpinner"
import { forgotPasswordApi } from "../../../utils/apis/auth-service"
import ForgotPasswordInsertCodeBox from "./components/ForgotPasswordInsertCodeBox"

const ForgotPasswordInsertCode = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state.email

    const [isLoading, setIsLoading] = useState(false)

    const onBackButtonClick = () => {
        navigate("/forgot-password")
    }

    const handleResendCode = () => {
        forgotPasswordApi(
            email,
            () => {
                navigate("/forgot-password-insert-code", { state: { email } })
            },
            () => {},
        )
    }

    const handleInputSubmit = (inputData: { [key: string]: string }) => {
        const code = inputData.code
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
            navigate("/new-password", { state: { email, code } })
        }, 1000)
    }

    // ********************************************************************************

    return (
        <div className="App relative">
            <div className={`w-[90%] h-[80px] flex justify-between items-center ${isLoading && "opacity-30 pointer-events-none"}`}>
                <BackButton onClick={onBackButtonClick} />
            </div>
            <div className={`w-full h-[calc(100%-120px)] flex flex-col items-center justify-start sm:justify-center ${isLoading && "opacity-30 pointer-events-none"}`}>
                <div className="w-[328px] flex bg-white p-[20px]">
                    <ForgotPasswordInsertCodeBox
                        onSubmit={handleInputSubmit}
                        onResendCodeClick={handleResendCode}
                    />
                </div>
            </div>
            {
                isLoading && <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div>
            }
        </div>
    )
}

export default ForgotPasswordInsertCode
