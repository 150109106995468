import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import LoadingSpinner from "../../../components/LoadingSpinner"
import { getAppointments, readsConsultsLeft } from "../../../utils/apis/booking-service"
import { getBloodTrackerByUserId, getTrackerByUserId } from "../../../utils/apis/dna-test-tracker"
import { getSurveyCompletedByUserIdAndSurveyType } from "../../../utils/apis/emr-service"
import { getMedicalUserByRole, getNutritionistByPatientId } from "../../../utils/apis/medicaluser-service"
import { getMembership, getMostImportantTutorial } from "../../../utils/apis/order-service"
import { getHealthPlan } from "../../../utils/apis/plan-service"
import { getBloodcode } from "../../../utils/apis/swabcode-service"
import { CHECKUP_SURVEY_IDS_DEV, CHECKUP_SURVEY_IDS_PROD, ENVIRONMENT, HEALTH_ASSESSMENT_IDS_DEV, HEALTH_ASSESSMENT_IDS_PROD } from "../../../utils/Constants"
import { useAuth } from "../../../utils/context/AuthContext"
import { useBloodTestTracker } from "../../../utils/context/BloodTextTrackerContext"
import { useConsults } from "../../../utils/context/ConsultsContext"
import { useDNATestTracker } from "../../../utils/context/DNATestTrackerContext"
import { useExpert } from "../../../utils/context/ExpertContext"
import { useHealthPlan } from "../../../utils/context/HealthPlanContext"
import { useSurveyData } from "../../../utils/context/SurveyDataContext"
import { useTutorial } from "../../../utils/context/TutorialContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { useProducts } from "../../../utils/context/UserProductsContext"
import { AppointmentStatus, ConsultType } from "../../../utils/enums/Consults"
import { Environment } from "../../../utils/enums/Environment"
import { ExpertRole } from "../../../utils/enums/Experts"
import { SurveyType } from "../../../utils/enums/Surveys"
import { handleDaysDifference, handleHoursDifference, handleMinutesDifference } from "../../../utils/Functions"
import { Appointment } from "../../new-platform/components/Appointment"

const FetchData = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const userId = auth.userId
    const { userData } = useUserData()
    const email = userData.email
    const { experts } = useExpert()
    const specialist = experts.specialist
    const nutritionist = experts.nutritionist
    const { updateAllAppointments, updateMostRecentFutureAppointment, updateNutritionistFutureConsults, updateSpecialistFutureConsults, updateNutritionistConsultsLeft, updateSpecialistConsultsLeft, updateAllFutureAppointments, updateAllPastAppointments } = useConsults()
    const { updateHealthAssessmentQuestions, updateCheckupSurveyQuestions, setHealthAssessmentCompleted } = useSurveyData()
    const { updateDNATestTracker } = useDNATestTracker()
    const { updateBloodTestTracker } = useBloodTestTracker()
    const { updateHealthPlan } = useHealthPlan()
    const { updateSpecialist, updateNutritionist } = useExpert()
    const { updateTutorial } = useTutorial()
    const { updateMembership } = useProducts()

    const navigate = useNavigate()

    const getExpert = () => {
        if (!idToken) return;
        getMedicalUserByRole(
            idToken,
            ExpertRole.Specialist,
            (experts) => {
                const expert = experts[0];
                updateSpecialist(expert)
                // getNutritionist()
                if (nutritionist) {
                    callGetAppointments()
                } else {
                    getNutritionist()
                }
            },
            (error) => {
                console.log(error)
                if (nutritionist) {
                    callGetAppointments()
                } else {
                    getNutritionist()
                }
            },
        )
    }

    const getNutritionist = () => {
        if (!idToken || !userId) return;
        getNutritionistByPatientId(
            idToken,
            userId,
            (nutritionist) => {
                updateNutritionist(nutritionist)
            },
            (error) => {
                console.log(error)
            },
        )
        callGetAppointments()
    }

    const callGetAppointments = () => {
        if (!idToken || !email) return
        getAppointments(
            idToken,
            email,
            (appointments) => {
                const allAppointments: Appointment[] = []
                for (const appointment of appointments) {
                    allAppointments.push({
                        date: appointment.payload.scheduled_event.start_time,
                        editUrl: appointment.payload.reschedule_url,
                        connectUrl: appointment.payload.scheduled_event.location.join_url,
                        cancelUrl: appointment.payload.cancel_url,
                        remainingDays: handleDaysDifference(appointment.payload.scheduled_event.start_time),
                        remainingHours: handleHoursDifference(appointment.payload.scheduled_event.start_time),
                        remainingMinutes: handleMinutesDifference(appointment.payload.scheduled_event.start_time),
                        expert: appointment.organizer_email === "elvira.pistolesi@holifya.com" ? specialist! : nutritionist!,
                        status: appointment.payload.status
                    })
                }
                const allActiveAppointments = allAppointments.filter(appointment => appointment.status === AppointmentStatus.Active)
                const futureAppointments = allActiveAppointments.filter(appointment => appointment.remainingMinutes > -30)
                const pastAppointments = allActiveAppointments.filter(appointment => appointment.remainingMinutes <= -30)
                updateAllAppointments(allActiveAppointments)
                updateAllFutureAppointments(futureAppointments)
                updateMostRecentFutureAppointment(futureAppointments[0])
                updateAllPastAppointments(pastAppointments)
                const expertFutureConsults = futureAppointments.filter(consult => consult.expert?.email === "elvira.pistolesi@holifya.com")
                const nutritionistFutureConsults = futureAppointments.filter(consult => consult.expert?.email !== "elvira.pistolesi@holifya.com")
                nutritionistFutureConsults.length > 0 && updateNutritionistFutureConsults(nutritionistFutureConsults)
                expertFutureConsults.length > 0 && updateSpecialistFutureConsults(expertFutureConsults)
                callUpdateQuestionIds()
            },
            (error) => {
                console.log(error)
                callUpdateQuestionIds()
            },
        )
    }

    const callUpdateQuestionIds = () => {
        var questionIds: string[] = []
        var checkupIds = []
        switch (ENVIRONMENT) {
            case Environment.DEVELOP:
                questionIds = HEALTH_ASSESSMENT_IDS_DEV
                checkupIds = CHECKUP_SURVEY_IDS_DEV
                updateHealthAssessmentQuestions(questionIds)
                updateCheckupSurveyQuestions(checkupIds)
                break
            case Environment.STAGE:
                return ""
            case Environment.PRODUCTION:
                questionIds = HEALTH_ASSESSMENT_IDS_PROD
                checkupIds = CHECKUP_SURVEY_IDS_PROD
                updateHealthAssessmentQuestions(questionIds)
                updateCheckupSurveyQuestions(checkupIds)
                break
        }
        callCheckHealthAssessmentCompleted(questionIds)
    }

    const callCheckHealthAssessmentCompleted = (questionIds: string[]) => {
        if (!userId || !idToken) {
            return
        }
        // getAnswersByIdsAndUserId(
        //     questionIds,
        //     userId,
        //     idToken,
        //     (questions) => {
        //         if (questions.length >= questionIds.length - 1) {
        //             setHealthAssessmentCompleted(true)
        //         } else {
        //             setHealthAssessmentCompleted(false)
        //         }
        //         getSpecialistConsultsLeft()
        //     },
        //     (error) => {
        //         getSpecialistConsultsLeft()
        //         setHealthAssessmentCompleted(false)
        //         console.log(error)
        //     },
        // )
        getSurveyCompletedByUserIdAndSurveyType(
            userId,
            idToken,
            SurveyType.HealthAssessment,
            (message) => {
                setHealthAssessmentCompleted(true)
                getSpecialistConsultsLeft()
            },
            (error) => {
                setHealthAssessmentCompleted(false)
                getSpecialistConsultsLeft()
            },
        )
    }

    const getSpecialistConsultsLeft = () => {
        if (!userId || !idToken) {
            return
        }

        readsConsultsLeft(
            userId,
            idToken,
            ConsultType.Specialist,
            (number) => {
                updateSpecialistConsultsLeft(number)
                getNutriConsultsLeft()
            },
            (error) => {
                updateSpecialistConsultsLeft(0)
                console.log(error)
                getNutriConsultsLeft()
            },
        )
    }

    const getNutriConsultsLeft = () => {
        if (!userId || !idToken) {
            return
        }

        readsConsultsLeft(
            userId,
            idToken,
            ConsultType.Nutritionist,
            (number) => {
                updateNutritionistConsultsLeft(number)
                callGetHealthPlan()
            },
            (error) => {
                updateNutritionistConsultsLeft(0)
                console.log(error)
                callGetHealthPlan()
            },
        )
    }

    const callGetHealthPlan = () => {
        if (!userId || !idToken) return
        getHealthPlan(
            userId,
            idToken,
            (healthPlan) => {
                updateHealthPlan(healthPlan)
                callGetTracker()
            },
            (error) => {
                console.log(error)
                callGetTracker()
            }
        )
    }

    const callGetTracker = () => {
        if (!userId || !idToken) return
        getTrackerByUserId(
            userId,
            idToken,
            (trackers) => {
                console.log(trackers)
                const tracker = trackers[0]
                const status = tracker.tracking_status
                const orderId = tracker.order_id
                updateDNATestTracker(status, orderId)
            },
            (error) => {
                console.log(error)
            },
        )
        callGetBloodTracker()
    }

    const callGetBloodTracker = () => {
        if (!userId || !idToken) return
        getBloodTrackerByUserId(
            userId,
            idToken,
            (tracker) => {
                const status = tracker.tracking_status
                updateBloodTestTracker(status)
            },
            (error) => {
                console.log(error)
            },
        )
        callGetBloodcode()
    }

    const callGetBloodcode = () => {
        if (!userId || !idToken) return
        getBloodcode(
            userId,
            idToken,
            (bloodcode) => {
                // updateBloodcode(bloodcode)
            },
            (error) => { console.log(error) },
        )
        callGetMembership()
    }

    const callGetMembership = () => {
        if (!userId || !idToken) return
        getMembership(
            userId,
            idToken,
            (membership) => {
                updateMembership(membership)
                callGetMostImportantUnconfirmedTutorial()
            },
            (error) => {
                console.log(error)
                callGetMostImportantUnconfirmedTutorial()
            },
        )
    }

    const callGetMostImportantUnconfirmedTutorial = () => {
        if (!userId || !idToken) return
        getMostImportantTutorial(
            userId,
            idToken,
            (tutorial) => {
                updateTutorial(tutorial)
                navigate("/home")
            },
            (error) => {
                console.log(error)
                navigate("/home")
            },
        )
    }

    useEffect(() => {
        getExpert()
    }, [])

    // ********************************************************************************

    return (
        <div className="App justify-center">
            <div className="flex flex-col bg-white w-[392px] m-[50px]">
                <div className="font-bold text-[30px] leading-[24px] self-center my-[20px] text-center">
                    Accesso in corso
                </div>
                <div className="font-normal text-[18px] leading-[26px] my-[20px] text-center self-center">
                    Stai per essere reindirizzato alla tua Area Riservata
                </div>
                <LoadingSpinner />
            </div>
        </div>
    )
}

export default FetchData
