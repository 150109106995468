import { useState } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import WhiteButtonBlackBorder from "../../../components/buttons/WhiteButtonBlackBorder"
import TextInputElement from "../../../components/input/v1/TextInputElement"
import { createProduct, findProduct } from "../../../utils/apis/order-service"
import { useAuth } from "../../../utils/context/AuthContext"
import { useProducts } from "../../../utils/context/UserProductsContext"
import { CreateProductChannel } from "../../../utils/enums/CreateProductChannel"
import { ProductType } from "../../../utils/enums/ProductType"

const MenuRedeem = () => {
    const navigate = useNavigate()
    const { auth } = useAuth()
    const idToken = auth.idToken
    const userId = auth.userId
    const { productsData } = useProducts()
    const membership = productsData.membership
    const dnaTest = productsData.dnaTest
    const [code, setCode] = useState("")
    const [codeError, setCodeError] = useState("")

    const [isLoading, setIsLoading] = useState(false)

    const onBackToShopClick = () => {
        navigate("/menu/payment-methods-and-purchases")
    }

    const onSubmitClick = () => {
        callFindOrder()
    }

    const callFindOrder = () => {
        if (!idToken || !userId) return

        setIsLoading(true)

        findProduct(
            code,
            idToken,
            (product) => {
                const type = product
                if (type === ProductType.DnaTest && (membership || dnaTest)) {
                    setCodeError("Hai già un DNA test associato, non puoi associarne un altro")
                    setIsLoading(false)
                    return
                }
                createProduct(
                    userId,
                    idToken,
                    type,
                    (success) => {
                        navigate("/menu/payment-methods-and-purchases")
                        setIsLoading(false)
                    },
                    (error) => {
                        console.log(error)
                        setIsLoading(false)
                    },
                    {
                        shopify_product: "one-off",
                        channel: CreateProductChannel.RedeemCode,
                        code: code,
                        buyer: null,
                    }
                )
            },
            (error) => {
                setCodeError(error)
                setIsLoading(false)
            }
        )
    }

    // ********************************************************************************

    return (
        <div className="NewApp">
            <div className="w-full h-full flex flex-col items-center justify-end">
                <div className="w-[90%] h-[90%] flex flex-col items-center justify-between">
                    <div className="h-[calc(100%-245px)] flex flex-col items-center justify-center">
                        <div className="font-bold text-[25px] leading-[33px] mb-[10px]">
                            🔓 Riscatta
                        </div>
                        <div className="font-normal text-[16px] leading-[24px] text-center mt-[10px]">
                            Se hai ricevuto un Codice Riscatto da qualcuno puoi riscattare il prodotto associato inserendolo qui sotto.
                        </div>
                    </div>
                    <div className="w-full mb-[20px]">
                        <div className="h-[85px] mb-[20px]">
                            <TextInputElement
                                label="Codice riscatto"
                                placeholder="123456"
                                errorText={codeError}
                                inputValue={code}
                                onChange={(e) => {
                                    setCodeError("")
                                    setCode(e.target.value)
                                }}
                            />
                        </div>
                        <div className="w-full h-[120px] flex flex-col justify-between">
                            <div className="w-full h-[50px]">
                                <PrimaryButton
                                    text="Conferma codice"
                                    pointer={code.length >= 6}
                                    disabled={code.length < 6}
                                    onClick={onSubmitClick}
                                    isLoading={isLoading}
                                />
                            </div>
                            <div className="w-full h-[50px]">
                                <WhiteButtonBlackBorder
                                    text="Indietro"
                                    onClick={onBackToShopClick}
                                    uppercase={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MenuRedeem
