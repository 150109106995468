import { Exam } from "../../../utils/api-objects/Exam"
import { handleDate } from "../../../utils/Functions"
import DownloadButton from "./DownloadButton"

interface Props {
    exam: Exam,
    pdfType: PdfType,
}

export const PdfCard: React.FC<Props> = ({
    exam,
    pdfType,
}) => {

    const handleEmoji = () => {
        switch (pdfType) {
            case PdfType.DnaTest:
                return "🧬"
            case PdfType.BloodTest:
                return "🩸"
            case PdfType.CrossResults:
                return "🔀"
            case PdfType.HealthPlan:
                return "🌀"
            case PdfType.Diet:
                return "🥙"
            case PdfType.ShoppingList:
                return "🛒"
            case PdfType.TipsAndRecepes:
                return "🧑‍🍳"
            case PdfType.EatingHabits:
                return "🍽"
            case PdfType.SeasonalFruitsAndVegetables:
                return "🍎"
            case PdfType.Uploads:
                return "🗂️"
        }
    }

    const handleFilename = () => {
        switch (pdfType) {
            case PdfType.CrossResults:
                return "CrossResults.pdf"
            case PdfType.HealthPlan:
                return "HealthPlan.pdf"
            case PdfType.Diet:
                return "Dieta.pdf"
            case PdfType.ShoppingList:
                return "Lista della spesa.pdf"
            case PdfType.TipsAndRecepes:
                return "Tips e ricette.pdf"
            case PdfType.EatingHabits:
                return "Eating habits.pdf"
            case PdfType.SeasonalFruitsAndVegetables:
                return "Frutta e verdura.pdf"
            default:
                return exam.filename
        }
    }

    // ********************************************************************************

    return (
        <div className="w-full h-[516px] rounded-[10px] relative shadow-platform flex-shrink-0 md:w-[362px] md:h-[527px]">
            <iframe
                className="w-full h-[75%] border-none m-[0] p-[0] block"
                src={exam.url + "#toolbar=0"}
            />
            <div className="w-full h-[25%] m-[0] p-[0] flex items-center justify-center">
                <div className="w-[90%] h-[50%] flex items-center justify-between">
                    <div className="h-full flex flex-col items-start justify-between">
                        <div className="font-semibold text-[12px] leading-[26px]">{handleDate(exam.uploaded_at)}</div>
                        <div className="font-semibold text-[16px] leading-[26px]">{handleFilename()}</div>
                    </div>
                    <DownloadButton onClick={() => window.open(exam.url)} />
                </div>
            </div>
            <div className="w-full h-[75%] bg-transparent absolute top-[0] left-[0] flex items-start justify-center">
                <div className="w-[90%] mt-[10px] flex justify-end">
                    <div className="w-[30px] h-[30px] rounded-[5px] bg-white flex items-center justify-center">
                        {handleEmoji()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export enum PdfType {
    DnaTest = "dna-test",
    BloodTest = "blood-test",
    CrossResults = "cross-results",
    HealthPlan = "health-plan",
    Diet = "diet",
    EatingHabits = "eating-habits",
    ShoppingList = "shopping-list",
    TipsAndRecepes = "tips-and-recepes",
    SeasonalFruitsAndVegetables = "seasonal-fruits-and-vegetables",
    Uploads = "uploads",
}