import { useState } from "react"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import WhiteButton from "../../../components/buttons/WhiteButton"
import { useUserData } from "../../../utils/context/UserDataContext"
import { ProductType } from "../../../utils/enums/ProductType"
import { handleShopifyProductName } from "../../../utils/Functions"
import NavBar from "../components/NavBar"
import { NavBarElement } from "../components/NavBarElement"

const PurchasePage = () => {
    const { userData } = useUserData()
    const email = userData.email
    const [currentItem, setCurrentItem] = useState(NavBarElement.MenuElement)
    const onMenuClick = () => {
        setCurrentItem((prev) => prev === NavBarElement.MenuElement ? NavBarElement.Menu : NavBarElement.HealthPlan)
    }

    // ********************************************************************************

    return (
        <div className="NewApp">
            <div className="w-full h-full flex md:flex md:flex-row-reverse">
                <div className="w-full h-[calc(100%-90px)] flex flex-col items-center justify-end overflow-y-auto scrollbar-hide md:w-[calc(100%-100px)] md:h-full">
                    <div className="w-[90%] h-[95%] flex flex-col">
                        <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
                            Acquista
                        </div>
                        <div className="font-semibold text-[12px] leading-[20px] mb-[20px]">
                            Per riscattare e gestire gli acquisti con questo account assicurati di utilizzare la mail <span className="text-holifya-blue">{email}</span> al momento dell'acquisto.
                        </div>
                        <PurchaseCard
                            product={ProductType.DnaTest}
                        />
                        <PurchaseCard
                            product={ProductType.SubHealth}
                        />
                        <PurchaseCard
                            product={ProductType.SubNutrition}
                        />
                        <PurchaseCard
                            product={ProductType.ExpertConsult}
                        />
                        <PurchaseCard
                            product={ProductType.Nutritionist}
                        />
                        <div className="p-[5px]" />
                    </div>
                </div>
                <NavBar
                    currentItem={currentItem}
                    onMenuClick={onMenuClick}
                />
            </div>
        </div>
    )
}

export default PurchasePage

interface Props {
    product: ProductType,
}

const PurchaseCard: React.FC<Props> = ({
    product,
}) => {
    const handleSubTitle = () => {
        switch (product) {
            case ProductType.DnaTest:
                return "DNA Test"
            case ProductType.SubHealth:
            case ProductType.SubNutrition:
                return "Abbonamento"
            case ProductType.ExpertConsult:
            case ProductType.Nutritionist:
                return "Consulto"
        }
    }

    const onClick = () => {
        switch (product) {
            case ProductType.DnaTest:
                return window.open("https://www.holifya.com/products/dna-complete", "_blank")
            case ProductType.ExpertConsult:
                return window.open("https://www.holifya.com/pages/consulto-nutrigenetico", "_blank")
            case ProductType.Nutritionist:
                return window.open("https://www.holifya.com/pages/consulto", "_blank")
            case ProductType.SubHealth:
                return window.open("https://www.holifya.com/products/percorso-health", "_blank")
            case ProductType.SubNutrition:
                return window.open("https://www.holifya.com/products/percorso-nutrition", "_blank")
        }
    }

    // ********************************************************************************

    return (
        <div className="w-full rounded-[15px] shadow-platform p-[10px] mb-[20px] md:flex md:justify-between">
            <div className="md:flex md:flex-col md:justify-center">
                <div className="font-bold text-[12px] leading-[20px]">{handleSubTitle()}</div>
                <div className="font-bold text-[25px] leading-[25px] mt-[10px] mb-[30px]">{handleShopifyProductName(product)}</div>
            </div>
            <div className="w-full h-[100px] flex flex-col justify-between md:w-[330px]">
                <div className="w-full h-[50px]">
                    <PrimaryButton
                        text={"Acquista"}
                        pointer={true}
                        disabled={false}
                        onClick={onClick}
                    />
                </div>
                <div className="w-full h-[40px]">
                    <WhiteButton
                        text={"Più informazioni"}
                        onClick={onClick}
                        fontSize={16}
                        uppercase={false}
                    />
                </div>
            </div>
        </div>
    )
}
