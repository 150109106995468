import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Exam } from "../../../../utils/api-objects/Exam"
import { getAllHealthPlanExamsByType } from "../../../../utils/apis/emr-service"
import { HOLIFYA_LOGO } from "../../../../utils/Constants"
import { useAuth } from "../../../../utils/context/AuthContext"
import { BucketFolder } from "../../../../utils/enums/BucketFolder"
import BackButton from "./../../components/BackButton"
import NavBar from "./../../components/NavBar"
import { NavBarElement } from "./../../components/NavBarElement"
import { PdfCard, PdfType } from "./../../components/PdfCard"

const TipsAndRecepes = () => {
  const { auth } = useAuth()
  const idToken = auth.idToken
  const userId = auth.userId
  const navigate = useNavigate()
  const [currentItem, setCurrentItem] = useState(NavBarElement.HealthPlan)
  const onMenuClick = () => {
    if (currentItem === NavBarElement.HealthPlan) {
      setCurrentItem(NavBarElement.Menu)
    } else {
      setCurrentItem(NavBarElement.HealthPlan)
    }
  }

  const [report, setReport] = useState<Exam>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!userId || !idToken) {
      return
    }
    getAllHealthPlanExamsByType(
      userId,
      idToken,
      BucketFolder.TipsAndRecepes,
      (exams) => {
        setReport(exams[0])
        setIsLoading(false)
      },
      (error) => {
        console.error(error)
        setIsLoading(false)
      }
    );
  }, []);

  // ********************************************************************************

  return (
    <div className="NewApp">
      <div className={`w-full h-full ${isLoading && "opacity-50 pointer-events-none"} md:flex md:flex-row-reverse`}>
        <div className="w-full h-[calc(100%-90px)] flex flex-col items-center justify-end overflow-y-auto scrollbar-hide md:w-[calc(100%-100px)] md:h-full">
          <div className="w-[90%] h-[95%] flex flex-col">
            <div className="hidden md:block h-[35px] my-[10px]">
              <img src={HOLIFYA_LOGO} alt="Holifya Logo" />
            </div>
            <div className="my-[10px]">
              <BackButton
                text="Torna a Meal Plan"
                onClick={() => navigate("/health-plan/meal-plan")}
              />
            </div>
            <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
              Tips e ricette
            </div>
            {
              report ?
              <PdfCard exam={report} pdfType={PdfType.TipsAndRecepes} /> :
              <div className="font-normal text-[16px] leading-[26px] mb-[20px]">
                  Il tuo pdf con tips e ricette sarà disponibile dopo il tuo consulto con nutrizionista.
              </div>
            }
            <div className="p-[10px]"/>
          </div>
        </div>
        <NavBar
          currentItem={currentItem}
          onMenuClick={onMenuClick}
        />
      </div>
    </div>
  )
}

export default TipsAndRecepes
