import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { Membership } from '../api-objects/Membership';
import { CreateProductChannel } from '../enums/CreateProductChannel';


interface ProductsState {
    membership: Membership | null,
    dnaTest: CreateProductChannel | null,
}

// Define the shape of the consults context
interface ProductsContextType {
    productsData: ProductsState;
    updateMembership: (membership: Membership) => void;
    updateDnaTest: (channel: CreateProductChannel) => void;
    clearProducts: () => void;
}

// Create the consults context with default values
const ProductsContext = createContext<ProductsContextType | undefined>(undefined);

// Create a provider component
export const ProductsProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    // Initialize state with data from localStorage
    const [productsData, setProductsData] = useState<ProductsState>({
        membership: localStorage.getItem('membership') ? JSON.parse(localStorage.getItem('membership')!) : null,
        dnaTest: localStorage.getItem('dnaTest') ? JSON.parse(localStorage.getItem('dnaTest')!) : null,
    });

    // Effect to update localStorage when products state changes
    useEffect(() => {
        if (productsData.membership !== null) {
            localStorage.setItem('membership', JSON.stringify(productsData.membership));
        } else {
            localStorage.removeItem('membership');
        }

        if (productsData.dnaTest !== null) {
            localStorage.setItem('dnaTest', JSON.stringify(productsData.dnaTest));
        } else {
            localStorage.removeItem('dnaTest');
        }
    }, [productsData]);

    // Function to update membership
    const updateMembership = (membership: Membership) => {
        setProductsData(prevState => ({ ...prevState, membership }));
    };

    // Function to update dnaTest status
    const updateDnaTest = (dnaTest: CreateProductChannel) => {
        setProductsData(prevState => ({ ...prevState, dnaTest }));
    };

    // Function to clear product data
    const clearProducts = () => {
        setProductsData({
            membership: null,
            dnaTest: null,
        });
        localStorage.removeItem('membership');
        localStorage.removeItem('dnaTest');
    };

    // Provide context value to children
    return (
        <ProductsContext.Provider
            value={{
                productsData,
                updateMembership,
                updateDnaTest,
                clearProducts,
            }}
        >
            {children}
        </ProductsContext.Provider>
    );
};

// Custom hook to use the products context
export const useProducts = (): ProductsContextType => {
    const context = useContext(ProductsContext);
    if (context === undefined) {
        throw new Error('useProducts must be used within a ProductsProvider');
    }
    return context;
};