export enum ExpertRole {
    Nutritionist = "nutritionist",
    Specialist = "specialist",
    User = "user",
}

export enum ExpertGender {
    Male = "M",
    Female = "F",
}

export enum ExpertGenderRole {
    Dott = "Dott.",
    Dottssa = "Dott.ssa",
}