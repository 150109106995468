import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { HOLIFYA_LOGO } from "../../../utils/Constants"
import BackButton from "../components/BackButton"
import { Folder } from "../components/Folder"
import FolderBox from "../components/FolderBox"
import NavBar from "../components/NavBar"
import { NavBarElement } from "../components/NavBarElement"

const TestsAndReports = () => {
    const navigate = useNavigate()
    const [currentItem, setCurrentItem] = useState(NavBarElement.Diagnostica)
    const onMenuClick = () => {
        setCurrentItem((prev) => prev === NavBarElement.Diagnostica ? NavBarElement.Menu : NavBarElement.Diagnostica)
    }

    // ********************************************************************************

    return (
        <div className="NewApp">
            <div className="w-full h-full md:flex md:flex-row-reverse">
                <div className="w-full h-[calc(100%-90px)] flex flex-col items-center justify-end overflow-y-auto scrollbar-hide md:w-[calc(100%-100px)] md:h-full">
                    <div className="w-[90%] h-[95%] flex flex-col">
                        <div className="hidden md:block h-[35px] my-[10px]">
                            <img src={HOLIFYA_LOGO} alt="Holifya Logo" />
                        </div>
                        <div className="my-[10px]">
                            <BackButton
                                text="Torna a Diagnostica"
                                onClick={() => navigate("/diagnostica")}
                            />
                        </div>
                        <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
                            Test e referti
                        </div>
                        <div className="font-normal text-[16px] leading-[26px] mb-[10px]">
                            In questa cartella puoi accedere a tutti i documenti condivisi con la tua Medical Equipe,
                            oltre ai risultati dei tuoi test e alle risposte alle domande che ti vengono sottoposte
                            con i questionari.
                        </div>
                        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                            <FolderBox
                                folder={Folder.DNATest}
                                onClick={() => navigate("dna-test")}
                            />
                            <FolderBox
                                folder={Folder.BloodExams}
                                onClick={() => navigate("blood-test")}
                            />
                            <FolderBox
                                folder={Folder.HealthAssessment}
                                onClick={() => navigate("/health-assessment")}
                            />
                            <FolderBox
                                folder={Folder.UploadedForYou}
                                onClick={() => navigate("uploaded-for-you")}
                            />
                            <FolderBox
                                folder={Folder.UploadedFromYou}
                                onClick={() => navigate("uploaded-from-you")}
                            />
                        </div>
                        <div className="p-[10px]" />
                    </div>
                </div>
                <NavBar
                    currentItem={currentItem}
                    onMenuClick={onMenuClick}
                />
            </div>
        </div>
    )
}

export default TestsAndReports
