interface Props {
    text: string,
    onClick?: () => void,
    pointer: boolean,
    disabled: boolean,
    fontSize?: number,
    uppercase?: boolean,
}

const TntButton: React.FC<Props> = ({
    text,
    onClick,
    pointer,
    disabled,
    fontSize = 14,
    uppercase = true,
}) => {
  return (
    <button
      className={`w-full h-full border-none rounded-[5px] font-bold text-white bg-tnt hover:bg-tnt-hover disabled:bg-tnt-disabled active:bg-tnt-active ${pointer ? "cursor-pointer" : "cursor-not-allowed"} text-[${fontSize}px] ${uppercase && "uppercase"}`}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  )
}

export default TntButton
