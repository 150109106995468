import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import WhiteButton from "../../../components/buttons/WhiteButton";
import WhiteButtonBlueBorder from "../../../components/buttons/WhiteButtonBlueBorder";
import { HOLIFYA_LOGO } from "../../../utils/Constants";
import { handleDaysDifference, handleHoursDifference, handleMinutesDifference } from "../../../utils/Functions";
import { CalendlyExpert } from "../../../utils/api-objects/CalendlyExpert";
import { getAppointments, readsConsultsLeft } from "../../../utils/apis/booking-service";
import { useAuth } from "../../../utils/context/AuthContext";
import { useConsults } from "../../../utils/context/ConsultsContext";
import { useExpert } from "../../../utils/context/ExpertContext";
import { useUserData } from "../../../utils/context/UserDataContext";
import { AppointmentStatus, ConsultType } from "../../../utils/enums/Consults";
import { ExpertGender, ExpertRole } from "../../../utils/enums/Experts";
import { Appointment } from "../components/Appointment";
import BackButton from "../components/BackButton";
import CloseButton from "../components/CloseButton";
import LoadingSpinner from "../components/LoadingSpinner";
import NavBar from "../components/NavBar";
import { NavBarElement } from "../components/NavBarElement";

const YourExperts = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const userId = auth.userId
    const { userData } = useUserData()
    const email = userData.email
    const { experts } = useExpert()
    const specialist = experts.specialist
    const nutritionist = experts.nutritionist
    const { consultsData, updateAllAppointments, updateMostRecentFutureAppointment, updateNutritionistFutureConsults, updateSpecialistFutureConsults, updateSpecialistConsultsLeft, updateNutritionistConsultsLeft, updateAllFutureAppointments, updateAllPastAppointments } = useConsults()
    const specialistConsultsLeft = consultsData.specialistConsultsLeft
    const nutritionistConsultsLeft = consultsData.nutritionistConsultsLeft

    const [shouldShowExpertInfoDialog, setShouldShowExpertInfoDialog] = useState(false)
    const [shouldShowContactDialog, setShouldShowContactDialog] = useState(false)
    const [expertToFocusOn, setExpertToFocusOn] = useState<CalendlyExpert | null>(null)
    const [shouldShowBookConsultDialog, setShouldShowBookConsultDialog] = useState(false)
    const [focusExpert, setFocusExpert] = useState<CalendlyExpert>()

    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate()

    const [currentItem, setCurrentItem] = useState(NavBarElement.Equipe)
    const onMenuClick = () => {
        setCurrentItem(prev =>
            prev === NavBarElement.Equipe ? NavBarElement.Menu : NavBarElement.Equipe
        );
    };

    const toggleDialog = (dialogType: 'expertInfo' | 'contact', expert?: CalendlyExpert) => {
        setExpertToFocusOn(expert || null);
        if (dialogType === 'expertInfo') {
            setShouldShowExpertInfoDialog(prev => !prev);
            setShouldShowContactDialog(false);
        } else {
            setShouldShowContactDialog(prev => !prev);
            setShouldShowExpertInfoDialog(false);
        }
    };

    const onExpertInfoClick = (expert: CalendlyExpert) => toggleDialog('expertInfo', expert);
    const onContactClick = (expert: CalendlyExpert) => toggleDialog('contact', expert);

    const closeDialogs = () => {
        setShouldShowExpertInfoDialog(false);
        setShouldShowContactDialog(false);
    };

    const onBookConsultClick = (expert: CalendlyExpert) => {
        setShouldShowBookConsultDialog(true)
        setFocusExpert(expert)
    }

    const callGetAppointments = () => {
        if (!idToken || !email) return
        getAppointments(
            idToken,
            email,
            (appointments) => {
                const allAppointments: Appointment[] = []
                for (const appointment of appointments) {
                    allAppointments.push({
                        date: appointment.payload.scheduled_event.start_time,
                        editUrl: appointment.payload.reschedule_url,
                        connectUrl: appointment.payload.scheduled_event.location.join_url,
                        cancelUrl: appointment.payload.cancel_url,
                        remainingDays: handleDaysDifference(appointment.payload.scheduled_event.start_time),
                        remainingHours: handleHoursDifference(appointment.payload.scheduled_event.start_time),
                        remainingMinutes: handleMinutesDifference(appointment.payload.scheduled_event.start_time),
                        expert: appointment.organizer_email === "elvira.pistolesi@holifya.com" ? specialist! : nutritionist!,
                        status: appointment.payload.status
                    })
                }
                const allActiveAppointments = allAppointments.filter(appointment => appointment.status === AppointmentStatus.Active)
                const futureAppointments = allActiveAppointments.filter(appointment => appointment.remainingMinutes > -30)
                const pastAppointments = allActiveAppointments.filter(appointment => appointment.remainingMinutes <= -30)
                updateAllAppointments(allActiveAppointments)
                updateAllFutureAppointments(futureAppointments)
                updateMostRecentFutureAppointment(futureAppointments[0])
                updateAllPastAppointments(pastAppointments)
                const expertFutureConsults = futureAppointments.filter(consult => consult.expert?.email === "elvira.pistolesi@holifya.com")
                const nutritionistFutureConsults = futureAppointments.filter(consult => consult.expert?.email !== "elvira.pistolesi@holifya.com")
                nutritionistFutureConsults.length > 0 ? updateNutritionistFutureConsults(nutritionistFutureConsults) : updateNutritionistFutureConsults(null)
                expertFutureConsults.length > 0 ? updateSpecialistFutureConsults(expertFutureConsults) : updateSpecialistFutureConsults(null)
            },
            (error) => {
                console.log(error)
            },
        )
    }

    const getSpecialistConsultsLeft = () => {
        if (!userId || !idToken) {
            return
        }

        readsConsultsLeft(
            userId,
            idToken,
            ConsultType.Specialist,
            (number) => {
                if (specialistConsultsLeft !== number) {
                    updateSpecialistConsultsLeft(number)
                }
            },
            (error) => {
                updateSpecialistConsultsLeft(0)
            },
        )
    }

    const getNutriConsultsLeft = () => {
        if (!userId || !idToken) {
            return
        }

        readsConsultsLeft(
            userId,
            idToken,
            ConsultType.Nutritionist,
            (number) => {
                if (nutritionistConsultsLeft !== number) {
                    updateNutritionistConsultsLeft(number)
                }
            },
            (error) => {
                updateNutritionistConsultsLeft(0)
            },
        )
    }

    useEffect(() => {
        callGetAppointments()
        getNutriConsultsLeft()
        getSpecialistConsultsLeft()
    }, [])

    // ********************************************************************************

    return (
        <div className="NewApp">
            <div className={`w-full h-full ${isLoading && "opacity-50 pointer-events-none"} md:flex md:flex-row-reverse`}>
                <div className={`w-full h-[calc(100%-90px)] flex flex-col items-center justify-end overflow-y-auto scrollbar-hide md:w-[calc(100%-100px)] md:h-full`}>
                    <div className={`w-[90%] h-[95%] flex flex-col ${(shouldShowExpertInfoDialog || shouldShowContactDialog || shouldShowBookConsultDialog) && "opacity-50 pointer-events-none"}`}>
                        <div className="hidden md:block h-[35px] my-[10px]">
                            <img src={HOLIFYA_LOGO} alt="Holifya Logo" />
                        </div>
                        <div className="my-[10px]">
                            <BackButton
                                text="Torna a Equipe Medica"
                                onClick={() => navigate("/equipe")} />
                        </div>
                        <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
                            I tuoi Professionisti
                        </div>
                        <div className="font-semibold text-[12px] leading-[20px] mb-[20px]">
                            Per riscattare e gestire gli acquisti con questo account assicurati di utilizzare la mail <span className="text-holifya-blue">{email}</span> al momento dell'acquisto.
                        </div>
                        {
                            specialistConsultsLeft !== null && specialist &&
                            <ExpertCard
                                expert={specialist}
                                onExpertInfoClick={onExpertInfoClick}
                                onContactClick={onContactClick}
                                consultsLeft={specialistConsultsLeft}
                                onBookClick={onBookConsultClick}
                            />
                        }
                        {
                            nutritionistConsultsLeft !== null && nutritionist &&
                            <ExpertCard
                                expert={nutritionist}
                                onExpertInfoClick={onExpertInfoClick}
                                onContactClick={onContactClick}
                                consultsLeft={nutritionistConsultsLeft}
                                onBookClick={onBookConsultClick}
                            />
                        }
                        <div className="h-[20px] flex-shrink-0" />
                    </div>
                    <NavBar
                        currentItem={currentItem}
                        onMenuClick={onMenuClick}
                        disabled={shouldShowContactDialog || shouldShowExpertInfoDialog}
                    />
                    {
                        shouldShowExpertInfoDialog && expertToFocusOn &&
                        <ExpertInfoDialog
                            expert={expertToFocusOn}
                            onExpertInfoDismissClick={closeDialogs}
                        />
                    }
                    {
                        shouldShowContactDialog && expertToFocusOn &&
                        <ContactExpertDialog
                            expert={expertToFocusOn}
                            onCloseClick={closeDialogs}
                        />
                    }
                    {
                        shouldShowBookConsultDialog && focusExpert &&
                        <BookConsultDialog
                            onCloseClick={() => setShouldShowBookConsultDialog(false)}
                            expert={focusExpert}
                        />
                    }
                </div>
            </div>
            {isLoading && <div className="w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center"><LoadingSpinner /></div>}
        </div>
    )
}

export default YourExperts

interface Props {
    expert: CalendlyExpert,
    onExpertInfoClick: (expert: CalendlyExpert) => void,
    onContactClick: (expert: CalendlyExpert) => void,
    consultsLeft: number,
    onBookClick: (expert: CalendlyExpert) => void,
}

const ExpertCard: React.FC<Props> = ({
    expert,
    onExpertInfoClick,
    onContactClick,
    consultsLeft,
    onBookClick,
}) => {
    const onPurchaseClick = () => {
        if (consultsLeft === 0) {
            if (expert.role === ExpertRole.Specialist) {
                window.open("https://www.holifya.com/pages/consulto-nutrigenetico", "_blank")
            } else if (expert.role === ExpertRole.Nutritionist) {
                window.open("https://www.holifya.com/pages/consulto", "_blank")
            }
        } else {
            onBookClick(expert)
        }
    }

    // ********************************************************************************

    return (
        <div className="w-full rounded-[15px] flex items-center justify-center mb-[20px] shadow-platform flex-shrink-0">
            <div className="w-full h-full p-[20px] flex flex-col md:flex-row md:justify-between">
                <div className="flex items-center justify-between mb-[20px] md:mb-0">
                    <div className="w-[93px] h-[93px] rounded-[6px] border-2 border-solid border-black flex items-center justify-center md:h-full md:aspect-square">
                        <img
                            className="w-[85px] h-[85px]"
                            src={expert.calendly_data.calendly_user.avatar_url}
                        />
                    </div>
                    <div className="w-[calc(100%-110px)] h-[85px] flex flex-col justify-between md:w-fit md:ml-[20px]">
                        <div className="w-full flex items-center justify-between cursor-pointer">
                            <div className={`font-bold text-[10px] text-holifya-blue ${consultsLeft === 0 && "text-holifya-red"}`}>{consultsLeft === 1 ? "1 disponibile" : (consultsLeft === 0 ? "Nessun consulto rimasto": `${consultsLeft} disponibili`)}</div>
                            <div onClick={() => onContactClick(expert)}  className="flex"><div className={`hidden font-bold text-[16px] leading-[22px] mx-[20px] md:block`}>Scrivi</div><img className="md:mr-[20px]" src="/images/new-platform/components/send-email-logo.svg" alt="Contact"/></div>
                        </div>
                        <div>
                            <div className="font-bold text-[12px] leading-[20px]">{handleExpertGender(expert.data.sex)}</div>
                            <div className="font-bold text-[20px] leading-[20px]">{expert.data.name} {expert.data.family_name}</div>
                        </div>
                        <div className="font-bold text-[14px] leading-[20px] text-holifya-blue">{handleExpertRole(expert.role)}</div>
                    </div>
                </div>
                <div className="w-full h-[100px] flex flex-col justify-between md:w-[362px]">
                    <div className="w-full h-[50px]">
                        <PrimaryButton
                            text={consultsLeft === 0 ? "Acquista un consulto" : "Prenota un consulto"}
                            pointer={true}
                            disabled={false}
                            fontSize={16}
                            onClick={onPurchaseClick}
                        />
                    </div>
                    <div className="w-full h-[40px]">
                        <WhiteButton
                            text={`Conosci meglio ${expert.data.name}`}
                            onClick={() => onExpertInfoClick(expert)}
                            uppercase={false}
                            fontSize={16}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

interface InfoDialogProps {
    expert: CalendlyExpert,
    onExpertInfoDismissClick: () => void,
}

const ExpertInfoDialog: React.FC<InfoDialogProps> = ({
    expert,
    onExpertInfoDismissClick,
}) => {
    return (
        <div className="w-[90%] h-[491px] rounded-[15px] bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center shadow-platform md:w-[712px]">
            <div className="w-[90%] h-[90%] flex flex-col justify-between">
                <div className="w-full h-[95px] flex items-center justify-between">
                    <div className="w-[93px] h-[93px] rounded-[6px] border-2 border-solid border-black flex items-center justify-center">
                        <img
                            className="w-[85px] h-[85px]"
                            src={expert.calendly_data.calendly_user.avatar_url}
                        />
                    </div>
                    <div className="w-[calc(100%-110px)] h-[85px] flex flex-col justify-between">
                        <div className="w-full flex items-center justify-end cursor-pointer">
                            <CloseButton onClick={onExpertInfoDismissClick} />
                        </div>
                        <div>
                            <div className="font-bold text-[12px] leading-[20px]">{handleExpertGender(expert.data.sex)}</div>
                            <div className="font-bold text-[20px] leading-[20px]">{expert.data.name} {expert.data.family_name}</div>
                        </div>
                        <div className="font-bold text-[14px] leading-[20px] text-holifya-blue">{handleExpertRole(expert.role)}</div>
                    </div>
                </div>
                <div className="w-full flex wrap mt-[10px]">
                    {
                        expert.tags.map((tag, index) => (
                            <div className="rounded-[15px] font-semibold text-[12px] leading-[17px] py-[10px] px-[15px] bg-holifya-warning mx-[5px] mt-[10px]" key={index}>{tag}</div>
                        ))
                    }
                </div>
                <div className="w-full h-[223px] font-normal text-[16px] leading-[26px] overflow-y-auto scrollbar-hide">
                    {expert.data.bio}
                </div>
            </div>
        </div>
    )
}

interface ContactProps {
    expert: CalendlyExpert,
    onCloseClick: () => void,
}

const ContactExpertDialog: React.FC<ContactProps> = ({
    expert,
    onCloseClick,
}) => {
    const onCopyClick = () => {
        navigator.clipboard.writeText(expert.email)
            .then(() => toast.success("Email copiata con successo!"))
            .catch(() => toast.error("Errore nel copiare l'email"));

        onCloseClick();
    };

    return (
        <div className="w-[90%] h-[312px] bg-white rounded-[15px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center shadow-platform md:w-[362px]">
            <div className="w-[90%] h-[90%] flex flex-col justify-between">
                <div className="font-bold text-[20px] leading-[20px]">Vuoi scrivere al professionista?</div>
                <div className="font-normal text-[16px] leading-[26px]">Qui di seguito hai a disposizione la email del tuo professionista se hai bisogno di una comunicazione urgente.</div>
                <div className="w-full h-[120px] flex flex-col justify-between">
                    <div className="w-full h-[50px]">
                        <WhiteButtonBlueBorder
                            text="Copia email"
                            onClick={onCopyClick}
                            pointer={true}
                            uppercase={false}
                            fontSize={16}
                        />
                    </div>
                    <div className="w-full h-[50px]">
                        <WhiteButton
                            text={"Chiudi"}
                            onClick={onCloseClick}
                            uppercase={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const handleExpertGender = (sex: ExpertGender) => {
    switch (sex) {
        case ExpertGender.Male:
            return "Dott"
        case ExpertGender.Female:
            return "Dott.ssa"
    }
}

const handleExpertRole = (role: ExpertRole) => {
    switch (role) {
        case ExpertRole.Specialist:
            return "Medico"
        case ExpertRole.Nutritionist:
            return "Nutrizionista"
    }
}

interface BookDialogProps {
    onCloseClick: () => void,
    expert: CalendlyExpert,
}

export const BookConsultDialog: React.FC<BookDialogProps> = ({
    onCloseClick,
    expert,
}) => {
    const { userData } = useUserData()
    const email = userData.email

    // ********************************************************************************

    return (
        <div className="w-[90%] h-[350px] bg-white rounded-[15px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center shadow-platform md:w-[400px]">
            <div className="w-[90%] h-[90%] flex flex-col justify-between">
                <div className="w-full flex justify-end mb-[10px]">
                    <CloseButton
                        onClick={onCloseClick}
                    />
                </div>
                <div className="font-bold text-[16px] leading-[20px] mb-[10px]">Usa lo stesso indirizzo e-mail!</div>
                <div>
                    Stai per prenotare il tuo video consulto sulla piattaforma Calendly.<br></br>
                    È importante che tu utilizzi la stessa mail con la quale hai effettuato la
                    registrazione su Holifya.
                </div>
                <div className="w-full flex items-center justify-between border-b border-solid border-black mt-[10px]">
                    <div className="font-normal text-[16px] leading-[35px]">
                        {email}
                    </div>
                    <div
                        className="flex cursor-pointer"
                        onClick={() => {
                            navigator.clipboard.writeText(email!)
                                .then(() => toast.success("Email copiata con successo!"))
                                .catch(() => toast.error("Errore nel copiare l'email"));
                        }}
                    >
                        <img src="/images/copy-icon.svg" />
                        <div className="book-apointment-dialog-copy-text">Copia</div>
                    </div>
                </div>
                <div className="w-full h-[50px] mt-[20px]">
                    <PrimaryButton
                        text={"Prenota"}
                        pointer={true}
                        disabled={false}
                        onClick={() => {
                            onCloseClick()
                            window.open(expert.calendly_data.calendly_user.scheduling_url)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}