import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import { HOLIFYA_LOGO } from "../../../../utils/Constants";
import BackButton from "../../components/BackButton";
import LoadingSpinner from "../../components/LoadingSpinner";
import NavBar from "../../components/NavBar";
import { NavBarElement } from "../../components/NavBarElement";

const UploadedFromYou = () => {
    const navigate = useNavigate()
    const [currentItem, setCurrentItem] = useState(NavBarElement.Diagnostica)
    const onMenuClick = () => {
        setCurrentItem((prev) => prev === NavBarElement.Diagnostica ? NavBarElement.Menu : NavBarElement.Diagnostica);
    };

    const [isLoading, setIsLoading] = useState(false)

    const onUploadClick = () => {

    };

    // ********************************************************************************

    return (
        <div className="NewApp">
            <div className={`w-full h-full ${isLoading && "opacity-50 pointer-events-none"} md:flex md:flex-row-reverse`}>
                <div className="w-full h-[calc(100%-90px)] flex flex-col items-center justify-end overflow-y-auto scrollbar-hide md:w-[calc(100%-100px)] md:h-full">
                    <div className="w-[90%] h-[95%] flex flex-col">
                        <div className="hidden md:block h-[35px] my-[10px]">
                            <img src={HOLIFYA_LOGO} alt="Holifya Logo" />
                        </div>
                        <div className="my-[10px]">
                            <BackButton
                                text="Torna a Test e referti"
                                onClick={() => navigate("/diagnostica/tests-and-reports")}
                            />
                        </div>
                        <div className="flex flex-col md:flex-row md:justify-between">
                            <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
                                Caricati da te
                            </div>
                            <div className="w-full h-[50px] mb-[20px] md:w-[362px]">
                                <PrimaryButton
                                    text="Carica"
                                    pointer={true}
                                    disabled={false}
                                    onClick={onUploadClick}
                                />
                            </div>
                        </div>
                        <div className="font-normal text-[16px] leading-[26px] mb-[20px]">
                            In questa cartella puoi accedere a tutti i documenti che hai condiviso con la tua Medical Equipe.
                        </div>
                    </div>
                </div>
                <NavBar
                    currentItem={currentItem}
                    onMenuClick={onMenuClick}
                />
            </div>
            {isLoading && <div className="w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center"><LoadingSpinner /></div>}
        </div>
    )
}

export default UploadedFromYou
