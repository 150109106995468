import PrimaryButton from "../components/buttons/PrimaryButton";
import WhiteButtonBlueBorder from "../components/buttons/WhiteButtonBlueBorder";
import { Environment } from "./enums/Environment";
import { TutorialType } from "./enums/TutorialType";

// export const BASE_URL = "https://ayvk7yg741.execute-api.eu-central-1.amazonaws.com";

export const BASE_URL = process.env.REACT_APP_BASE_URL || "https://ayvk7yg741.execute-api.eu-central-1.amazonaws.com";
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || Environment.DEVELOP;

export const requestOptionsGET = (idToken: string) => ({
    method: 'GET',
    headers: {
        'Authorization': idToken
    }
});

export const PHARMACIES_CONFIRMATION_NUMBER = "FARMA6794LH"

export const NUTRITIONIST_APPOINTMENT_LINK = "https://www.holifya.com/pages/consulto"
export const SPECIALIST_APPOINTMENT_LINK = "https://www.holifya.com/pages/consulto-nutrigenetico"

export const YOUTUBE_VIDEO_TUTORIAL = "https://www.youtube.com/embed/lhD2OlmiETI?si=URjszY5cHx-xstf7"

export const CONFIRMATION_NUMBER_MINIMUM_DIGITS = 8
export const SWAB_CODE_MINIMUM_DIGITS = 12

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
export const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[.,:;\-_?!$€£&%/()='"*])[^\s]{8,16}$/

export const IMAGE_LOGO = "/images/full-logo-new.svg"

export const NEW_PLATFORM_HOME_LOGO = "/images/new-platform/navbar/home-logo.svg"
export const NEW_PLATFORM_HEALTH_PLAN_LOGO = "/images/new-platform/navbar/health-plan-logo.svg"
export const NEW_PLATFORM_DIAGNOSTICA_LOGO = "/images/new-platform/navbar/diagnostica-logo.svg"
export const NEW_PLATFORM_EQUIPE_LOGO = "/images/new-platform/navbar/equipe-logo.svg"
export const NEW_PLATFORM_MENU_LOGO = "/images/new-platform/navbar/menu-logo.svg"
export const NEW_PLATFORM_HOME_LOGO_FOCUS = "/images/new-platform/navbar/home-logo-focus.svg"
export const NEW_PLATFORM_HEALTH_PLAN_LOGO_FOCUS = "/images/new-platform/navbar/health-plan-logo-focus.svg"
export const NEW_PLATFORM_DIAGNOSTICA_LOGO_FOCUS = "/images/new-platform/navbar/diagnostica-logo-focus.svg"
export const NEW_PLATFORM_EQUIPE_LOGO_FOCUS = "/images/new-platform/navbar/equipe-logo-focus.svg"
export const NEW_PLATFORM_MENU_LOGO_FOCUS = "/images/new-platform/navbar/menu-logo-focus.svg"
export const NEW_PLATFORM_MENU_LOGO_X = "/images/new-platform/navbar/menu-logo-x.svg"
export const NEW_PLATFORM_HOME_LOGO_BLACK = "/images/new-platform/navbar/home-logo-black.svg"
export const NEW_PLATFORM_DIAGNOSTICA_LOGO_BLACK = "/images/new-platform/navbar/diagnostica-logo-black.svg"
export const NEW_PLATFORM_HEALTH_PLAN_LOGO_BLACK = "/images/new-platform/navbar/health-plan-logo-black.svg"
export const NEW_PLATFORM_EQUIPE_LOGO_BLACK = "/images/new-platform/navbar/equipe-logo-black.svg"

export const HEALTH_ASSESSMENT_IDS_DEV = [
    "783",
    "784",
    "785",
    "786",
    "787",
    "788",
    "789",
    "790",
    "791",
    "792",
    "793",
    "794",
    "795",
    "796",
    "797",
    "798",
    "799",
    "800",
    "801",
    "802",
    "803",
    "804",
    "805",
    "806",
    "807",
    "808",
    "809",
    "810",
    "811",
    "812",
    "813",
    "814",
    "815",
    "816",
    "817",
    "818",
    "819",
    "820",
    "821",
    "822",
    "823",
    "824",
    "825",
    "826",
    "827",
    "828",
    "829",
    "830",
    "831",
    "832",
]

export const CHECKUP_SURVEY_IDS_DEV = [
    "789",
    "787",
    "798",
    "799",
    "800",
    "801",
    "802",
    "803",
    "804",
    "805",
    "806",
    "808",
    "809",
    "810",
    "811",
    "812",
    "813",
    "814",
    "815",
    "816",
    "819",
    "820",
    "821",
    "822",
    "823",
    "824",
    "825",
    "826",
    "827",
    "828",
    "829",
    "830",
    "831",
    "832",
]

export const HEALTH_ASSESSMENT_IDS_PROD = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
]

export const CHECKUP_SURVEY_IDS_PROD = [
    "7",
    "5",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
]

export const ANSWERS_OPENING_INPUT = [
    "Altro (specificare)",
    "Sì (specificare)"
]

export const HOLIFYA_LOGO = "/images/new-platform/logos/holifya-logo.svg"

export const tutorialSlidesData = (email: string, type: TutorialType) => {
    switch (type) {
        case TutorialType.DNA_TEST:
            return [
                {
                    title: <>Hai acquistato un DNA Test Complete</>,
                    content: (
                        <>
                            Complimenti! Hai fatto un passo in più verso la versione migliore di te!<br />Il prodotto include l'analisi di 43 predisposizioni genetiche, linee guida e suggerimenti in basi ai tuoi risultati, e una mappa alimentare genetica degli alimenti amici e nemici del tuo corpo.
                        </>
                    ),
                    imageSrc: "images/new-platform/tutorial/tutorial-dna-1.jpg",
                },
                {
                    title: <>Cosa sta arrivando a casa tua</>,
                    content: (
                        <>
                            Stai per ricevere un kit che ti permetterà di prelevare il tuo DNA dalla comodità del tuo divano. È semplicissimo!<br />Segui le istruzioni, preleva il tuo campione di saliva e richiedi il ritiro.
                        </>
                    ),
                    imageSrc: "images/new-platform/tutorial/tutorial-dna-2.jpg",
                },
                {
                    title: <>Dove vedrai i risultati?</>,
                    content: (
                        <>
                            Riceverai una notifica via email non appena i risultati del tuo 🧬 <strong>Test del DNA</strong> saranno pronti! Potrai visualizzarli e scaricarli dalla tua area personale.<br />
                            Sarà disponibile sia in <strong>Home</strong> ( <span><img className="align-middle inline-block m-0 p-0 h-auto w-auto" src={NEW_PLATFORM_HOME_LOGO_BLACK} /></span> ) sia nella cartella 📁 <strong>Test e referti</strong> all'interno dell'area <strong>Diagnostica</strong> ( <span><img className="align-middle inline-block m-0 p-0 h-auto w-auto" src={NEW_PLATFORM_DIAGNOSTICA_LOGO_BLACK} /></span> ).
                        </>
                    ),
                    imageSrc: "images/new-platform/tutorial/tutorial-dna-3.jpg",
                },
                {
                    title: <>Cosa puoi fare dopo?</>,
                    content: (
                        <>
                            <div className="font-normal text-[16px] leading-[26px] mt-[20px]">
                                Puoi decidere di acquistare (quando vuoi):
                            </div>
                            <ul className="list-disc ml-[20px]">
                                <li className="ml-[20px]">👩‍⚕️ <strong>Consulto medico</strong> che ti aiuterà ad analizzare meglio i risultati del tuo test.</li>
                                <li className="ml-[20px]">👨‍⚕️ <strong>Consulto nutrizionista</strong> che imposterà per te un piano alimentare basato sui tuoi geni.</li>
                                <li className="ml-[20px]">🍎 <strong>Percorso Nutrition</strong> è un servizio pensato per coloro che desiderino intraprendere un percorso nutrizionale estremamente personalizzato per raggiungere i propri obiettivi di salute. La membership comprende il test del DNA e 3 consulti con un Nutrizionista Holifya, che elaborerà per te un piano alimentare personalizzato e costruito in base ai tuoi risultati genetici</li>
                                <li className="ml-[20px]">🌀 <strong>Percorso Health</strong>: il servizio di abbonamento più completo che possiamo offrirti! La membership comprende l'analisi degli aminoacidi, grazie a cui avrai una visione completa del tuo stato di salute, consulti ricorrenti con medici e nutrizionisti Holifya, aggiornamento e ottimizzazione mensile del tuo piano di salute e prevenzione personalizzato.</li>
                            </ul>
                            <div className="font-bold text-[16px] leading-[26px] mt-[10px]">
                                Se hai già acquistato il test del DNA, non paghi il primo mese di abbonamento!
                            </div>
                        </>
                    )
                },
                {
                    title: <>👩‍⚕️ Consulto con Medico e Nutrizionista</>,
                    content: (
                        <>
                            <div className="font-normal text-[16px] leading-[26px] mt-[20px]">
                                Puoi acquistare il numero di consulti che preferisci e riscattarli quando vuoi! Prenota il tuo consulto all'orario che preferisci, in base alle disponibilità del tuo professionista Holifya.<br />
                                Puoi accedere al calendario di prenotazione dalla <strong>Home</strong> ( <span><img className="align-middle inline-block m-0 p-0 h-auto w-auto" src={NEW_PLATFORM_HOME_LOGO_BLACK} /></span> ) o dall'area <strong>Equipe Medica</strong> ( <span><img className="align-middle inline-block m-0 p-0 h-auto w-auto" src={NEW_PLATFORM_EQUIPE_LOGO_BLACK} /></span> ). Il consulto con il Medico durerà circa 60 minuti, mentre i consulti con il Nutrizionista hanno una durata di circa 45 minuti.<br />
                                <strong>Nota</strong>: I consulti avvengono in modalità videoconsulto
                            </div>
                        </>
                    )
                },
                {
                    title: <>🌀 Percorso Health - <span className="font-normal">gratis per 1 mese</span></>,
                    content: (
                        <>
                            <div className="font-normal text-[16px] leading-[26px] mt-[20px]">
                                Un abbonamento progettato per crescere con te e supportarti nel raggiungimento dei tuoi obiettivi di salute.
                                Monitora la tua salute e i tuoi progressi nel tempo grazie alle 🩸 <strong>Analisi degli Aminoacidi</strong> ricorrenti, da eseguire comodamente a casa tua.
                                Ottieni un <strong>Health Plan</strong> personalizzato e su misura, che evolve con le tue esigenze, i tuoi obiettivi e il tuo stato di salute.
                                Ricevi il supporto continuo dei <strong>professionisti Holifya</strong>, sempre disponibili per ottimizzare il tuo piano e la tua routine quotidiana.
                            </div>
                        </>
                    )
                },
                {
                    title: <>Credi sia il momento giusto?</>,
                    content: (
                        <>
                            <div className="font-semibold text-[12px] leading-[20px] mb-[20px]">
                                Per riscattare e gestire gli acquisti con questo account assicurati di utilizzare la mail <span className="text-holifya-blue">{email}</span> al momento dell'acquisto.
                            </div>
                            <div className="font-bold text-[16px] leading-[26px]">
                                👩‍⚕️ Consulto con Medico
                            </div>
                            <div className="w-full h-[50px] mt-[20px] mb-[30px]">
                                <WhiteButtonBlueBorder
                                    text="Acquista"
                                    onClick={() => window.open("https://www.holifya.com/pages/consulto-nutrigenetico")}
                                    pointer={true}
                                    uppercase={false}
                                    fontSize={16}
                                />
                            </div>
                            <div className="font-bold text-[16px] leading-[26px]">
                                👨‍️ Consulto con Nutrizionista
                            </div>
                            <div className="w-full h-[50px] mt-[20px] mb-[30px]">
                                <WhiteButtonBlueBorder
                                    text="Acquista"
                                    onClick={() => window.open("https://www.holifya.com/pages/consulto")}
                                    pointer={true}
                                    uppercase={false}
                                    fontSize={16}
                                />
                            </div>
                            <div className="font-normal text-[16px] leading-[26px]">
                                🌀 <strong>Percorso Health</strong>: Gratis per 2 mesi<br />
                                Usa il codice sconto <strong>REVOLUTION30</strong> nel carrello
                            </div>
                            <div className="w-full h-[50px] mt-[20px] mb-[30px]">
                                <PrimaryButton
                                    text="Acquista"
                                    onClick={() => window.open("https://www.holifya.com/products/percorso-health")}
                                    pointer={true}
                                    disabled={false}
                                    uppercase={false}
                                    fontSize={16}
                                />
                            </div>
                        </>
                    )
                }
            ]
        case TutorialType.SUB_HEALTH:
            return [
                {
                    title: <>Hai acquistato un Percorso Health</>,
                    content: (
                        <>
                            Complimenti! Hai acquistato il 🌀 <strong>Percorso Health</strong>, il servizio di abbonamento più completo che possiamo offrirti! Riceverai un piano di salute e prevenzione personalizzato in base ai risultati del tuo 🧬 <strong>Test del DNA</strong> e le tue 🩸 <strong>Analisi degli Aminoacidi</strong>, tutto accompagnato da consulti ricorrenti con 👩‍⚕️️ <strong>Medici e Nutrizionisti</strong> a tuo completa disposizione.
                        </>
                    ),
                    imageSrc: "images/new-platform/tutorial/tutorial-sub-health-1.jpg",
                },
                {
                    title: <>Cosa sta arrivando a casa tua</>,
                    content: (
                        <>
                            Stai per ricevere un kit che ti permetterà di prelevare il tuo DNA e il tuo sangue dalla comodità del tuo divano.
                            È semplicissimo! Segui le istruzioni, preleva il tuo campione di saliva e richiedi il ritiro.
                        </>
                    ),
                    imageSrc: "images/new-platform/tutorial/tutorial-sub-health-2.jpg",
                },
                {
                    title: <>Dove vedrai i risultati?</>,
                    content: (
                        <>
                            Riceverai una notifica via e- mail non appena i risultati del tuo test del DNA saranno pronti! Potrai visualizzarli e scaricarli dalla tua area personale.<br />
                            Saranno disponibili sia in <strong>Home</strong> ( <span><img className="align-middle inline-block m-0 p-0 h-auto w-auto" src={NEW_PLATFORM_HOME_LOGO_BLACK} /></span> ) sia nella cartella 📁 <strong>Test e referti</strong> all'interno dell'area <strong>Diagnostica</strong> ( <span><img className="align-middle inline-block m-0 p-0 h-auto w-auto" src={NEW_PLATFORM_DIAGNOSTICA_LOGO_BLACK} /></span> ).
                        </>
                    ),
                    imageSrc: "images/new-platform/tutorial/tutorial-sub-health-3.jpg",
                },
                {
                    title: <>Risultati e progressi</>,
                    content: (
                        <>
                            Oltre ai risultati dei singoli 🧬 <strong>Test del DNA</strong> e 🩸 <strong>Analisi degli Aminoacidi</strong>, all'interno di 📊 Risultati e progressi nell'area <strong>Diagnostica</strong> ( <span><img className="align-middle inline-block m-0 p-0 h-auto w-auto" src={NEW_PLATFORM_DIAGNOSTICA_LOGO_BLACK} /></span> ), puoi trovare il risultato incrociato di <strong>DNA</strong> e <strong>Aminoacidi</strong> presentato per categorie di salute, a cui ognuna è stato assegnato un punteggio.
                            Cerca di raggiungere il punteggio più vicino a 10 possibile!!
                        </>
                    ),
                    imageSrc: "images/new-platform/tutorial/tutorial-sub-health-4.jpg",
                },
                {
                    title: <>🩸 Analisi degli Aminoacidi</>,
                    content: (
                        <>
                            L'analisi ematica (🩸 <strong>Analisi degli Aminoacidi</strong>) che questo piano ti mette a disposizione ti permetterà di individuare eventuali carenze nutrizionali, ottenere informazioni complete sulla tua salute metabolica, cardiovascolare, digestiva e cognitiva, misurare i tuoi livelli di infiammazione cronica e stress ossidativo, e valutare l'efficienza della tua risposta immunitaria agli agenti esterni. Mantenendo attivo l'abbonamento riceverai una nuova 🩸 <strong>Analisi degli Aminoacidi</strong> da effettuare ogni 6 mesi per monitorare gli effettivi miglioramenti dei tuoi parametri di salute nel tempo!
                        </>
                    ),
                },
                {
                    title: <>👩‍⚕️ Consulto con Medico</>,
                    content: (
                        <>
                            Hai a disposizione 1x 👩‍⚕️ <strong>Consulto con Medico!</strong>
                            Il Medico ti aiuterà a leggere e interpretare a fondo i risultati del tuo 🧬 <strong>Test del DNA</strong> e della tua 🩸 <strong>Analisi degli Aminoacidi</strong>.<br /> Ti consigliamo di prenotare il tuo consulto dopo aver ricevuto l'esito di entrambi i test Rinnovando l'abbonamento alla fine dei 6 mesi, avrai a disposizione una nuova 🩸 <strong>Analisi degli Aminoacidi</strong> e un nuovo 👩‍⚕️ <strong>Consulto con Medico</strong> per discutere i risultati e i progressi dei mesi precedenti.<br />
                            <strong>Ricorda:</strong> potrai acquistare consulti aggiuntivi ogni volta che lo riterrai necessario!<br />
                            <strong>Nota</strong>: I consulti avvengono in modalità videoconsulto
                        </>
                    ),
                },
                {
                    title: <>👨‍⚕️ Consulto con Nutrizionista</>,
                    content: (
                        <>
                            Hai a disposizione 3x 👨‍⚕️ <strong>Consulto con Nutrizionista</strong>! Wow!<br />Questo significa che hai un alleato, che ti aiuterà a raggiungere i tuoi obiettivi.<br />Una volta ricevuti i tuoi risultati del 🧬 <strong>Test del DNA</strong> e della tua 🩸 <strong>Analisi degli Aminoacidi</strong>, prenota il primo consulto con il professionista Holifya selezionato per te, riceverai il tuo piano alimentare pochi giorni dopo il primo incontro.Ti consigliamo di prenotare il tuo consulto dopo aver ricevuto l'esito di entrambi i test. Hai a disposizione 3 consulti ogni 6 mesi, questo vuol dire che puoi scegliere tu (assieme al tuo nutrizionista) quando incontrarvi nuovamente, valutare i progressi ed, eventualmente, aggiornare il piano.<br />
                            <strong>Ricorda:</strong> potrai acquistare consulti aggiuntivi ogni volta che lo riterrai necessario!<br />
                            <strong>Nota:</strong> I consulti avvengono in modalità videoconsulto
                        </>
                    ),
                },
                {
                    title: <>📝 Health Assessment</>,
                    content: (
                        <>
                            In vista dei tuoi primi ‍👩‍⚕️️ <strong>Consulti con Medico e Nutrizionista</strong> è importante che compili il tuo 📝 <strong>Health Assessment</strong>. In questo modo i tuoi professionisti avranno a disposizione tutte le informazioni (oltre a quelle genetiche e degli Aminoacidi analizzate) necessarie per una vera e profonda personalizzazione del tuo 🌀 <strong>Percorso Health</strong>.
                        </>
                    ),
                },
                {
                    title: <>Dove vedrò il mio piano?</>,
                    content: (
                        <>
                            Una volta pronto, il tuo piano sarà disponibile nell'area <strong>Health Plan</strong> ( <span><img className="align-middle inline-block m-0 p-0 h-auto w-auto" src={NEW_PLATFORM_HEALTH_PLAN_LOGO_BLACK} /></span> ): potrai trovare consigli e suggerimenti, oltre ai protocolli specifici del tuo piano, nella cartella 🍲 <strong>Meal Plan</strong>.
                        </>
                    ),
                },
            ]
        case TutorialType.SUB_NUTRITION:
            return [
                {
                    title: <>Hai acquistato un Percorso Nutrition</>,
                    content: (
                        <>
                            Complimenti! <br />
                            Il 🍎 <strong>Percorso Nutrition</strong> ti permetterà di sfruttare i risultati del tuo 🧬 <strong>Test del DNA</strong> per ricevere un piano alimentare personalizzato dal tuo 👨‍⚕️ <strong>Nutrizionista</strong>.
                        </>
                    ),
                    imageSrc: "images/new-platform/tutorial/tutorial-sub-nutrition-1.jpg",
                },
                {
                    title: <>Cosa sta arrivando a casa tua</>,
                    content: (
                        <>
                            Stai per ricevere un kit per effettuare il test del DNA dalla comodità del tuo divano.<br />
                            È semplicissimo! Segui le istruzioni, preleva il tuo campione di saliva e richiedi il ritiro.
                        </>
                    ),
                    imageSrc: "images/new-platform/tutorial/tutorial-sub-nutrition-2.jpg",
                },
                {
                    title: <>Dove vedrai i risultati?</>,
                    content: (
                        <>
                            Riceverai una notifica via email non appena i risultati del tuo 🧬 <strong>Test del DNA</strong> saranno pronti! Potrai visualizzarli e scaricarli dalla tua area personale.<br />
                            Sarà disponibile sia in <strong>Home</strong> ( <span><img className="align-middle inline-block m-0 p-0 h-auto w-auto" src={NEW_PLATFORM_HOME_LOGO_BLACK} /></span> ) sia nella cartella 📁 <strong>Test e referti</strong> all'interno dell'area <strong>Diagnostica</strong> ( <span><img className="align-middle inline-block m-0 p-0 h-auto w-auto" src={NEW_PLATFORM_DIAGNOSTICA_LOGO_BLACK} /></span> ).
                        </>
                    ),
                    imageSrc: "images/new-platform/tutorial/tutorial-sub-nutrition-3.jpg",
                },
                {
                    title: <>👨‍⚕️ Consulto con Nutrizionista</>,
                    content: (
                        <>
                            Hai a disposizione 3x 👨‍⚕️ <strong>Consulto con Nutrizionista</strong>! Wow!<br />
                            Questo significa che hai un alleato, che ti aiuterà a raggiungere i tuoi obiettivi.<br />
                            Una volta ricevuti i tuoi risultati del 🧬 <strong>Test del DNA</strong>, prenota il primo consulto con il professionista Holifya selezionato per te, riceverai il tuo piano alimentare pochi giorni dopo il primo incontro.<br />
                            Hai a disposizione 3 consulti ogni 6 mesi, questo vuol dire che puoi scegliere tu (assieme al tuo nutrizionista) quando incontrarvi nuovamente, valutare i progressi ed, eventualmente, aggiornare il piano.<br />
                            <strong>Ricorda:</strong> potrai acquistare consulti aggiuntivi ogni volta che lo riterrai necessario!<br />
                            <strong>Nota:</strong> I consulti avvengono in modalità videoconsulto
                        </>
                    ),
                },
                {
                    title: <>📝 Health Assessment</>,
                    content: (
                        <>
                            Prima del tuo primo ‍👨‍⚕️ <strong>Consulto con Nutrizionista</strong> è importante che compili il tuo 📝 <strong>Health Assessment</strong>.<br /> In questo modo il tuo Nutrizionista avrà a disposizione tutte le informazioni (oltre a quelle genetiche analizzate) necessarie per una vera e profonda personalizzazione del tuo 🍎 <strong>Percorso Nutrition.</strong>
                        </>
                    ),
                },
                {
                    title: <>Dove vedrò il mio piano?</>,
                    content: (
                        <>
                            Una volta pronto, il tuo piano sarà disponibile nell'area Health Plan ( <span><img className="align-middle inline-block m-0 p-0 h-auto w-auto" src={NEW_PLATFORM_HEALTH_PLAN_LOGO_BLACK} /></span> ): potrai trovare consigli e suggerimenti, oltre ai protocolli specifici del tuo piano, nella cartella 🍲 <strong>Meal Plan</strong>.
                        </>
                    ),
                },
                {
                    title: <>Cos'altro posso fare?</>,
                    content: (
                        <>
                            Il 🍎 <strong>Percorso Nutrition</strong> è un servizio pensato per coloro che desiderino intraprendere un percorso nutrizionale estremamente personalizzato per raggiungere i propri obiettivi di salute. <br />
                            In qualsiasi momento, potrai decidere di aggiungere più ‍👨‍⚕️ <strong>Consulti con Nutrizionista</strong>, oppure acquistare un 👩‍⚕️ <strong>Consulto con Medico</strong> per approfondire ulteriormente i  risultati del tuo 🧬 <strong>Test del DNA</strong>.<br />
                            In alternativa, puoi scegliere di aggiornare il tuo abbonamento a 🌀 <strong>Percorso Health</strong>: in questo abbonamento, oltre ai servizi già inclusi nel 🍎 <strong>Percorso Nutrition</strong>, avrai a disposizione un 👩‍⚕️ <strong>Consulto con Medico</strong> e un check-up ematico (<strong>Analisi degli Aminoacidi</strong>) ogni 6 mesi, per tenere traccia dei tuoi valori diagnostici e controllare il tuo stato di salute!
                        </>
                    ),
                },
                {
                    title: <>Credi sia il momento giusto?</>,
                    content: (
                        <>
                            <div className="font-semibold text-[12px] leading-[20px] mb-[20px]">
                                Per riscattare e gestire gli acquisti con questo account assicurati di utilizzare la mail <span className="text-holifya-blue">{email}</span> al momento dell'acquisto.
                            </div>
                            <div className="font-bold text-[16px] leading-[26px]">
                                👩‍⚕️ Consulto con Medico
                            </div>
                            <div className="w-full h-[50px] mt-[20px] mb-[30px]">
                                <WhiteButtonBlueBorder
                                    text="Acquista"
                                    onClick={() => window.open("https://www.holifya.com/pages/consulto-nutrigenetico")}
                                    pointer={true}
                                    uppercase={false}
                                    fontSize={16}
                                />
                            </div>
                            <div className="font-bold text-[16px] leading-[26px]">
                                👨‍️ Consulto con Nutrizionista
                            </div>
                            <div className="w-full h-[50px] mt-[20px] mb-[30px]">
                                <WhiteButtonBlueBorder
                                    text="Acquista"
                                    onClick={() => window.open("https://www.holifya.com/pages/consulto")}
                                    pointer={true}
                                    uppercase={false}
                                    fontSize={16}
                                />
                            </div>
                            <div className="font-bold text-[16px] leading-[26px]">
                                🌀 Percorso Health:
                            </div>
                            <div className="w-full h-[50px] mt-[20px] mb-[30px]">
                                <PrimaryButton
                                    text="Aggiorna"
                                    onClick={() => window.open("https://www.holifya.com/products/membership-dna-test")}
                                    pointer={true}
                                    disabled={false}
                                    uppercase={false}
                                    fontSize={16}
                                />
                            </div>
                        </>
                    ),
                },
            ]
        case TutorialType.CONSULT:
            return []
    }
};
