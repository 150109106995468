import { Link } from "react-router-dom"
import SmallButton from "./SmallButton"
import BackButton from "../BackButton"

const AppleLogin = () => {
    return (
        <div className="App">
            <span>TO IMPLEMENT! Login with Apple</span>
            <Link to="/login" className="no_decoration_text">
                <BackButton />
            </Link>
        </div>
    )
}

export default AppleLogin
