import { useNavigate } from "react-router-dom"
import BackButton from "../BackButton"

const NewsletterScreen = () => {
  const navigate = useNavigate();
  return (
    <div className="App">
      <span>TO IMPLEMENT! Newsletter screen</span>
      <BackButton onClick={() => (navigate("/create-account"))}/>
    </div>
  )
}

export default NewsletterScreen
