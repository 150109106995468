import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loginApi } from "../../../utils/apis/auth-service";
import { useAuth } from "../../../utils/context/AuthContext";
import { useRegistrationData } from "../../../utils/context/RegistrationContext";
import LoadingSpinner from "../../new-platform/components/LoadingSpinner";

const RegistrationSuccess = () => {

    const navigate = useNavigate();
    const { login } = useAuth();
    const { registrationData, clearData } = useRegistrationData()
    const email = registrationData.registrationEmail
    const password = registrationData.password

    useEffect(() => {
        email !== null && password !== null && loginApi(
            email,
            password,
            (result) => {
                login(result.idToken, result.userId, result.accessToken, result.refreshToken, result.expiryTime);
                clearData();
                navigate("/fetch-user-data")
            },
            () => {}
        )
    }, [])

    // ********************************************************************************

    return (
        <div className="App">
            <div className="w-[392px] h-full bg-white flex items-center justify-center">
                <div className="w-[328px] flex flex-col items-center">
                    <div className="font-bold text-[30px] leading-[38px] text-center my-[20px]">
                        Account creato correttamente
                    </div>
                    <div className="font-normal text-[18px] leading-[26px] text-center my-[20px]">
                        Stai per essere reindirizzato alla tua Area Personale
                    </div>
                    <LoadingSpinner />
                </div>
            </div>
        </div>
    )
}

export default RegistrationSuccess
