interface Props {
  name?: string,
  label: string,
  errorText: string,
  infoText?: string,
  inputValue: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  placeholder: string,
  disabled?: boolean,
  marginBottom?: boolean,
  marginTop?: boolean,
  fontSize?: number,
  fixedHeight?: boolean,
}

const TextInputElement: React.FC<Props> = ({
  name,
  label,
  errorText,
  infoText,
  inputValue,
  onChange,
  placeholder,
  disabled = false,
  marginBottom = false,
  marginTop = false,
  fontSize = 16,
  fixedHeight = true,
}) => {
  const handleTextColor = () => {
    if (infoText) {
      return "text-holifya-blue"
    } else if (errorText !== "") {
      return "font-bold text-[12px] leading-[17px] text-holifya-error"
    } else {
      return "text-transparent"
    }
  }

  return (
    <div className={`w-full flex flex-col justify-between ${fixedHeight && "h-[90px]"} ${marginBottom && "mb-[5px]"} ${marginTop && "mt-[20px]"}`}>
      {
        label !== "" && <label
          className="font-normal text-[14px] leading-[20px]"
          htmlFor={`text-input-${label}`}
        >
          {label}
        </label>
      }
      <input
        name={name}
        id={`text-input-${label}`}
        className={`border border-solid border-[#cecece] rounded-[5px] outline-none h-[42px] font-normal pl-[15px] placeholder:font-normal placeholder:text-[14px] placeholder:leading-[20px] text-[${fontSize}px]`}
        placeholder={placeholder}
        value={inputValue}
        type="text"
        onChange={onChange}
        disabled={disabled}
      />
      <div className={`font-bold text-[12px] leading-[17px] ${handleTextColor()}`}>
        {
          errorText !== "" ? errorText : (infoText ? infoText : "placeholder")
        }
      </div>
    </div>
  )
}

export default TextInputElement
